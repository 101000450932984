.secondary-button{
    background: #eee;
    padding: 6px 22px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    display: inline-block;
    color: #333;
    font-size: 16px;
    border: 1px solid #9844b7;
    cursor: pointer;
    
}
.milestone-toggler{
    position: absolute;
    bottom: 3px;
    right: -1px;
    border: none;
    border-radius: 5px;
}
.milestone-status{
    border: none;
    padding: 6px;
    border-radius: 3px;
    width: auto;
    font-weight: 600;
    &.green{
        background: #96ff966b;
    color: #007e1e;
    border: 1px solid;
    }
    &.yellow{
        background: #ffff0033;
    color: #af9b0f;
    border: 1px solid;
    }
    &.red{
        background: #ffd4d4;
    color: #f40505;
    border: 1px solid;
    }
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl{
    font-size: 16px !important;
}
label#demo-simple-select-label {
    font-size: 18px !important;
}
button[disabled] {
    cursor: default !important;
    opacity: 0.5 !important;
}
button#dropdown-item-button{
    width: 100% !important;
}