.loader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay color */
    z-index: 999; /* Ensure it appears on top of other content */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out; /* Smooth fade-in/fade-out */
}

/* Loader animation */
.loader {
    border: 5px solid #f3f3f3; /* Light gray border */
    border-top: 5px solid #3498db; /* Blue border on top */
    border-radius: 50%; /* Circle shape */
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite; /* Rotate animation */
}

/* Keyframes for loader animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Show the overlay and loader when needed */
.loading {
    opacity: 1;
}