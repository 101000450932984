#top_cards {
  padding: 30px 0 40px;
}

#top_cards .gallery_info {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
#top_cards .gallery_info h5 {
  position: absolute;
  height: 80px;
  z-index: 0;
  padding: 39px 14px 0;
  bottom: -8px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
#top_cards .gallery_info h5:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-bottom: 94px solid #043570;
  border-right: 548px solid transparent;
  width: 100%;
  z-index: -1;
  cursor: pointer;
}

#top_cards .item:nth-child(2) .gallery_info h5:before {border-bottom-color: #dd1141;}
#top_cards .item:nth-child(3) .gallery_info h5:before {border-bottom-color: #027d39;}
#top_cards .item:nth-child(4) .gallery_info h5:before {border-bottom-color: #9f2b88;}
#top_cards .item:nth-child(5) .gallery_info h5:before {border-bottom-color: #068cca;}
#top_cards .item {
  width: 16.6%;
  float: left;
}

#top_cards .gallery_info .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 9, 9, 0.90);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
#top_cards .gallery_info:hover .overlay {
  height: 100%;
}
#top_cards .movie_img:hover:after{content: none;}
#top_cards .gallery_info .stroke_btn {
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  color: #fff;
  border-color: #fff;
  font-size: 13px;
  padding: 5px 11px;
  min-width: 150px;
  transition: all ease 0.4s;
  margin: 5px 0;
}
#top_cards .gallery_info .stroke_btn:hover, 
#top_cards .gallery_info .stroke_btn:focus{color:#fff !important; border-color:#fff;}
.dark_clr{background:#030c18;}
.home_grey_bx {
  background: #0d1b2e;
  padding: 6rem 80px;
  border-radius: 20px;
  margin: 20px 0;
  position: relative;
  overflow: hidden;
}
.section_info h2 {
  font-size: 42px;
  font-weight: 600;
  color: #fff;
  line-height: 55px;
  margin: 0 0 35px;
}
.section_info p {
  font-size: 20px;
  color: #b5c5da;
  line-height: 32px;
}
.section_info .stroke_btn {
  margin: 40px 0 0;
  min-width: 235px;
  text-align: center;
  padding: 14px 10px;
  font-size: 18px;
}
.section_img img {
  max-width: 42rem;
}
.home_grey_bx .box_align, #auditions .box_align{
  display: flex;
  align-items: center;
}
#make_film_part{padding: 0 0 40px;}
/* #make_film_part {
  background: #003e7e;
  position: relative;
  overflow: hidden;
  padding: 10rem 0;
} */
#make_film_part .pattern_img {
  width: 100%;
  opacity: 0.08;
  position: absolute;
  bottom: -17rem;
}
#make_film_part .brush_shape {
  position: absolute;
  right: 0;
  max-width: 80rem;
  bottom: 0;
}
//#make_film_part .section_info .stroke_btn{background: rgba(6,16,30,0.8); color:#3caeff }
.mid_info .section_info {
  padding: 0 70px;
}
.mid_info .left_pattern{position: absolute;}
#post_audition .section_img img {
  max-width: 50rem;
}
.mid_info .left_pattern, .mid_info .right_pattern {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 58%;
  opacity: 0.04;
}
.mid_info .right_pattern{left: auto; right: 0;}
#find_work_part .section_img img {
  max-width: 32rem;
}
#top_cards .gallery_info .overlay p small {
  text-align: center;
  line-height: 3px;
  padding-bottom: 20px;
  color: #fff;
  bottom: 28px;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 14px;
}
.full_movie_detail .list_part span:first-child, #auditions .bg_bx .list_part span:first-child {
  margin-left: 0;
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image:linear-gradient(-60deg, #66053f 10%, #05047c 40%, #23142f 40%);
  bottom:0;
  left:-50%;
  opacity:1;
  position:absolute;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:5s;
}

.bg3 {
  animation-duration:6s;
}
@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}
.carousel-fade .carousel-inner .item {
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}

.carousel,
.carousel-inner,
.carousel-inner .item {
  height: 100%;
}
.slider_img img {
    max-width: 565px;
    margin-top: -41px;
}

.inner_text{padding:5rem 0 0;}
.inner_text p {
    color: #f3f3f3;
    font-weight: 300;
    line-height: 24px;
}
.inner_text h1 {
    font-size: 34px;
    color: #fff;
    font-weight: 700;
    margin: 0 0 15px;
    line-height: 46px;
}
#slider_part .inner_text .stroke_btn {
  border: 1px solid #c62537;
  background:#c62537;
}
.top_slide .navbar-nav > li > .stroke_btn {
    color: #fff;
    border-color: #fff;
}
.top_slide .search_button{background: transparent;}
.top_slide .top_slide input[type="text"]{border-color: #fff;}

@-webkit-keyframes changeBg {
  0% {
    background: #556270;
  }
  33.33333333% {
    background: #7FB069;
  }
  66.66666666% {
    background: #FF7644;
  }
}

@keyframes changeBg {
  0% {
    background: #556270;
  }
  33.33333333% {
    background: #7FB069;
  }
  66.66666666% {
    background: #FF7644;
  }
}
/*** <--- PARTICLES ---> ***/
.bottom-particles {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.bottom-particles .bubble {
  opacity: 0;
  position: absolute;
  bottom: -1rem;
  width: 1rem;
  height: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}
.bottom-particles .bubble:nth-child(1) {
  left: 45%;
  -webkit-animation: blow 14114ms infinite;
          animation: blow 14114ms infinite;
  -webkit-animation-delay: 10521ms;
          animation-delay: 10521ms;
}
.bottom-particles .bubble:nth-child(2) {
  left: 44%;
  -webkit-animation: blow 11339ms infinite;
          animation: blow 11339ms infinite;
  -webkit-animation-delay: 4281ms;
          animation-delay: 4281ms;
}
.bottom-particles .bubble:nth-child(3) {
  left: 75%;
  -webkit-animation: blow 7125ms infinite;
          animation: blow 7125ms infinite;
  -webkit-animation-delay: 695ms;
          animation-delay: 695ms;
}
.bottom-particles .bubble:nth-child(4) {
  left: 37%;
  -webkit-animation: blow 14335ms infinite;
          animation: blow 14335ms infinite;
  -webkit-animation-delay: 4405ms;
          animation-delay: 4405ms;
}
.bottom-particles .bubble:nth-child(5) {
  left: 86%;
  -webkit-animation: blow 11504ms infinite;
          animation: blow 11504ms infinite;
  -webkit-animation-delay: 3507ms;
          animation-delay: 3507ms;
}
.bottom-particles .bubble:nth-child(6) {
  left: 91%;
  -webkit-animation: blow 8410ms infinite;
          animation: blow 8410ms infinite;
  -webkit-animation-delay: 2500ms;
          animation-delay: 2500ms;
}
.bottom-particles .bubble:nth-child(7) {
  left: 33%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 7382ms;
          animation-delay: 7382ms;
}
.bottom-particles .bubble:nth-child(8) {
  left: 92%;
  -webkit-animation: blow 12568ms infinite;
          animation: blow 12568ms infinite;
  -webkit-animation-delay: 2430ms;
          animation-delay: 2430ms;
}
.bottom-particles .bubble:nth-child(9) {
  left: 80%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 5267ms;
          animation-delay: 5267ms;
}
.bottom-particles .bubble:nth-child(10) {
  left: 58%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 8781ms;
          animation-delay: 8781ms;
}
.bottom-particles .bubble:nth-child(11) {
  left: 62%;
  -webkit-animation: blow 6323ms infinite;
          animation: blow 6323ms infinite;
  -webkit-animation-delay: 10863ms;
          animation-delay: 10863ms;
}
.bottom-particles .bubble:nth-child(12) {
  left: 79%;
  -webkit-animation: blow 10953ms infinite;
          animation: blow 10953ms infinite;
  -webkit-animation-delay: 2725ms;
          animation-delay: 2725ms;
}
.bottom-particles .bubble:nth-child(13) {
  left: 87%;
  -webkit-animation: blow 8447ms infinite;
          animation: blow 8447ms infinite;
  -webkit-animation-delay: 5829ms;
          animation-delay: 5829ms;
}
.bottom-particles .bubble:nth-child(14) {
  left: 64%;
  -webkit-animation: blow 7369ms infinite;
          animation: blow 7369ms infinite;
  -webkit-animation-delay: 10574ms;
          animation-delay: 10574ms;
}
.bottom-particles .bubble:nth-child(15) {
  left: 73%;
  -webkit-animation: blow 11897ms infinite;
          animation: blow 11897ms infinite;
  -webkit-animation-delay: 10233ms;
          animation-delay: 10233ms;
}
.bottom-particles .bubble:nth-child(16) {
  left: 95%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 8265ms;
          animation-delay: 8265ms;
}
.bottom-particles .bubble:nth-child(17) {
  left: 15%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 8203ms;
          animation-delay: 8203ms;
}
.bottom-particles .bubble:nth-child(18) {
  left: 14%;
  -webkit-animation: blow 6348ms infinite;
          animation: blow 6348ms infinite;
  -webkit-animation-delay: 7140ms;
          animation-delay: 7140ms;
}
.bottom-particles .bubble:nth-child(19) {
  left: 51%;
  -webkit-animation: blow 13687ms infinite;
          animation: blow 13687ms infinite;
  -webkit-animation-delay: 1438ms;
          animation-delay: 1438ms;
}
.bottom-particles .bubble:nth-child(20) {
  left: 11%;
  -webkit-animation: blow 13293ms infinite;
          animation: blow 13293ms infinite;
  -webkit-animation-delay: 8814ms;
          animation-delay: 8814ms;
}
.bottom-particles .bubble:nth-child(21) {
  left: 23%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 10828ms;
          animation-delay: 10828ms;
}
.bottom-particles .bubble:nth-child(22) {
  left: 74%;
  -webkit-animation: blow 5738ms infinite;
          animation: blow 5738ms infinite;
  -webkit-animation-delay: 4681ms;
          animation-delay: 4681ms;
}
.bottom-particles .bubble:nth-child(23) {
  left: 67%;
  -webkit-animation: blow 6759ms infinite;
          animation: blow 6759ms infinite;
  -webkit-animation-delay: 5266ms;
          animation-delay: 5266ms;
}
.bottom-particles .bubble:nth-child(24) {
  left: 86%;
  -webkit-animation: blow 10490ms infinite;
          animation: blow 10490ms infinite;
  -webkit-animation-delay: 9139ms;
          animation-delay: 9139ms;
}
.bottom-particles .bubble:nth-child(25) {
  left: 43%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 199ms;
          animation-delay: 199ms;
}
.bottom-particles .bubble:nth-child(26) {
  left: 16%;
  -webkit-animation: blow 6650ms infinite;
          animation: blow 6650ms infinite;
  -webkit-animation-delay: 11027ms;
          animation-delay: 11027ms;
}
.bottom-particles .bubble:nth-child(27) {
  left: 64%;
  -webkit-animation: blow 11906ms infinite;
          animation: blow 11906ms infinite;
  -webkit-animation-delay: 2567ms;
          animation-delay: 2567ms;
}
.bottom-particles .bubble:nth-child(28) {
  left: 9%;
  -webkit-animation: blow 14862ms infinite;
          animation: blow 14862ms infinite;
  -webkit-animation-delay: 5260ms;
          animation-delay: 5260ms;
}
.bottom-particles .bubble:nth-child(29) {
  left: 60%;
  -webkit-animation: blow 7305ms infinite;
          animation: blow 7305ms infinite;
  -webkit-animation-delay: 11545ms;
          animation-delay: 11545ms;
}
.bottom-particles .bubble:nth-child(30) {
  left: 50%;
  -webkit-animation: blow 9631ms infinite;
          animation: blow 9631ms infinite;
  -webkit-animation-delay: 11695ms;
          animation-delay: 11695ms;
}
.bottom-particles .bubble:nth-child(31) {
  left: 66%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 5246ms;
          animation-delay: 5246ms;
}
.bottom-particles .bubble:nth-child(32) {
  left: 60%;
  -webkit-animation: blow 8515ms infinite;
          animation: blow 8515ms infinite;
  -webkit-animation-delay: 6399ms;
          animation-delay: 6399ms;
}
.bottom-particles .bubble:nth-child(33) {
  left: 53%;
  -webkit-animation: blow 10964ms infinite;
          animation: blow 10964ms infinite;
  -webkit-animation-delay: 7305ms;
          animation-delay: 7305ms;
}
.bottom-particles .bubble:nth-child(34) {
  left: 57%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 5612ms;
          animation-delay: 5612ms;
}
.bottom-particles .bubble:nth-child(35) {
  left: 62%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3171ms;
          animation-delay: 3171ms;
}
.bottom-particles .bubble:nth-child(36) {
  left: 50%;
  -webkit-animation: blow 5484ms infinite;
          animation: blow 5484ms infinite;
  -webkit-animation-delay: 2049ms;
          animation-delay: 2049ms;
}
.bottom-particles .bubble:nth-child(37) {
  left: 75%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 9879ms;
          animation-delay: 9879ms;
}
.bottom-particles .bubble:nth-child(38) {
  left: 10%;
  -webkit-animation: blow 8613ms infinite;
          animation: blow 8613ms infinite;
  -webkit-animation-delay: 9915ms;
          animation-delay: 9915ms;
}
.bottom-particles .bubble:nth-child(39) {
  left: 21%;
  -webkit-animation: blow 12311ms infinite;
          animation: blow 12311ms infinite;
  -webkit-animation-delay: 10701ms;
          animation-delay: 10701ms;
}
.bottom-particles .bubble:nth-child(40) {
  left: 5%;
  -webkit-animation: blow 14241ms infinite;
          animation: blow 14241ms infinite;
  -webkit-animation-delay: 4531ms;
          animation-delay: 4531ms;
}
.bottom-particles .bubble:nth-child(41) {
  left: 68%;
  -webkit-animation: blow 13336ms infinite;
          animation: blow 13336ms infinite;
  -webkit-animation-delay: 8643ms;
          animation-delay: 8643ms;
}
.bottom-particles .bubble:nth-child(42) {
  left: 62%;
  -webkit-animation: blow 13893ms infinite;
          animation: blow 13893ms infinite;
  -webkit-animation-delay: 3941ms;
          animation-delay: 3941ms;
}
.bottom-particles .bubble:nth-child(43) {
  left: 52%;
  -webkit-animation: blow 5066ms infinite;
          animation: blow 5066ms infinite;
  -webkit-animation-delay: 9864ms;
          animation-delay: 9864ms;
}
.bottom-particles .bubble:nth-child(44) {
  left: 2%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3804ms;
          animation-delay: 3804ms;
}
.bottom-particles .bubble:nth-child(45) {
  left: 42%;
  -webkit-animation: blow 13116ms infinite;
          animation: blow 13116ms infinite;
  -webkit-animation-delay: 10453ms;
          animation-delay: 10453ms;
}
.bottom-particles .bubble:nth-child(46) {
  left: 47%;
  -webkit-animation: blow 13984ms infinite;
          animation: blow 13984ms infinite;
  -webkit-animation-delay: 7547ms;
          animation-delay: 7547ms;
}
.bottom-particles .bubble:nth-child(47) {
  left: 10%;
  -webkit-animation: blow 5355ms infinite;
          animation: blow 5355ms infinite;
  -webkit-animation-delay: 2437ms;
          animation-delay: 2437ms;
}
.bottom-particles .bubble:nth-child(48) {
  left: 83%;
  -webkit-animation: blow 14583ms infinite;
          animation: blow 14583ms infinite;
  -webkit-animation-delay: 233ms;
          animation-delay: 233ms;
}
.bottom-particles .bubble:nth-child(49) {
  left: 64%;
  -webkit-animation: blow 14971ms infinite;
          animation: blow 14971ms infinite;
  -webkit-animation-delay: 7692ms;
          animation-delay: 7692ms;
}
.bottom-particles .bubble:nth-child(50) {
  left: 96%;
  -webkit-animation: blow 11597ms infinite;
          animation: blow 11597ms infinite;
  -webkit-animation-delay: 8978ms;
          animation-delay: 8978ms;
}
.bottom-particles .bubble:nth-child(51) {
  left: 66%;
  -webkit-animation: blow 7456ms infinite;
          animation: blow 7456ms infinite;
  -webkit-animation-delay: 8299ms;
          animation-delay: 8299ms;
}
.bottom-particles .bubble:nth-child(52) {
  left: 63%;
  -webkit-animation: blow 13446ms infinite;
          animation: blow 13446ms infinite;
  -webkit-animation-delay: 8193ms;
          animation-delay: 8193ms;
}
.bottom-particles .bubble:nth-child(53) {
  left: 100%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 9124ms;
          animation-delay: 9124ms;
}
.bottom-particles .bubble:nth-child(54) {
  left: 54%;
  -webkit-animation: blow 5332ms infinite;
          animation: blow 5332ms infinite;
  -webkit-animation-delay: 6688ms;
          animation-delay: 6688ms;
}
.bottom-particles .bubble:nth-child(55) {
  left: 12%;
  -webkit-animation: blow 10150ms infinite;
          animation: blow 10150ms infinite;
  -webkit-animation-delay: 8434ms;
          animation-delay: 8434ms;
}
.bottom-particles .bubble:nth-child(56) {
  left: 59%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 10715ms;
          animation-delay: 10715ms;
}
.bottom-particles .bubble:nth-child(57) {
  left: 52%;
  -webkit-animation: blow 10034ms infinite;
          animation: blow 10034ms infinite;
  -webkit-animation-delay: 7795ms;
          animation-delay: 7795ms;
}
.bottom-particles .bubble:nth-child(58) {
  left: 43%;
  -webkit-animation: blow 7360ms infinite;
          animation: blow 7360ms infinite;
  -webkit-animation-delay: 7328ms;
          animation-delay: 7328ms;
}
.bottom-particles .bubble:nth-child(59) {
  left: 76%;
  -webkit-animation: blow 6429ms infinite;
          animation: blow 6429ms infinite;
  -webkit-animation-delay: 2146ms;
          animation-delay: 2146ms;
}
.bottom-particles .bubble:nth-child(60) {
  left: 1%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 7304ms;
          animation-delay: 7304ms;
}
.bottom-particles .bubble:nth-child(61) {
  left: 48%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11426ms;
          animation-delay: 11426ms;
}
.bottom-particles .bubble:nth-child(62) {
  left: 89%;
  -webkit-animation: blow 11292ms infinite;
          animation: blow 11292ms infinite;
  -webkit-animation-delay: 1201ms;
          animation-delay: 1201ms;
}
.bottom-particles .bubble:nth-child(63) {
  left: 85%;
  -webkit-animation: blow 10508ms infinite;
          animation: blow 10508ms infinite;
  -webkit-animation-delay: 2294ms;
          animation-delay: 2294ms;
}
.bottom-particles .bubble:nth-child(64) {
  left: 12%;
  -webkit-animation: blow 10986ms infinite;
          animation: blow 10986ms infinite;
  -webkit-animation-delay: 3411ms;
          animation-delay: 3411ms;
}
.bottom-particles .bubble:nth-child(65) {
  left: 61%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 6855ms;
          animation-delay: 6855ms;
}
.bottom-particles .bubble:nth-child(66) {
  left: 31%;
  -webkit-animation: blow 6526ms infinite;
          animation: blow 6526ms infinite;
  -webkit-animation-delay: 4458ms;
          animation-delay: 4458ms;
}
.bottom-particles .bubble:nth-child(67) {
  left: 22%;
  -webkit-animation: blow 13800ms infinite;
          animation: blow 13800ms infinite;
  -webkit-animation-delay: 5403ms;
          animation-delay: 5403ms;
}
.bottom-particles .bubble:nth-child(68) {
  left: 88%;
  -webkit-animation: blow 12008ms infinite;
          animation: blow 12008ms infinite;
  -webkit-animation-delay: 7780ms;
          animation-delay: 7780ms;
}
.bottom-particles .bubble:nth-child(69) {
  left: 18%;
  -webkit-animation: blow 13914ms infinite;
          animation: blow 13914ms infinite;
  -webkit-animation-delay: 3438ms;
          animation-delay: 3438ms;
}
.bottom-particles .bubble:nth-child(70) {
  left: 40%;
  -webkit-animation: blow 11944ms infinite;
          animation: blow 11944ms infinite;
  -webkit-animation-delay: 3272ms;
          animation-delay: 3272ms;
}
.bottom-particles .bubble:nth-child(71) {
  left: 23%;
  -webkit-animation: blow 12352ms infinite;
          animation: blow 12352ms infinite;
  -webkit-animation-delay: 7218ms;
          animation-delay: 7218ms;
}
.bottom-particles .bubble:nth-child(72) {
  left: 58%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4127ms;
          animation-delay: 4127ms;
}
.bottom-particles .bubble:nth-child(73) {
  left: 77%;
  -webkit-animation: blow 7062ms infinite;
          animation: blow 7062ms infinite;
  -webkit-animation-delay: 9507ms;
          animation-delay: 9507ms;
}
.bottom-particles .bubble:nth-child(74) {
  left: 12%;
  -webkit-animation: blow 9394ms infinite;
          animation: blow 9394ms infinite;
  -webkit-animation-delay: 403ms;
          animation-delay: 403ms;
}
.bottom-particles .bubble:nth-child(75) {
  left: 97%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 7782ms;
          animation-delay: 7782ms;
}
.bottom-particles .bubble:nth-child(76) {
  left: 45%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2621ms;
          animation-delay: 2621ms;
}
.bottom-particles .bubble:nth-child(77) {
  left: 87%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11244ms;
          animation-delay: 11244ms;
}
.bottom-particles .bubble:nth-child(78) {
  left: 62%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4299ms;
          animation-delay: 4299ms;
}
.bottom-particles .bubble:nth-child(79) {
  left: 73%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3313ms;
          animation-delay: 3313ms;
}
.bottom-particles .bubble:nth-child(80) {
  left: 67%;
  -webkit-animation: blow 12937ms infinite;
          animation: blow 12937ms infinite;
  -webkit-animation-delay: 3301ms;
          animation-delay: 3301ms;
}
.bottom-particles .bubble:nth-child(81) {
  left: 87%;
  -webkit-animation: blow 6006ms infinite;
          animation: blow 6006ms infinite;
  -webkit-animation-delay: 806ms;
          animation-delay: 806ms;
}
.bottom-particles .bubble:nth-child(82) {
  left: 41%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 10559ms;
          animation-delay: 10559ms;
}
.bottom-particles .bubble:nth-child(83) {
  left: 32%;
  -webkit-animation: blow 5748ms infinite;
          animation: blow 5748ms infinite;
  -webkit-animation-delay: 3938ms;
          animation-delay: 3938ms;
}
.bottom-particles .bubble:nth-child(84) {
  left: 70%;
  -webkit-animation: blow 13178ms infinite;
          animation: blow 13178ms infinite;
  -webkit-animation-delay: 6134ms;
          animation-delay: 6134ms;
}
.bottom-particles .bubble:nth-child(85) {
  left: 17%;
  -webkit-animation: blow 14411ms infinite;
          animation: blow 14411ms infinite;
  -webkit-animation-delay: 2815ms;
          animation-delay: 2815ms;
}
.bottom-particles .bubble:nth-child(86) {
  left: 76%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11581ms;
          animation-delay: 11581ms;
}
.bottom-particles .bubble:nth-child(87) {
  left: 28%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2889ms;
          animation-delay: 2889ms;
}
.bottom-particles .bubble:nth-child(88) {
  left: 4%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 9820ms;
          animation-delay: 9820ms;
}
.bottom-particles .bubble:nth-child(89) {
  left: 61%;
  -webkit-animation: blow 6010ms infinite;
          animation: blow 6010ms infinite;
  -webkit-animation-delay: 3115ms;
          animation-delay: 3115ms;
}
.bottom-particles .bubble:nth-child(90) {
  left: 79%;
  -webkit-animation: blow 11271ms infinite;
          animation: blow 11271ms infinite;
  -webkit-animation-delay: 11355ms;
          animation-delay: 11355ms;
}
.bottom-particles .bubble:nth-child(91) {
  left: 1%;
  -webkit-animation: blow 10541ms infinite;
          animation: blow 10541ms infinite;
  -webkit-animation-delay: 5948ms;
          animation-delay: 5948ms;
}
.bottom-particles .bubble:nth-child(92) {
  left: 13%;
  -webkit-animation: blow 13834ms infinite;
          animation: blow 13834ms infinite;
  -webkit-animation-delay: 2999ms;
          animation-delay: 2999ms;
}
.bottom-particles .bubble:nth-child(93) {
  left: 65%;
  -webkit-animation: blow 13328ms infinite;
          animation: blow 13328ms infinite;
  -webkit-animation-delay: 2568ms;
          animation-delay: 2568ms;
}
.bottom-particles .bubble:nth-child(94) {
  left: 23%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 1476ms;
          animation-delay: 1476ms;
}
.bottom-particles .bubble:nth-child(95) {
  left: 87%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4224ms;
          animation-delay: 4224ms;
}
.bottom-particles .bubble:nth-child(96) {
  left: 24%;
  -webkit-animation: blow 12538ms infinite;
          animation: blow 12538ms infinite;
  -webkit-animation-delay: 2735ms;
          animation-delay: 2735ms;
}
.bottom-particles .bubble:nth-child(97) {
  left: 77%;
  -webkit-animation: blow 14184ms infinite;
          animation: blow 14184ms infinite;
  -webkit-animation-delay: 1463ms;
          animation-delay: 1463ms;
}
.bottom-particles .bubble:nth-child(98) {
  left: 59%;
  -webkit-animation: blow 14290ms infinite;
          animation: blow 14290ms infinite;
  -webkit-animation-delay: 912ms;
          animation-delay: 912ms;
}
.bottom-particles .bubble:nth-child(99) {
  left: 96%;
  -webkit-animation: blow 9889ms infinite;
          animation: blow 9889ms infinite;
  -webkit-animation-delay: 5675ms;
          animation-delay: 5675ms;
}
.bottom-particles .bubble:nth-child(100) {
  left: 65%;
  -webkit-animation: blow 9581ms infinite;
          animation: blow 9581ms infinite;
  -webkit-animation-delay: 10427ms;
          animation-delay: 10427ms;
}
.bottom-particles .bubble:nth-child(101) {
  left: 5%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3399ms;
          animation-delay: 3399ms;
}
.bottom-particles .bubble:nth-child(102) {
  left: 42%;
  -webkit-animation: blow 10015ms infinite;
          animation: blow 10015ms infinite;
  -webkit-animation-delay: 378ms;
          animation-delay: 378ms;
}
.bottom-particles .bubble:nth-child(103) {
  left: 87%;
  -webkit-animation: blow 11034ms infinite;
          animation: blow 11034ms infinite;
  -webkit-animation-delay: 9897ms;
          animation-delay: 9897ms;
}
.bottom-particles .bubble:nth-child(104) {
  left: 22%;
  -webkit-animation: blow 9518ms infinite;
          animation: blow 9518ms infinite;
  -webkit-animation-delay: 7485ms;
          animation-delay: 7485ms;
}
.bottom-particles .bubble:nth-child(105) {
  left: 40%;
  -webkit-animation: blow 12803ms infinite;
          animation: blow 12803ms infinite;
  -webkit-animation-delay: 5723ms;
          animation-delay: 5723ms;
}
.bottom-particles .bubble:nth-child(106) {
  left: 81%;
  -webkit-animation: blow 11781ms infinite;
          animation: blow 11781ms infinite;
  -webkit-animation-delay: 2068ms;
          animation-delay: 2068ms;
}
.bottom-particles .bubble:nth-child(107) {
  left: 55%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 5119ms;
          animation-delay: 5119ms;
}
.bottom-particles .bubble:nth-child(108) {
  left: 79%;
  -webkit-animation: blow 10760ms infinite;
          animation: blow 10760ms infinite;
  -webkit-animation-delay: 7001ms;
          animation-delay: 7001ms;
}
.bottom-particles .bubble:nth-child(109) {
  left: 30%;
  -webkit-animation: blow 12909ms infinite;
          animation: blow 12909ms infinite;
  -webkit-animation-delay: 3542ms;
          animation-delay: 3542ms;
}
.bottom-particles .bubble:nth-child(110) {
  left: 44%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4173ms;
          animation-delay: 4173ms;
}
.bottom-particles .bubble:nth-child(111) {
  left: 59%;
  -webkit-animation: blow 14397ms infinite;
          animation: blow 14397ms infinite;
  -webkit-animation-delay: 8190ms;
          animation-delay: 8190ms;
}
.bottom-particles .bubble:nth-child(112) {
  left: 59%;
  -webkit-animation: blow 5409ms infinite;
          animation: blow 5409ms infinite;
  -webkit-animation-delay: 4347ms;
          animation-delay: 4347ms;
}
.bottom-particles .bubble:nth-child(113) {
  left: 51%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 916ms;
          animation-delay: 916ms;
}
.bottom-particles .bubble:nth-child(114) {
  left: 52%;
  -webkit-animation: blow 7453ms infinite;
          animation: blow 7453ms infinite;
  -webkit-animation-delay: 6550ms;
          animation-delay: 6550ms;
}
.bottom-particles .bubble:nth-child(115) {
  left: 46%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11427ms;
          animation-delay: 11427ms;
}
.bottom-particles .bubble:nth-child(116) {
  left: 51%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4122ms;
          animation-delay: 4122ms;
}
.bottom-particles .bubble:nth-child(117) {
  left: 98%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 7648ms;
          animation-delay: 7648ms;
}
.bottom-particles .bubble:nth-child(118) {
  left: 85%;
  -webkit-animation: blow 13521ms infinite;
          animation: blow 13521ms infinite;
  -webkit-animation-delay: 5300ms;
          animation-delay: 5300ms;
}
.bottom-particles .bubble:nth-child(119) {
  left: 66%;
  -webkit-animation: blow 8462ms infinite;
          animation: blow 8462ms infinite;
  -webkit-animation-delay: 1581ms;
          animation-delay: 1581ms;
}
.bottom-particles .bubble:nth-child(120) {
  left: 65%;
  -webkit-animation: blow 5899ms infinite;
          animation: blow 5899ms infinite;
  -webkit-animation-delay: 421ms;
          animation-delay: 421ms;
}
.bottom-particles .bubble:nth-child(121) {
  left: 54%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11740ms;
          animation-delay: 11740ms;
}
.bottom-particles .bubble:nth-child(122) {
  left: 42%;
  -webkit-animation: blow 11994ms infinite;
          animation: blow 11994ms infinite;
  -webkit-animation-delay: 6705ms;
          animation-delay: 6705ms;
}
.bottom-particles .bubble:nth-child(123) {
  left: 5%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 9452ms;
          animation-delay: 9452ms;
}
.bottom-particles .bubble:nth-child(124) {
  left: 21%;
  -webkit-animation: blow 8168ms infinite;
          animation: blow 8168ms infinite;
  -webkit-animation-delay: 7029ms;
          animation-delay: 7029ms;
}
.bottom-particles .bubble:nth-child(125) {
  left: 68%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3176ms;
          animation-delay: 3176ms;
}
.bottom-particles .bubble:nth-child(126) {
  left: 73%;
  -webkit-animation: blow 11334ms infinite;
          animation: blow 11334ms infinite;
  -webkit-animation-delay: 7794ms;
          animation-delay: 7794ms;
}
.bottom-particles .bubble:nth-child(127) {
  left: 8%;
  -webkit-animation: blow 12779ms infinite;
          animation: blow 12779ms infinite;
  -webkit-animation-delay: 3688ms;
          animation-delay: 3688ms;
}
.bottom-particles .bubble:nth-child(128) {
  left: 93%;
  -webkit-animation: blow 14274ms infinite;
          animation: blow 14274ms infinite;
  -webkit-animation-delay: 6530ms;
          animation-delay: 6530ms;
}
.bottom-particles .bubble:nth-child(129) {
  left: 61%;
  -webkit-animation: blow 8062ms infinite;
          animation: blow 8062ms infinite;
  -webkit-animation-delay: 2130ms;
          animation-delay: 2130ms;
}
.bottom-particles .bubble:nth-child(130) {
  left: 38%;
  -webkit-animation: blow 5096ms infinite;
          animation: blow 5096ms infinite;
  -webkit-animation-delay: 2678ms;
          animation-delay: 2678ms;
}
.bottom-particles .bubble:nth-child(131) {
  left: 70%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 231ms;
          animation-delay: 231ms;
}
.bottom-particles .bubble:nth-child(132) {
  left: 70%;
  -webkit-animation: blow 11697ms infinite;
          animation: blow 11697ms infinite;
  -webkit-animation-delay: 761ms;
          animation-delay: 761ms;
}
.bottom-particles .bubble:nth-child(133) {
  left: 34%;
  -webkit-animation: blow 5341ms infinite;
          animation: blow 5341ms infinite;
  -webkit-animation-delay: 245ms;
          animation-delay: 245ms;
}
.bottom-particles .bubble:nth-child(134) {
  left: 70%;
  -webkit-animation: blow 11091ms infinite;
          animation: blow 11091ms infinite;
  -webkit-animation-delay: 4569ms;
          animation-delay: 4569ms;
}
.bottom-particles .bubble:nth-child(135) {
  left: 12%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11951ms;
          animation-delay: 11951ms;
}
.bottom-particles .bubble:nth-child(136) {
  left: 58%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 10796ms;
          animation-delay: 10796ms;
}
.bottom-particles .bubble:nth-child(137) {
  left: 55%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3745ms;
          animation-delay: 3745ms;
}
.bottom-particles .bubble:nth-child(138) {
  left: 73%;
  -webkit-animation: blow 13861ms infinite;
          animation: blow 13861ms infinite;
  -webkit-animation-delay: 6774ms;
          animation-delay: 6774ms;
}
.bottom-particles .bubble:nth-child(139) {
  left: 24%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 9419ms;
          animation-delay: 9419ms;
}
.bottom-particles .bubble:nth-child(140) {
  left: 68%;
  -webkit-animation: blow 5918ms infinite;
          animation: blow 5918ms infinite;
  -webkit-animation-delay: 484ms;
          animation-delay: 484ms;
}
.bottom-particles .bubble:nth-child(141) {
  left: 45%;
  -webkit-animation: blow 11655ms infinite;
          animation: blow 11655ms infinite;
  -webkit-animation-delay: 1248ms;
          animation-delay: 1248ms;
}
.bottom-particles .bubble:nth-child(142) {
  left: 46%;
  -webkit-animation: blow 5220ms infinite;
          animation: blow 5220ms infinite;
  -webkit-animation-delay: 9105ms;
          animation-delay: 9105ms;
}
.bottom-particles .bubble:nth-child(143) {
  left: 23%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2355ms;
          animation-delay: 2355ms;
}
.bottom-particles .bubble:nth-child(144) {
  left: 2%;
  -webkit-animation: blow 11377ms infinite;
          animation: blow 11377ms infinite;
  -webkit-animation-delay: 1439ms;
          animation-delay: 1439ms;
}
.bottom-particles .bubble:nth-child(145) {
  left: 21%;
  -webkit-animation: blow 11038ms infinite;
          animation: blow 11038ms infinite;
  -webkit-animation-delay: 2516ms;
          animation-delay: 2516ms;
}
.bottom-particles .bubble:nth-child(146) {
  left: 87%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2138ms;
          animation-delay: 2138ms;
}
.bottom-particles .bubble:nth-child(147) {
  left: 68%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2236ms;
          animation-delay: 2236ms;
}
.bottom-particles .bubble:nth-child(148) {
  left: 82%;
  -webkit-animation: blow 7773ms infinite;
          animation: blow 7773ms infinite;
  -webkit-animation-delay: 2306ms;
          animation-delay: 2306ms;
}
.bottom-particles .bubble:nth-child(149) {
  left: 62%;
  -webkit-animation: blow 12231ms infinite;
          animation: blow 12231ms infinite;
  -webkit-animation-delay: 4355ms;
          animation-delay: 4355ms;
}
.bottom-particles .bubble:nth-child(150) {
  left: 71%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2886ms;
          animation-delay: 2886ms;
}
.bottom-particles .bubble:nth-child(151) {
  left: 31%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 6303ms;
          animation-delay: 6303ms;
}
.bottom-particles .bubble:nth-child(152) {
  left: 88%;
  -webkit-animation: blow 13733ms infinite;
          animation: blow 13733ms infinite;
  -webkit-animation-delay: 3399ms;
          animation-delay: 3399ms;
}
.bottom-particles .bubble:nth-child(153) {
  left: 42%;
  -webkit-animation: blow 14947ms infinite;
          animation: blow 14947ms infinite;
  -webkit-animation-delay: 8239ms;
          animation-delay: 8239ms;
}
.bottom-particles .bubble:nth-child(154) {
  left: 9%;
  -webkit-animation: blow 7751ms infinite;
          animation: blow 7751ms infinite;
  -webkit-animation-delay: 2661ms;
          animation-delay: 2661ms;
}
.bottom-particles .bubble:nth-child(155) {
  left: 67%;
  -webkit-animation: blow 10969ms infinite;
          animation: blow 10969ms infinite;
  -webkit-animation-delay: 7629ms;
          animation-delay: 7629ms;
}
.bottom-particles .bubble:nth-child(156) {
  left: 20%;
  -webkit-animation: blow 14742ms infinite;
          animation: blow 14742ms infinite;
  -webkit-animation-delay: 499ms;
          animation-delay: 499ms;
}
.bottom-particles .bubble:nth-child(157) {
  left: 12%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4348ms;
          animation-delay: 4348ms;
}
.bottom-particles .bubble:nth-child(158) {
  left: 95%;
  -webkit-animation: blow 10077ms infinite;
          animation: blow 10077ms infinite;
  -webkit-animation-delay: 1504ms;
          animation-delay: 1504ms;
}
.bottom-particles .bubble:nth-child(159) {
  left: 88%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4334ms;
          animation-delay: 4334ms;
}
.bottom-particles .bubble:nth-child(160) {
  left: 22%;
  -webkit-animation: blow 7996ms infinite;
          animation: blow 7996ms infinite;
  -webkit-animation-delay: 7151ms;
          animation-delay: 7151ms;
}
.bottom-particles .bubble:nth-child(161) {
  left: 73%;
  -webkit-animation: blow 6631ms infinite;
          animation: blow 6631ms infinite;
  -webkit-animation-delay: 3092ms;
          animation-delay: 3092ms;
}
.bottom-particles .bubble:nth-child(162) {
  left: 26%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 10149ms;
          animation-delay: 10149ms;
}
.bottom-particles .bubble:nth-child(163) {
  left: 73%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2247ms;
          animation-delay: 2247ms;
}
.bottom-particles .bubble:nth-child(164) {
  left: 72%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2452ms;
          animation-delay: 2452ms;
}
.bottom-particles .bubble:nth-child(165) {
  left: 13%;
  -webkit-animation: blow 10458ms infinite;
          animation: blow 10458ms infinite;
  -webkit-animation-delay: 4973ms;
          animation-delay: 4973ms;
}
.bottom-particles .bubble:nth-child(166) {
  left: 45%;
  -webkit-animation: blow 6390ms infinite;
          animation: blow 6390ms infinite;
  -webkit-animation-delay: 8637ms;
          animation-delay: 8637ms;
}
.bottom-particles .bubble:nth-child(167) {
  left: 6%;
  -webkit-animation: blow 5830ms infinite;
          animation: blow 5830ms infinite;
  -webkit-animation-delay: 9550ms;
          animation-delay: 9550ms;
}
.bottom-particles .bubble:nth-child(168) {
  left: 37%;
  -webkit-animation: blow 10074ms infinite;
          animation: blow 10074ms infinite;
  -webkit-animation-delay: 6189ms;
          animation-delay: 6189ms;
}
.bottom-particles .bubble:nth-child(169) {
  left: 95%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 8001ms;
          animation-delay: 8001ms;
}
.bottom-particles .bubble:nth-child(170) {
  left: 88%;
  -webkit-animation: blow 11688ms infinite;
          animation: blow 11688ms infinite;
  -webkit-animation-delay: 6679ms;
          animation-delay: 6679ms;
}
.bottom-particles .bubble:nth-child(171) {
  left: 23%;
  -webkit-animation: blow 12905ms infinite;
          animation: blow 12905ms infinite;
  -webkit-animation-delay: 8275ms;
          animation-delay: 8275ms;
}
.bottom-particles .bubble:nth-child(172) {
  left: 64%;
  -webkit-animation: blow 12992ms infinite;
          animation: blow 12992ms infinite;
  -webkit-animation-delay: 9652ms;
          animation-delay: 9652ms;
}
.bottom-particles .bubble:nth-child(173) {
  left: 80%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 9812ms;
          animation-delay: 9812ms;
}
.bottom-particles .bubble:nth-child(174) {
  left: 85%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 371ms;
          animation-delay: 371ms;
}
.bottom-particles .bubble:nth-child(175) {
  left: 68%;
  -webkit-animation: blow 5561ms infinite;
          animation: blow 5561ms infinite;
  -webkit-animation-delay: 5409ms;
          animation-delay: 5409ms;
}
.bottom-particles .bubble:nth-child(176) {
  left: 42%;
  -webkit-animation: blow 9455ms infinite;
          animation: blow 9455ms infinite;
  -webkit-animation-delay: 4254ms;
          animation-delay: 4254ms;
}
.bottom-particles .bubble:nth-child(177) {
  left: 11%;
  -webkit-animation: blow 10695ms infinite;
          animation: blow 10695ms infinite;
  -webkit-animation-delay: 2827ms;
          animation-delay: 2827ms;
}
.bottom-particles .bubble:nth-child(178) {
  left: 86%;
  -webkit-animation: blow 7092ms infinite;
          animation: blow 7092ms infinite;
  -webkit-animation-delay: 11252ms;
          animation-delay: 11252ms;
}
.bottom-particles .bubble:nth-child(179) {
  left: 44%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4154ms;
          animation-delay: 4154ms;
}
.bottom-particles .bubble:nth-child(180) {
  left: 2%;
  -webkit-animation: blow 13585ms infinite;
          animation: blow 13585ms infinite;
  -webkit-animation-delay: 7654ms;
          animation-delay: 7654ms;
}
.bottom-particles .bubble:nth-child(181) {
  left: 96%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 1341ms;
          animation-delay: 1341ms;
}
.bottom-particles .bubble:nth-child(182) {
  left: 58%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 7353ms;
          animation-delay: 7353ms;
}
.bottom-particles .bubble:nth-child(183) {
  left: 17%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 11434ms;
          animation-delay: 11434ms;
}
.bottom-particles .bubble:nth-child(184) {
  left: 14%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 10826ms;
          animation-delay: 10826ms;
}
.bottom-particles .bubble:nth-child(185) {
  left: 15%;
  -webkit-animation: blow 13230ms infinite;
          animation: blow 13230ms infinite;
  -webkit-animation-delay: 8663ms;
          animation-delay: 8663ms;
}
.bottom-particles .bubble:nth-child(186) {
  left: 50%;
  -webkit-animation: blow 12176ms infinite;
          animation: blow 12176ms infinite;
  -webkit-animation-delay: 1838ms;
          animation-delay: 1838ms;
}
.bottom-particles .bubble:nth-child(187) {
  left: 59%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 1375ms;
          animation-delay: 1375ms;
}
.bottom-particles .bubble:nth-child(188) {
  left: 79%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2843ms;
          animation-delay: 2843ms;
}
.bottom-particles .bubble:nth-child(189) {
  left: 51%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 831ms;
          animation-delay: 831ms;
}
.bottom-particles .bubble:nth-child(190) {
  left: 61%;
  -webkit-animation: blow 11775ms infinite;
          animation: blow 11775ms infinite;
  -webkit-animation-delay: 2254ms;
          animation-delay: 2254ms;
}
.bottom-particles .bubble:nth-child(191) {
  left: 83%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 4560ms;
          animation-delay: 4560ms;
}
.bottom-particles .bubble:nth-child(192) {
  left: 59%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 3183ms;
          animation-delay: 3183ms;
}
.bottom-particles .bubble:nth-child(193) {
  left: 60%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 2267ms;
          animation-delay: 2267ms;
}
.bottom-particles .bubble:nth-child(194) {
  left: 62%;
  -webkit-animation: blow 14174ms infinite;
          animation: blow 14174ms infinite;
  -webkit-animation-delay: 11432ms;
          animation-delay: 11432ms;
}
.bottom-particles .bubble:nth-child(195) {
  left: 26%;
  -webkit-animation: blow 5000ms infinite;
          animation: blow 5000ms infinite;
  -webkit-animation-delay: 8859ms;
          animation-delay: 8859ms;
}
.bottom-particles .bubble:nth-child(196) {
  left: 3%;
  -webkit-animation: blow 8703ms infinite;
          animation: blow 8703ms infinite;
  -webkit-animation-delay: 7679ms;
          animation-delay: 7679ms;
}
.bottom-particles .bubble:nth-child(197) {
  left: 33%;
  -webkit-animation: blow 11408ms infinite;
          animation: blow 11408ms infinite;
  -webkit-animation-delay: 1728ms;
          animation-delay: 1728ms;
}
.bottom-particles .bubble:nth-child(198) {
  left: 63%;
  -webkit-animation: blow 10369ms infinite;
          animation: blow 10369ms infinite;
  -webkit-animation-delay: 11028ms;
          animation-delay: 11028ms;
}
.bottom-particles .bubble:nth-child(199) {
  left: 17%;
  -webkit-animation: blow 11516ms infinite;
          animation: blow 11516ms infinite;
  -webkit-animation-delay: 5372ms;
          animation-delay: 5372ms;
}
.bottom-particles .bubble:nth-child(200) {
  left: 92%;
  -webkit-animation: blow 11136ms infinite;
          animation: blow 11136ms infinite;
  -webkit-animation-delay: 4105ms;
          animation-delay: 4105ms;
}

@-webkit-keyframes blow {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0, -100vh) scale(0.2);
  }
}

@keyframes blow {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0, -100vh) scale(0.2);
  }
}


.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
}

.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}
.line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  -webkit-animation: drop 45s 0s infinite;
          animation: drop 4s 0s infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
          animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.line:nth-child(1) {
  margin-left: -25%;
}
.line:nth-child(1)::after {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.line:nth-child(3) {
  margin-left: 25%;
}
.line:nth-child(3)::after {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

.top_slide {
    position: relative;
    margin-bottom: -140px;
    background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
}
#about_big .head_part h2, #spotlight .head_part h2 {
  font-weight: 700;
  font-size: 36px;
  color:#282828;
  line-height: 48px;
}
#spotlight .head_part span {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 20px;
    display: block;
    line-height: 26px;
}
.tiltle {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  color:#c62537;
}
.tiltle:before, .tiltle:after {
  height: 40px;
  width: 3px;
  background: #c62537;
  content: '';
  position: absolute;
  bottom: 48px;
  left: 5px;
}
.tiltle:after {
  bottom: 10px;
  height: 8px;
}
#about_big p span {
  font-size: 18px;
  color: #383838;
  font-weight: 500;
}
#about_big p {
    color: #383838;
    font-weight: 300;
}
.stats span {
  display: block;
}
.stats {
  background: #222;
  margin: 50px 0 0;
  padding: 25px 40px 25px 0;
  position: relative;
}
.stats ul {
  display: flex;
  justify-content: space-between;
}
#about_big {
    padding: 7rem 0 8rem;
    background: #fff;
}
.about_img img {
  max-width: 175px;
  margin: 45px 5px 0;
  height: 407px;
  position: relative;
  z-index: 9;
  border: 5px solid #fff;
  transition: all ease 0.4s;
}
.about_img img:hover {
    transform: translate(0, 20px) !important;
}
#about_big .stats p span {
  color: #fff;
  font-size: 29px;
  font-weight: 700;
}
#about_big .about_img {
  padding-left: 40px;
}
#about_big .stats p {
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}
.stats:before {
  content: '';
  position: absolute;
  left: -100%;
  background: #222;
  width: 100%;
  height: 100%;
  top: 0;
}
.about_img img:nth-child(even) {
  margin-top: -55px;
}
#feature_oppertunity{
        background:linear-gradient(293deg, #f35524 4%, #c82538 27%, #1c1c1c 82%);  
        background:-webkit-linear-gradient(293deg, #f35524 4%, #c82538 27%, #1c1c1c 82%);  
        background-attachment: fixed;
         padding: 0 0 7rem; position: relative; overflow: hidden;}
#feature_oppertunity:before {
    content: '';
    background: url(/images/feature_bg.png);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
#feature_oppertunity .tiltle, #feature_oppertunity h2 {
  color: #fff;
}
#feature_oppertunity .item {
    margin: 0 10px;
}
#feature_oppertunity .owl-dots {
    display: none;
}
// #feature_oppertunity:after {
//   background:#030c18;
//   content: '';
//   width: 47%;
//   height: 100%;
//   right: 0;
//   position: absolute;
//   top: 0;
// }
#feature_oppertunity h2 {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
}
#feature_oppertunity .owl-nav span {
    width: 30px;
    height: 30px;
    color: #fff;
    display: inline-block;
    border-radius: 50px;
    line-height: 27px;
    font-size: 34px;
    border: 1px solid #fff;
}
#feature_oppertunity .owl-nav {
    position: absolute;
    left: -85px;
    bottom: 12px;
    z-index: 9999;
}
#feature_oppertunity .owl-theme .owl-nav [class*='owl-']:hover {
    background: transparent;
}
#feature_oppertunity .owl-nav button:hover span {
    color: #34246d;
    background: #fff;
    border-color: #fff;
}
.feature_tab {
  position: relative;
  z-index: 9;
}
.white_bx {
  margin: 20px 0;
  padding: 25px;
  box-shadow:0 4px 14px -1px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}
#feature_oppertunity .tab-content .white_bx{ border-bottom: 2px solid transparent; background:rgba(0,0,0,0.7);}
#feature_oppertunity .tab-content .white_bx:hover {
    transform: translate(0, 10px);
    border-bottom: 2px solid #f20d69;
}
.feature_tab h4 {
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    margin: 0 0 6px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.feature_tab p {
     font-size: 13px;
    line-height: 22px;
    color: #c5c5c5;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
#feature_oppertunity .item span {
    color: #c5c5c5;
    margin: 0 0 15px;
    display: block;
    border-bottom: 1px solid #50353c;
    padding: 0 0 15px;
    font-size: 13px;
}
#feature_oppertunity .nav-tabs > li > a:hover {
    border-color: transparent;
    background: transparent;
}
.feature_tab p small {
  display: block;
  margin: 12px 0 23px;
}
.feature_tab .nav-tabs>li{float: right;}
.feature_tab .nav-tabs {
      border-bottom: 2px solid #ffffff;
    margin: 34px 0 30px;
    padding-bottom: 8px;
}
.feature_tab .nav-tabs>li.active>a, .feature_tab .nav-tabs>li.active>a:focus, .feature_tab .nav-tabs>li.active>a:hover {
  background-color: transparent;
  border: none;
  color: #f20d69;
}
.feature_bg {
  position: absolute;
  left: 0;
  bottom: 0;
}
#feature_oppertunity .head_part {
  padding: 7rem 0 0;
}
.feature_tab .nav-tabs>li>a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
#trusted_parnter {
    position: relative;
    z-index: 99;
    background: #fff;
    padding:19rem 0 2rem;
}
#trusted_parnter .white_bx {
    margin: 0;
}
.inner_text h1 span {
  color: #e3293e;
}
.inner_text h1 {
  font-size: 36px;
  color: #fff;
  font-weight: 700;
  margin: 0 0 20px;
  line-height: 45px;
}
.inner_text {
  padding-left: 30px;
}
#trusted_parnter img {
    margin: 0 14px;
    max-width: 135px;
}
#trusted_parnter h4 {
    color: #fff;
    background: #4f3789;
    display: inline-block;
    padding: 12px 12px 10px;
    font-size: 17px;
}
#trusted_parnter .owl-carousel{
    border: 1px solid #222;
    padding: 30px 15px 0;
    background: #222;
}
#trusted_parnter .owl-carousel .owl-dots{display: none;}
.toggle-icon {
  background:transparent !important;
}
#trusted_parnter .owl-carousel .owl-item img{width:auto;}
.stats ul li img {
  max-width: 50px;
  display: inline-block;
  vertical-align: sub;
  margin-bottom: 7px;
}
#testimonial{padding:8rem 0 9rem;  background: #fff;}
#testimonial h2{color: #282828;  font-weight: 700;  font-size: 36px;}
.testinonial_bx p{color: #383838;  font-weight: 300;}
.testinonial_bx {
    padding: 30px 30px 25px;
    background: #fff;
    box-shadow: 1px 0 13px -3px rgba(0, 0, 0, 0.15);
    margin: 18px 14px;
    border: 1px solid #eee;
}
.user_part {
    margin: 20px 0 0;
    display: inline-flex;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    padding: 20px 0 0;
}
#testimonial .owl-theme .owl-nav.disabled + .owl-dots {
    bottom: -43px;
}
.user_part img {
    max-width: 45px;
    height: 45px !important;
    object-fit: cover;
    border-radius: 50px;
    margin-right: 15px;
}
.user_part h6 {
        color:#282828 !important
 }

/* journey section */
#journey {
    background:#0f1010 url(/images/journey_bg.jpg);
    padding: 7rem 0;
    background-repeat: no-repeat;
    background-position: 165% 101%;
    background-attachment: fixed;
}
#journey h2 {
    margin: 0 0 45px;
}
#journey h2, #process .head_part h2{color:#fff; font-size: 36px; font-weight: 700;}
#process .head_part h2{color:#282828;}
#process .tiltle:before, #process .tiltle:after {
        left: 0;
        right: 0;
        margin: 0 auto;
}
#journey .tiltle{color:#fff;}
#journey ul li h6 {
    font-weight: 700;
    font-size: 18px;
    color:#d72438 !important;
    padding: 0 0 0;
    margin-top: 0;
    display: inline-flex;
    align-items: center;
    line-height: 22px;
}
#journey ul li p {
    color: #c5c5c5;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
}
#journey ul {
    margin: 0 -10px;
}
#journey ul li {
    padding: 25px 26px;
    vertical-align: top;
    display: inline-block;
    width: 46%;
    background:#1e1e1e;
    box-shadow: 0 3px 22px -5px rgba(0, 0, 0, 0.4);
    margin: 10px 1.5%;
}
#journey ul li:last-child{border:none;}
#journey ul li img {
    z-index: 9;
    max-width: 65px;
    margin-left: 60px;
}
#journey ul li .blue_btn {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 0;
    text-align: center;
    line-height: 31px;
    vertical-align: middle;
    min-width: 30px;
    margin: 0 14px 0 0;
}

/* spotlight */
#spotlight {
    background: -webkit-linear-gradient(303deg, #f35524 10%, #c82538 28%, #1c1c1c 198%);
    background: linear-gradient(303deg, #f35524 10%, #c82538 28%, #1c1c1c 198%);
    padding:8rem 0 19rem;
    position: relative;
    overflow: hidden;
}
#spotlight_list ul li {
    margin: 0 8px;
}
#spotlight_list ul {
    display: flex;
}
.project {
        width: 100%;
        height: 360px;
        background-image: url('/images/project.jpg');
        background-size: cover;
        background-position: center;
        padding: 0 !important;
        float: left;
    }
.project:nth-child(2){ background-image: url('/images/audition_call.jpg');}
.project:nth-child(4){ background-image: url('/images/film_industry.jpg');}
.project:nth-child(5){ background-image: url('/images/network_event.jpg');}
.project:nth-child(3){ background-image: url('/images/success_story.jpg');}


.project-hover {
    width: 100%;
    height: 100%;
    color: #fff;
    opacity: 0;
    transition: all 0.5s ease;
    background-color:rgba(159, 19, 19, 0.85);
    padding: 20% 25px !important;
}

.project-hover hr {
	height: 20px;
	width: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	background-color: rgba(255, 255, 255, 1);
	border: 0;
}

.project:hover .project-hover {
	opacity: 1;
}

.project:hover .project-hover hr {
	width: 100%;
	height: 2px;
}
.project p {
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    font-weight: 300;
}
.project h4{color:#fff;}
.spot_img img {
    margin: 0 8px;
    transition: all ease 0.3s;
    max-width: 260px;
}
#spotlight .head_part h2{color: #fff;}
#spotlight .tiltle {
    color: #fff;
}
#spotlight p{margin: 0;
    color: #ebebeb;
    font-weight: 300;
}
#spotlight .blue_btn {
    margin: 30px 0 0;
}
#spotlight_list {
    background: #0f1010;
    padding: 0 0 8rem;
}
#spotlight_list h3 {
    margin: -12rem 0 34px;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
}
#top_cards {
    padding:0;
    background: transparent;
    position: relative;
    z-index: 999;
}
#slider_part .carousel-inner .inner_text {
    padding-left: 37px;
}
.spot_img img:hover {
    transform: translate(0, 20px);
}
.spot_img img {
    margin: 0 8px;
    transition: all ease 0.3s;
}
#spotlight .blue_btn {
    background: #fff;
    color: #0c0c0c !important;
}
#slider_part .stroke_btn:hover{background: #cd1227 !important;}

/* process section */
#process {
    background: #fdf7f2;
    padding: 7rem 0 8rem;
}
.process_outline {
    border: 9px solid #48128f;
    padding: 35px;
    --border-angle: 0turn;
    --main-bg: conic-gradient(from var(--border-angle), #222, #222 5%, #222 60%, #222 95%);
    border: solid 5px transparent;
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #c62537, #f35524 99%, transparent);
    background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
    background-position: center center;
    animation: bg-spin 3s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
.process_outline:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
.process_outline h3 {
    color: #fff;
    font-weight: 600;
    margin: 22px 0 12px;
    font-size: 22px;
}
.process_outline p {
    color: #c5c5c5;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
}
.process_outline img {
    max-width: 80px;
}
#process .head_part {
    margin: 0 0 20px;
}
.head_part h2 {
    color:#282828;
}

/* communitty part */
#big_community {
    padding: 8rem 0 7rem;
}
 #big_community h2 {
    font-weight: 700;
    font-size: 38px;
}
#big_community h4 {
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 22px;
}
#big_community p {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
}
.event_high h3 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 40px;
}
#big_community .event_high {
    padding-left: 10rem ;
}
.comunity_bx {
    border: 1px solid #ededed;
    padding: 12px;
    min-height: 351px;
    background: #f7f7f7;
}
#big_community .comunity_bx h6 {
    color:#282828 !important;
    font-weight: 600;
    line-height: 23px;
}
#big_community .comunity_bx p {
    font-size: 13px;
    line-height: 19px;
    color: #383838;
}
#process .blue_btn, #spotlight .blue_btn, #about_big .blue_btn, #journey .blue_btn{
    margin: 4rem 0 0;
    font-size: 16px;
    padding: 11px 30px;
    font-weight: 600;
}
.top_slide .top_head {background:transparent; z-index: 9;}