*{
	box-sizing:border-box;
}
body{
	/* background-color:#abd9e9;
	font-family:Arial; */
}
#container{
	/* width:750px;
	height:800px; */
	background:#06101e;
	margin:0 auto;
	font-size:0;
	/* border-radius:5px; */
	overflow:hidden;
}
aside ::-webkit-scrollbar {
	width: 10px;
  }
  aside img{
	width: 50px;
	height: 50px;
	border-radius: 50%;
  }
aside{
	/* width:260px;
	height:800px; */
	background-color:#3b3e49;
	display:inline-block;
	font-size:15px;
	vertical-align:top;
}
main{
	/* width:490px; */
	/* height:800px; */
	width: calc(100% - 270px);
	display:inline-block;
	font-size:15px;
	vertical-align:top;
	
}

aside header{
	padding:0px 20px;
	height: 100px;
	display: flex;
    align-items: center;
	justify-content: space-between;
	color: #fff;
	width: 270px;
	border-bottom: 1px solid rgba(148, 147, 147, 0.422);
}
aside header .my-name{
	font-size: 24px;
}
aside input{
	width:100%;
	height:50px;
	line-height:50px;
	padding:0 50px 0 20px;
	background-color:#5e616a;
	border:none;
	border-radius:3px;
	color:#fff;
	background-image:url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
	background-repeat:no-repeat;
	background-position:170px;
	background-size:40px;
}
aside input::placeholder{
	color:#fff;
}
aside ul{
	padding-left:0;
	margin:0;
	list-style-type:none;
	overflow-y:scroll;
	height: calc(100vh - 100px);
}
aside li{
	padding:10px 0;
}
aside li:hover{
	background-color:#5e616a;
}
h2,h3{
	margin:0;
}
aside li img{
	border-radius:50%;
	margin-left:20px;
	margin-right:8px;
	border-radius: 50%;
	height: 36px;
	width: 36px;
}
aside li div{
	display:inline-block;
	vertical-align:top;
	margin-top:12px;
}
aside li h2{
	font-size:14px;
	color:#fff;
	font-weight:normal;
	margin-bottom:5px;
}
aside li h3{
	font-size:12px;
	color:#7e818a;
	font-weight:normal;
}

.status{
	width:8px;
	height:8px;
	border-radius:50%;
	display:inline-block;
	margin-left:7px;
}
.green{
	background-color:#58b666;
}
.orange{
	background-color:#ff725d;
}
.blue{
	background-color:#b5e2ff;
	margin-right:0;
	margin-left:7px;
	color: blue;
    border: 1px solid blue;
}

main header{
	height:100px;
	padding:30px 20px 30px 40px;
	display: flex;
    align-items: center;
}
main header > *{
	display:inline-block;
	vertical-align:top;

}
main header img:first-child{
	border-radius:50%;
}
main header img:last-child{
	width:24px;
	margin-top:8px;
	border-radius: 50%;
}
main header div{
	margin-left:10px;
	margin-right:145px;
}
main header h2{
	font-size:16px;
	margin-bottom:5px;
}
main header h3{
	font-size:14px;
	font-weight:normal;
	color:#7e818a;
}

#chat{
	padding-left:0;
	margin:0;
	list-style-type:none;
	overflow-y:scroll;
	height: calc(100vh - 180px);
	border-top:2px solid #fff;
	border-bottom:2px solid #fff;
}
#chat li{
	padding:10px 30px;
}
#chat h2,#chat h3{
	display:inline-block;
	font-size:13px;
	font-weight:normal;
}
#chat h3{
	color:#bbb;
}
#chat .entete{
	margin-bottom:5px;
}
#chat .message{
	padding:20px;
	color:#fff;
	line-height:25px;
	max-width:90%;
	display:inline-block;
	text-align:left;
	border-radius:5px;
}
#chat .me{
	text-align:right;
}
#chat .you .message{
	background-color:#58b666;
}
#chat .me .message{
	background-color:#6fbced;
}
#chat .triangle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 10px 10px;
}
#chat .you .triangle{
		border-color: transparent transparent #58b666 transparent;
		margin-left:15px;
}
#chat .me .triangle{
		border-color: transparent transparent #6fbced transparent;
		margin-left:375px;
}

main footer{
	/* height:155px; */
	height: 80px;
	padding:16px;
	display: flex;
}
main footer input{
	resize:none;
	border:none;
	display:block;
	width:100%;
	border-radius:3px;
	padding:10px;
	font-size:13px;
	color: #5e616a;
	font-size: 16px;
}
main footer input::placeholder{
	color:rgb(161, 160, 160);
}
main footer img{
	height:30px;
	cursor:pointer;
}
main footer .send{
	text-decoration:none;
	text-transform:uppercase;
	font-weight:bold;
	background:#6fbced;
	vertical-align:top;
	display:inline-block;
	margin-left: 16px !important;
}