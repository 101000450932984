
.profile_area .job_list h6 {
    color: #003e7e;
}
.profile_area .job_list_info ul li {
    width: 50%;
    float: left;
    margin: 2px 0;
}
.award_pic img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 0;
    margin-right: 20px;
}
.award_bx {
    display: flex;
    align-items: start;
    margin: 10px 0;
}
.award_detail span {
    display: inline-block;
    border-radius: 50px;
    background: #1683f3;
    padding: 1px 15px;
    color: #fff;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 20px;
}

.profile_info_bx {
    margin: 0 0 20px;
    line-height: 20px;
}

.about_profile ul li {
    padding-right: 32px;
    font-size: 13px;
    line-height: 18px;
}
.about_profile ul li span {
    display: block;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 7px;
}
.profile_area .job_list ul li span {
    font-weight: 500;
    width: 72%;
}
.tab_info.client_his h5 {
    font-weight: 600;
    margin: 0 0 5px;
    font-size: 18px;
}
.tab_info.client_his ul li small {
    margin-right: 14px;
}
.profile_area .work_head{margin: 0 0 10px; display: flex; position: relative;}
hr {
    border-top: 1px solid #ddd;
}
.quick_link {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 10px 35px;
    background: #00234b;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.quick_link ul li {
    display: inline-block;
    margin: 0 10px;
}
.quick_link ul li a {
    color: #ffffff;
    transition: all ease-in-out 0.4s;
    font-size: 13px;
}
.quick_link ul li a svg {
    color: #15beff;
    width: 20px;
    vertical-align: baseline;
    margin: 0 3px -2px 0;
}
.quick_link ul li a:hover, .quick_link ul li a:active, .quick_link ul li a:focus{color:#fff;}

.side_details .shadow_bg::-webkit-scrollbar-track{
  background: #eee;
}
.side_details .shadow_bg::-webkit-scrollbar-thumb {
  background: #bdc8d5;
}
.side_details .shadow_bg::-webkit-scrollbar-thumb:horizontal{
  background: #bdc8d5;
}
/* width */
.side_details .shadow_bg::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
.side_details .shadow_bg::-moz-scrollbar-track{
  background: #eee;
}

/* Handle */
.side_details .shadow_bg::-moz-scrollbar-thumb{
  background: #bdc8d5;
}
.award_detail span:empty {
    display: none;
}
.people_detail_banner .dark_bg small {
    color: #3a3a3a;
    display: block;
    border-bottom: 1px solid #f1f1f1;
    margin: 10px 0 11px;
    font-weight: 600;
    padding-bottom: 7px;
}
.edit-merit {
    display: block;
    text-align: center;
    background: #f9f9f9;
    padding: 2px;
    transition: all ease 0.4s;
}
.edit-merit a{color:#3a3a3a;}
.edit-merit:hover {
    background: #222222;
}
.edit-merit:hover a{color: #fff;}
.progress {
    background-color: #efefef !important;
}