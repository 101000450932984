
.top_nav .notification_img img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
  }
  .top_nav .notification_img {
    min-width: 48px;
    display: inline-table;
    width: 48px;
}
  .dropdown-menu.notifications {
   min-width:390px; 
  }
  
  .notifications-wrapper {
     overflow:auto;
      max-height:340px;
  }
    
  .menu-title {
     color:#003e7e;
     font-size:1.5rem;
      display:inline-block;
      }
  
  .glyphicon-circle-arrow-right {
      margin-left:10px;     
   }
  .notifications .badge {
    background: #1683f3;
    font-weight: 500;
    min-width: 28px;
    font-size: 12px;
  }
   
  .notification-heading {
    padding: 8px 10px 3px;
  }  
  
  .notifications .item-title {
    font-size: 13px;
    color: #000;
    font-weight: 400;
    line-height: 19px;
  }
  .notifications-wrapper h6, .notification_label {
    background: #f3f5f6;
    padding: 8px 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin: 10px 0;
    color: #333!important;
  }
  .notifications a.content small {
    color: #1683f3;
  }
  .notifications a.content {
      text-decoration: none;
      display: flex;
      width: 100%;
      align-items: start;
      padding:6px 13px;
      color: #686868;
      transition: all ease 0.3s;
  }
  .notification_list ul li .casting_list_head {
    display: inline-flex;
    width: 70%;
    align-items: self-start;
  }
  .notification_list ul li .casting_list_head h3 {
    margin: 0 0 2px;
    font-size: 15px;
    font-weight: 600;
  }
  .notification_list ul li .casting_list_head{margin: 0;}
  #breakdown_list.notification_list .breakdown_casting_info {
    padding: 15px 0;
  }
  .notification_list ul li .noti_detail span {
    color: #1683f3;
    font-size: 11px;
  }
  .notification_list ul li .noti_detail p {
    font-size: 13px;
    margin: 0;
    line-height: 20px;
  }
  .notification_list ul li .notification_img img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 20px 0 0;
    max-width: 65px;
  }
  .notification_list ul li .side_action_btn {
    width: auto;
    text-align: right;
    float: right;
  }
  .notification_label {
    margin: 0;
    padding: 6px 30px;
    width: 100%;
    border:none;
  }
  .notifications a.content:hover {
    background: #f7f9fa;
  }
  .notification_list h2 {
    color: #fff;
    font-size: 27px;
    margin: 0 0 25px;
  }
  .notification_list .post_result ul li:first-child .breakdown_casting_info {
    padding-top: 15px !important;
  }
  #breakdown_list.notification_list .post_result ul li:last-child .breakdown_casting_info {
    border:none;
  }

  .notifications-wrapper::-webkit-scrollbar-track, .film_cat_menu .multi-level::-webkit-scrollbar-track{
    background: #eee;
  }
  .notifications-wrapper::-webkit-scrollbar-thumb, .film_cat_menu .multi-level::-webkit-scrollbar-thumb {
    background: #bdc8d5;
  }
  .notifications-wrapper::-webkit-scrollbar-thumb:horizontal{
    background: #bdc8d5;
  }
  /* width */
  .notifications-wrapper::-webkit-scrollbar, .film_cat_menu .multi-level::-webkit-scrollbar{
    width: 5px;
    height: 4px;
  }
  
  /* Track */
  .notifications-wrapper::-moz-scrollbar-track, .film_cat_menu .multi-level::-moz-scrollbar-track{
    background: #eee;
  }
  
  /* Handle */
  .notifications-wrapper::-moz-scrollbar-thumb, .film_cat_menu .multi-level::-moz-scrollbar-thumb{
    background: #bdc8d5;
  }
  
  .noti-count {
    position: absolute;
    // top: 18px;
    left: 50%;
    top: 0;
    transform: translate(0, 5px);
    color: #fff;
    font-size: 12px;
    z-index: 84783478;
    width: 22px;
    height: 22px;
    background: #f21c65;
    border-radius: 50%;
    // right: 6px;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
}