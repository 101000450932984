
.celebrity_detail .people_img img {
  width: 100%;
  height: 200px;
  border-radius: none;
  object-fit: cover;
  position: relative;
  z-index: 1;
  border: none;
  object-position: top;
}

/*.celebrity_detail .people_img:after {
    content: '';
    width: 160px;
    height: 160px;
    background: linear-gradient(45deg, #3bade3 0%, #576fe6 25%, #9844b7 51%, #ff357f 100%);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 0;
    top: -5px;
} */
.celebrity_detail .people_img {
  position: relative;
  margin: 0 0 15px;
}
.audition_people_list .inside_listing_tab{background:transparent;}

.audition_people_list .inner_tab li.active a, .audition_people_list .inner_tab li.active a:focus,.audition_people_list .inner_tab li.active a:hover {
  color: #03b5fa;
  background: transparent;
  border: none;
  background: rgba(0,0,0,0.2);
  border-radius: 50px;
  border: 1px solid #17416a;
  opacity: 1;
}
.audition_people_list .inner_tab li a:hover{color: #03b5fa; background: transparent; opacity: 1;}
.audition_people_list .inner_tab li a {
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 400;
  padding: 6px 20px;
  opacity: 0.9;
}
.audition_people_list .inner_tab{border:none;}
.audition_people_list .search_field .default_btn {
  width: auto;
  background: transparent;
  position: absolute;
  height: auto;
  right: 14px;
}
.dark_search input {
  float: none;
  width: 70%;
  background: #f5f5f7;
  border: 1px solid #87888a;
  padding-right: 40px;
  color: #474646;
}
.celebrity_detail .people_list {
  display: block;
  color: #fff;
  padding-bottom: 0;
}
.celebrity_detail .end_info.double_action .stroke_btn:last-child {
  background: #121d45;
}
.celebrity_detail .end_info.double_action .stroke_btn {
  width: 50%;
  float: left;
  display: inline-block;
  padding: 8px 5px;
}
.celebrity_detail .people_list_info{display: block; padding: 0 20px;}
.celebrity_detail .people_short_info span {
  display: inline;
  padding: 0 10px;
  color: black;
  font-weight: 400;
}
.celebrity_detail .people_list_info h6{font-weight: 500;}
.celebrity_detail .people_short_info span:first-child {
  border-right: 1px solid #ddd;
}
.celebrity_detail .end_info .stroke_btn {
  border: none;
  color: #fff !important;
  border-radius:0;
  width: 100%;
  background: #c62537;
  font-size: 14px;
  margin:10px 0 0;
}
.celebrity_detail .people_list_info {
  display: block;
  min-height: 60px;
}
.audition_people_list .end_info .icon {
  width: 32px;
  height: 32px;
  background: #09142e;
  display: inline-block;
  text-align: center;
  line-height: 37px;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 30px;
}
.celebrity_detail .circle_icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  bottom: 16px;
  margin-left: -24px;
  border: 3px solid #fff;
  z-index: 1;
}
.cele_price {
  padding: 4px 20px;
  border-radius: 50px;
  display: inline-block;
  margin: 0 0 20px;
  border: 1px solid #72748f;
}
.people_short_info span:empty {
  display: none;
}
.celebrity_detail {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 0;
  position: relative;
  min-height: 400px;
  transition: all 0.5s ease; 
}
.celebrity_detail:hover {
  background-color: #f0f0f0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  transform: translateY(-1px);
}

.dark_pagi li a {
  background: transparent !important;
  margin: 0 5px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #1a304f !important;
}

.audition_info .casting_list_head {
  width: 100%;
}

.audition_list .tab-content ul li, .audition_list .post_result ul li{
  display: inline-flex;
  align-items: start;
  width:100%;
}

#breakdown_list .breakdown_casting_info.audition_info {
  margin: 0;
padding:20px 0 0;
border:none;
width: 100%;
}

.audition_list .searchjob_bx {
  text-align: right;
  margin:0;
  color: #6e6e6e;
}
.audition_info .casting_list_head span {
  margin-right: 6px;
  background: #eafbe6;
  padding: 0px 10px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 4px;
  line-height: 22px;
  color: #0b7e25;
  font-weight: 500;
  font-size: 11.5px;
}
.bg_img {
  opacity: 0.6;
}
.audition_list .pagination li {
  padding: 0;
  border: none;
  display: inline-block !important;
  width: auto !important;
}
.audition_people_list .post_result .search_field input{width: 100%;}
.celebrity_detail .people_list_info h6 {
  display: inline-block;
  color:black;
  
}
.talent_list {
  margin: 0 0 5px;
}
.talent_list p {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 13px;
  color: black;
}

.find_talent_list  .cele_price {
  margin: 0 0 10px;
}

.save_btn{
  background: #1683f3;
}

.time-picker:before {
    content: 'HH:MM';
    position: absolute;
    background: #fff;
    height: 35px;
}


.date-time-picker:before {
    content: 'DD/MM/YYYYY';
    position: absolute;
    background: #fff;
    height: 35px;
}
#cta.inner_banner .right_pattern {
  opacity: 1;
  left: auto;
  object-position: 48%;
  top: -75px;
  right: -67px;
}
.find_talent_list .cele_price{line-height: 18px;}
.people_list_info .bottom_desc {
  margin: 23px 0 0;
}
.people_list_info .default_progress span {
  font-size: 12px;
  color: black;
}
.audition_date img {
  height: 160px;
  width: 100%;
  object-fit: cover;
}
.audition_date {
  margin: -20px -20px 0;
}
.audition_info .casting_list_head span svg {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -1px;
}
p.audi_date {
  margin: 0 0 7px;
}
.audition_info hr {
  margin: 15px 0;
}
.audtion_content {
  min-height: 164px;
}
#audition_banner {
  background: -webkit-linear-gradient(298deg, #f35524 6%, #c82538 21%, #1c1c1c 84%);
  background: linear-gradient(298deg, #f35524 6%, #c82538 21%, #1c1c1c 84%);
  height:350px;
  overflow:hidden;
}
.top_slide.inner_header{margin-bottom: 0;}
.inner_banner_text h2 {
  color: #fff;
  font-weight: 700;
  line-height: 42px;
}
.inner_banner_text p {
  margin: 20px 0 30px;
  color: #e7e5e5;
  font-weight: 300;
}
.inner_banner_text {margin-top:5rem;}
.inner_banner_text .blue_btn {
  margin-right: 14px;
}
.search_job_list .flex_bx {
  justify-content: left;
  align-items: baseline;
  margin: 10px -6px 0;
}
#breakdown_list.audition_list {
  background: #f9f9f9;
  padding: 3rem 0;
}
.audition_list .outline_bx {
  background: #fdfdfd;
}
.search_job_list .flex_bx .blue_btn {
  width: 18%;
}
.search_job_list .flex_bx .filter_points {
  width: 33%;
  margin: 0 10px;
  padding: 0;
}
.search_job_list .flex_bx .form-group {
  width: 48%;
  margin: 0;
}
.search_job_list .flex_bx .blue_btn svg {
  vertical-align: middle;
  width: 13px;
  margin-right: 5px;
}
.page-item:first-child .page-link{
    background: transparent;
  border: none;
}
.page-item:last-child .page-link {
  border-radius: 0;
  background: transparent;
  border: none;
}
#breakdown_list.audition_list .pagination {
  margin: 30px 0 0;
}
.audition_list .outline_bx:hover h3 {
  color: #e11a31;
  transition: all ease 0.4s;
}
.username-ellipsis {
  white-space: nowrap;        /* Prevents text from wrapping to the next line */
  overflow: hidden;           /* Hides the overflowed text */
  text-overflow: ellipsis;    /* Adds '...' when the text overflows */
  max-width: 100%;            /* Ensures it takes the full width of the container */
  display: inline-block;      /* Ensures proper truncation */
}
