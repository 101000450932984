
.suggested_ques p {
    margin: 18px 0 15px;
}
.suggested_ques label {
    margin: 7px 0;
}

.screen_ques span {
    display: inline-block;
    height: 44px;
    width: 44px;
    background: #1683f3;
    color: #fff;
    text-align: center;
    vertical-align: bottom;
    border-radius: 4px;
    margin-left: 9px;
    font-size: 17px;
    line-height: 45px;
}
.screen_ques input[type="text"] {
    width: 50%;
    display: inline-block;
}
#breakdown_list .breakdown_casting_info {
    margin:0 !important;
    padding:21px 0;
    font-size: 13px;
    line-height: 24px;
    border-bottom: 1px solid #ddd;
}
.filter_list {
    margin: 0 0 20px;
}
.filter_list label {
    margin: 9px 0;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
}
.job_list_sidebar h4 {
    margin: 0 0 15px;
    font-weight: 700;
    color: #fff;
}
.job_list_sidebar strong{color:#fff;}
.list_bg {
    padding: 20px;
    border: 1px solid #eee;
    box-shadow: 0 0 17px -3px rgba(0, 0, 0, 0.08);
}
.filter_list p {
    background: transparent;
    margin: 0 -20px;
    padding: 10px 20px 3px;
    text-transform: uppercase;
    border-top: 1px solid #353b64;
}
.search_field input {
    width: 50%;
    display: inline-block;
    float: left;
}
.post_result .search_field input {
    width: 93%;
    display: inline-block;
    float: left;
}
.search_field .default_btn {
    height: 45px;
    padding: 0 15px;
    margin-left: 6px;
    line-height: 51px;
    font-size: 16px;
    vertical-align: bottom;
    width: 45px;
}
.job_list .search_field .default_btn{width: auto;}
.post_result ul li:first-child .breakdown_casting_info{padding-top: 0 !important;}
#postjob_detail .application_btn {
    margin-left: 60px;
}
#postjob_detail .main_head {
    margin: 26px 0px;
    color:black;
    font-size: 28px;
    position: relative;
}
#postjob_detail .shadow_bg{position: relative;}
#postjob_detail .detail_sidebar {
    height: 100%;
    background:white;
    top: 0;
    right: 0;
    border: 1px solid white;
    padding: 20px;
    border-radius: 8px;
    color: black;
    font-size: 13px;
}
.about_client ul li strong {
    color: #bbc2d9;
}
.detail_sidebar .stroke_btn {
    min-width: 205px;
    text-align: center;
    margin: 0 0 13px;
    font-size: 13px;
}
#postjob_detail .job_cat small {
    color: #868686;
    display: block;
    margin: 2px 0 0;
}
#postjob_detail .job_cat {
    margin: 0 0 20px;
    font-size: 15px;
}
#postjob_detail .job_cat h6 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #0f70d5;
}
.job_list ul li {
    margin: 8px 0;
}
.job_list ul li small {
    display: block;
}
.mid_job_info ul li small {
    display: block;
    color: #898a8d;
    font-size: 13px;
    line-height: 19px;
}
.mid_job_info ul li .side_icon {
    width: 40px;
    height: 40px;
    border: 1px solid #c6c6c6;
    border-radius: 50%;
    margin-right: 15px;
    line-height: 42px;
    font-size: 15px;
    color: #393a3a;
}
.mid_job_info ul li {
    display: inline-flex;
    align-items: center;
    width: 33.3%;
    float: left;
}
.job_list h6 {
    font-weight: 700;
    font-size: 17px;
    margin: 0 0 20px;
    color: #333 !important;
}
.detail_sidebar .stroke_btn svg {
    vertical-align: middle;
}

.job_list ul li strong {
    margin-right: 5px;
}
.postjob_tab .nav-tabs {
    border: none;
}
.postjob_tab, .bid_info {
    margin: 0 0 30px;
    background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    border: none;
    border-radius: 50px;
}
.postjob_tab .nav-tabs li {
    width: 25%;
    text-align: center;
    margin: -1px 0 -3px;
}
.postjob_tab .nav-tabs li a {
    padding: 14px 6px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin: 0;
    color: #fff;
    font-size: 13px;
}
.casting_list_btn {
    margin: 14px 0 0;
}
.postjob_tab .nav-tabs > li.active > a, .postjob_tab .nav-tabs > li.active > a:focus, .postjob_tab .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: rgba(0,0,0, 0.3);
    border: transparent;
    border-radius: 50px;
    font-weight: 600;
  }
.postjob_tab .nav>li>a:focus, .postjob_tab .nav>li>a:hover{
    background-color: transparent;
    color: #fff !important;
    font-weight: 500;
}
.inside_listing_tab .nav>li>a:focus, .inside_listing_tab .nav>li>a:hover {
    background-color: transparent;
    color:#333;
    font-weight: 500; 
}
.job_action ul li strong {
    margin-right: 8px;
}
.job_action ul li {
    display: inline-block;
    font-size: 13px;
    color: #646464;
    padding: 0 15px;
}
.job_action {
    display: inline-block;
}

.inside_listing_tab {
    border-radius: 6px;
}
.inside_listing_tab .nav-tabs li a {
    padding: 0 30px 15px;
    font-weight: 500;
    color: #4b4b4b;
    border:none;
}
.inside_listing_tab .nav-tabs>li.active>a, .inside_listing_tab .nav-tabs>li.active>a:focus, .inside_listing_tab .nav-tabs>li.active>a:hover {
    color: #c62537;
    cursor: default;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 3px solid #c62537;
}

.inside_listing_tab ul li .people_img img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 6px;
    background: #eee;
}
.inside_listing_tab ul li .people_img .circle_icon { bottom: -11px;}
.people_list_info h6 {
    font-size: 17px;
    font-weight: 600;
    margin: 5px 0;
}
.people_list_info .name_title {
    font-size: 18px;
    font-weight: 600;
}
.people_list_info span strong {
    color: #949497;
    font-weight: 500;
}
.people_list_info small {
    font-size: 13px;
    color: #f62858;
    display: block;
    margin: 3px 0;
}
.skills span {
    background: #eff2f3;
    padding: 0 15px;
    border-radius: 20px;
    margin: 5px 8px 5px 0;
    font-size: 12px;
    display: inline-block;
}
.skills p {
    font-weight: 600;
    margin: 0 0 2px;
}
.people_list_info, .side_action_btn {
    display: inline-block;
    vertical-align: top;
}
.side_action_btn .stroke_btn, .side_action_btn .default_btn {
    font-weight: 500;
    font-size: 13px;
    margin-left: 20px;
    padding: 4px 20px;
    min-width: 85px;
    text-align: center;
}
.side_action_btn .icon{
color: #c3c3c3;
font-size: 16px;
margin-left: 14px;
width: 35px;
height: 35px;
border-radius: 50%;
display: inline-block;
border: 1px solid #ddd;
text-align: center;
line-height: 39px;
vertical-align: middle;
}
.inside_listing_tab ul li {
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
}
.listing_filter {
    background: #eff2f3;
    padding: 20px 30px 9px;
    margin: 0 -30px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    position: relative;
}
.inside_listing_tab .nav-tabs{border:none;}
.inside_listing_tab .nav-tabs li {
    margin: 0;
    padding: 0;
    border: none;
}
.people_short_info {
    margin: 0 0 10px;
}
.people_short_info span svg {
    vertical-align: baseline;
    padding: 5px 0 0;
    font-size: 15px;
    margin: 0 5px 0 20px;
    color: #5d6164;
}
.people_short_info span:first-child svg {
    margin-left: 0;
}
.inside_listing_tab .tab-pane .contract ul li {
    display: inline-block;
    margin-right: 54px;
    border: none;
    padding: 0;
}
.inside_listing_tab .tab-pane .contract h6 {
    font-size: 16px;
    font-weight: 600;
}
.inside_listing_tab .tab-pane .contract ul li span{font-weight:600;}
.side_action_btn {
    width: 53%;
    text-align: right;
}
.people_list{display: inline-flex;
    justify-content: space-between;
    align-items: top;
}

.side_action_btn {
    width: 35%;
}

.circle_icon{width: 10px; height: 10px; border-radius: 50%; }
.online .circle_icon {
    background: #18b818;
    display: inline-block;
}
.away .circle_icon {
    background:orange;
    display: inline-block;
}
.offline .circle_icon {
    background:#ccc;
    display: inline-block;
}

.circle_icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    bottom: 9px;
    margin-left: -24px;
    border: 3px solid #fff;
}
.people_detail_banner .circle_icon {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    position: absolute;
    bottom: -9px;
    margin-left: -32px;
    border: 4px solid #052c5a;
}

.dropdown.invite_filter.open {
    position: relative;
    z-index: 9999;
}
.dropdown.invite_filter.open .dropdown-menu {
    width: 100%;
    position: relative;
}
.dropdown.invite_filter .dropdown-toggle {
    position: absolute;
    top: -49px;
    right: 7px;
}


/** user side **/

.about_client h6, .job_link h6 {
    padding: 18px 20px;
    border-top:1px solid rgba(255,255,255,0.15);
    margin: 20px -20px 0;
    font-weight: 600;
    color: #2cb1ff;
}
.rating .star svg {
    color: #ebbd15;
    margin-right: 3px;
    font-size: 14px;
}
.rating {
    align-items: center;
    display: inline-flex;
    margin: 2px 0;
    font-size: 12px;
}
.about_client ul li {
    margin: 10px 0;
}
.about_client ul li p, .detail_sidebar p{color:rgb(41, 40, 40);}
#postjob_detail .client_his, #postjob_detail .other_job, #postjob_detail .message_part, #postjob_detail .chat_bx, #postjob_detail .info_bx  {
    margin-top: 34px;
}
.project_shotinfo p {
    margin-right: 40px;
}
.project_shotinfo {
    display: inline-flex;
    width: 100%;
}
.apply_job .blue_btn, .apply_job .stroke_btn {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    padding: 11px 10px;
}
.job_list ul li span {
    margin-right: 7px;
}

.applicant_rating .rating .star svg {
    font-size: 14px;
}
.applicant_rating a {
    font-size: 13px;
    font-weight: 500;
    margin: 0 5px;
}
.client_his h6 {
    font-size: 16px;
    font-weight: 600;
}
.other_job ul li a {
    font-weight: 500;
}
.other_job ul li {
    margin: 7px 0;
}
.terms_part ul li {
    float: left;
    width: 33%;
    background: #eff2f3bf;
    padding: 20px;
    border-radius: 6px;
    margin-right: 2%;
    border: 1px solid #eee;
}
.terms_part ul li:last-child {
    width: 100%;
    background: no-repeat;
    padding: 0;
    border: none;
}

/* chat box */
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}

.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}


.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}



.incoming_msg_img{
  display: inline-block;
  width: 6%;
  margin-right: 10px;
}
.outgoing_msg_img {
    float: right;
    margin-left: 20px;
    width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg  {
  background: #f2f4f8;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}

 .sent_msg {
  background: #1f2e3f;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.sent_msg .time_date{color:#eee;}
.outgoing_msg h6 {
    text-align: right;
}
.sent_msg p {
    color: #fff;
}
.messaging .outline_bx p {
    font-size: 14px;
    line-height: 22px;
}
.received_msg h6, .outgoing_msg h6 {
    font-weight: 700;
    font-size: 14px;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
    width: 97%;
    padding-right: 100px;
}
.type_msg {
    position: relative;
    background: #eee;
    padding: 15px;
    margin: 0 -20px -20px;
}
.msg_send_btn {
    border: none;
    background: transparent;
    color: #1683f3;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 12px;
    top: 25px;
}
.outline_bx {
    border: 1px solid #e7e7e7;
    margin: 20px 0 0;
    padding: 20px 20px 15px;
    min-height: 300px;
}
.joboutline_bx {
    border: 1.5px solid #e7e7e7;
    margin: 20px 0 0;
    padding: 0px 20px 0px;
    min-height: 270px;
}
.joboutline_bx:hover{
    border-color: #f08787;
}

.msg_topic h6 {
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 6px;
}
.input_msg_write label{
    position: absolute;
    top: 28px;
    right: 65px;
    font-size: 18px;
    color: #a1a6a9;
}

.input_msg_write label input {
    display: none;
}
.input_msg_write input[type="text"] {
    width: 95%;
}
.end_info.double_action {
    margin: 20px 0 0;
}
.skills_part span, .job_list_info small {
    background: #eee;
    padding: 2px 15px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    display: inline-block;
    font-size: 13px;
}
.job_list_info small{display: inline-block !important; margin: 0 2px;}
.job_list_info ul li span {
    font-size: 14px;
    font-weight: 600;
    color: #3a3a3a;
}

/* end chat part */

//.detail_list{margin: 30px 0;}
.detail_list li {
    position: relative;
    //padding-left: 28px;
    margin: 2px 0;
}
//.detail_list li:before{font-family: 'FontAwesome'; content: "\f0a4"; position: absolute; left:0; top:0; font-size: 15px;}
.work_link a svg {
    vertical-align: middle;
    font-size: 13px;
}

.work_link a {
    display: block;
}

/* progress bar */
.progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background: linear-gradient(
45deg
, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width .6s ease;
}
.progress.blue {
    width: 130px;
    height: 130px !important;
    float: left;
    line-height: 150px;
    background: none;
    margin: 20px;
    box-shadow: none;
    position: relative;
    border-radius: 50% !important;
    background-color: transparent !important;
}

.progress.blue:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid #fff;
    position: absolute;
    top: 0;
    left: 0
}

.progress.blue>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1
}

.progress.blue .progress-left {
    left: 0
}

.progress.blue .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0
}

.progress.blue .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left
}

.progress.blue .progress-right {
    right: 0
}

.progress.blue .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards
}

.progress.blue .progress-value {
    width: 85%;
    height:85%;
    border-radius: 50%;
    background: transparent;
    font-size: 18px;
    color: #fff;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
    padding: 32px 0 0;
}

.progress.blue .progress-bar {
    border-color: #049dff
}

.progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s
}


@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg)
    }
}


.applicant_img img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
}

.search_btm_info {
    display: inline-flex;
    align-items: center;
}
.search_btm_info p, .search_btm_info .rating{margin-right: 15px;}
.rating .star .unfill {
    color: #dbdbdd;
}
.search_btm_info .rating .star svg {
    font-size: 13px;
}
.job_des {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 85%;
    margin-bottom: 10px;
}
#breakdown_list .inside_listing_tab {
    margin: 0;
}
.search_job_list .inside_listing_tab .nav-tabs {
    border-bottom: 1px solid #ddd;
    margin:0 -5px;
    padding: 0;
}
/*.post_result .form-group.search_field {
background: rgba(7, 22, 38, 0.6);
padding: 30px;
margin: 0; 
border: 1px solid #274076;
border-bottom: none;}
*/

.form-group.search_field {
    border-radius: 6px 6px 0 0;
    border: none;
}
.job_list .form-group.search_field{display: inline-flex;}
.side_action_btn .icon.saved_job {
    color: #ff3838;
    border: 1px solid #ff3838;
}
.searchjob_head h3 {
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: 600;
}
.searchjob_head {
    display: inline-block;
    margin: 0 0 5px;
}
#breakdown_list .searchjob_bx ul li {
    border-bottom: 1px solid #ddd;
    padding: 25px 0 15px;
}
#breakdown_list .searchjob_bx ul li:first-child{padding-top: 0;}
.filter_points h6 svg {
    float: right;
    color: #afafaf;
}

.terms_bx ul li label {
    width: 60%;
    font-weight: 400;
}
.terms_bx ul li:nth-child(2) input, .terms_bx ul li:first-child p {
    margin-right: 29px;
}
.application_from label p{    font-weight: 400;}
.application_from label{margin-bottom: 10px;}
.terms_bx ul li {
    display: inline-flex;
    width: 60%;
    justify-content: space-between;
    padding: 12px 0;
}
.input_part input[type="text"] {
    display: inline-block;
    width: auto;
    margin-right: 5px;
    font-weight: 500;
}
.file_input input[type=file]{display: none;}
.file_input label {
    border: 2px dashed rgb(243, 129, 129);
    border-radius: 4px;
    display: block;
    text-align: center;
    padding: 15px 10px;
    margin: 15px 0 15px;
    color: #6e6e6e;
    background: #f9f9fa;
    cursor: pointer;
}

.radio_btn .check_bx .checkmark:after, .radio_btn .checkmark{
    border-radius: 50%;
}

.radio_btn .check_bx:hover input ~ .checkmark {
    outline:none;
}
.radio_btn label {
    margin: 10px 0;
}

#change_term .modal-dialog {
    width: 700px;
}
#change_term .terms_bx ul li {
    width: 100%;
    justify-content: flex-start;
}
#change_term .terms_bx ul li:nth-child(2) input, #change_term .terms_bx ul li:first-child p {
    margin-right: 10px;
}
.search_job .filter_point label {
    font-weight: 400;
}
.search_job .filter_point h6 {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 15px;
}
.search_job .filter_point {
    min-height: 210px;
}
.search_job .stroke_btn {
    position: absolute;
    right: 24px;
    top: 24px;
    font-size: 14px;
    padding: 6px 25px;
}
.default_progress span {
    position: absolute;
    bottom: 8px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
}
.progress.default_progress {
    height: 8px;
    border-radius: 20px;
    max-width: 280px;
}
.about_profile ul {
    display: inline-flex;
    width: 100%;
}

.about_profile {
    padding: 20px 0;
}
.about_profile p {
    color: #bcbcbc;
    font-size: 14px;
}
.about_profile ul li:last-child{padding:0;}
.profile_info_bx{margin: 0 0 20px;}
.profile_info_bx h5 {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
}
.tab_info .rating{margin:0;}
.tab_info .project_shotinfo{margin:15px 0 0; font-size: 13px;}
hr {
    border-top: 1px solid #e3e3e3 !important;
}
.tab_info.client_his h6 {
    color: #003e7e;
}
.tab_info.skills_part h6 {
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0 12px;
}
.tab_info.client_his ul li {
    margin: 25px 0;
}

.emp_his ul li span, .certificate_info ul li span{
    color: #6e6e6e;
}
.emp_his ul li h6, .certificate_info ul li h6 {
    font-weight: 700;
    font-size: 16px;
}
.emp_his ul li:first-child, .certificate_info ul li:first-child {
    padding-top: 0;
    border:none;
}
.emp_his ul li {
    border-top: 1px solid #ddd;
    padding: 18px 0;
}
.portfolio_list .img_bx img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.portfolio_list .img_bx {
    height: 200px;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 0 14px;
}
.portfolio_list .img_bx img{transition: all ease-in-out 0.4s; -webkit-transition: all ease-in-out 0.4s;}
.portfolio_list a:hover .img_bx img{
    transform: scale(1.1);
}
.portfolio_list h6 {
    font-size: 15px;
    font-weight: 700;
}

.portfolio_list {
    margin: 0 0 25px;
    height: 250px;
}
.tab_links .circle_btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 43px;
    text-align: center;
    border: 1px solid #ccc;
    display: inline-block;
}
.certificate_info ul li{border-top: 1px solid #dfdfdf; padding: 18px 0; display:inline-flex; width: 100%; align-items: center;}
.certificate_info ul li img {
    width: 90px;
    margin-right: 20px;
}
#people_page .circle_icon {
    border-color:#0a0e1e;
    margin-left: -35px;
}
.inline_feild label {
    display: inline-block;
    margin-right: 30px;
}

#view_more_list .user_cat_info {
    width: 260px;
}
#view_more_list .people_table tr td h5 {
    margin: 4px 0 0;
    font-size: 15px;
}
#view_more_list .people_table tr td {
    padding: 10px 0 !important;
}
#view_more_list .people_table .user_cat_info img {
    width: 40px;
    height: 40px;
}
.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    background: #024189;
    color: #fff;
    border-radius: 4px 4px 0 0;
}
.modal-header h4 {
    color: #fff;
}
.casting_list_head strong {
    font-weight: 600;
    color: #3a3a3a;
}
.casting_list_btn .action_btn ul li {
    border: none;
    color: #8b8b8d;
    display: unset !important;
}
#postjob_detail .jobdetail_tab .shadow_bg {
    padding-top: 0;
}
#postjob_detail .jobdetail_tab #tab_default_1 .shadow_bg {
    padding-top: 30px;
}
.people_short_info span {
    font-size: 13px;
    font-weight: 500;
    color: #949497;
}
.search_job_list .form-group.search_field {
    border-radius: 6px 6px 0 0;
    border: none;
    background: #eff2f3;
    margin: 0 -30px;
    padding: 22px 30px;
    border-bottom: 1px solid #ddd;
}
.search_job_list .search_field input {
    width: 75%;
}
.searchjob_bx .searchjob_point  {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    align-items: center;
    color: #8b8b8d;
}
.searchjob_bx {margin: 25px 0 0;}
.searchjob_bx .application_info p {
    display: inline-block;
    margin-right: 0;
}
.searchjob_bx .application_info a {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}
.searchjob_bx .application_info{
    display: flex;
    justify-content: space-between;
}
.searchjob_head h3 {
    margin: 0 0 7px;
}
.searchjob_head p {
    font-size: 12px;
    color: #7f7f7f;
}
.search_job_list .inside_listing_tab.shadow_bg {
    padding-top: 0;
}
#postjob_detail .jobdetail_tab .detail_sidebar{
    background: #f4f5f6;
    position: absolute;
    height: 100%;
    border-radius: 0;
    border:none;
    border-left: 1px solid #e5e7eb;
    color: #828282;
}
#postjob_detail.submit_application  .detail_sidebar {
    background: #f4f5f6;
    border-radius: 6px;
    height: 100%;
    border:none;
    border: 1px solid #e5e7eb;
    color: #828282;
}
#postjob_detail.submit_application .application_btn {
    margin:20px 0;
    position: relative;
}
#postjob_detail.submit_application .about_client ul li strong {
    color: #3a3a3a;
}
.default_bg .jobdetail_tab .detail_sidebar  .stroke_btn{
border:1px solid #1683f3;
color:#1683f3;
margin: 8px 0;
}
.people_header .rating {
    color: #fff;
    margin-left: 0;
    display: block;
}
.profile_right a {
    width: 200px;
    text-align: center;
    margin: 5px 0;
}

.profile_tab .postjob_tab .nav-tabs li {
    width: 50%;
    text-align: center;
    margin: 0 0 -3px;
}
.bid_info strong {
    font-size: 18px;
    color: #3bd2ff;
}
.bid_info {
    color: #fff;
    padding: 12px 10px;
    text-transform: uppercase;
}
.bid_info p {
    display: inline-block;
    margin-right: 8px;
}
.people_detail_banner {
    margin: 0 0 30px;
}
.submit_application h5 {
    background: #2e59a7;
    color: #fff;
    padding: 16px 30px;
    margin: -30px -30px 25px;
    border-radius: 5px 5px 0 0;
}
.profile_area{position: relative;}


#track_page_tab .tab-content .mid_head h6 {
    font-size: 18px;
    margin: 20px 0 0;
    color: #235993 !important;
    font-weight: 700;
}

#track_page_tab .upload_resume_field  .stroke_btn {
    font-size: 14px;
}
.filter_points {
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 3px 0 11px;
}
.filter_points:last-child {border:none; padding-bottom: 0;}

.job_des_info ul {
    margin: 0 0 15px;
}
.job_des_info ul li span {
    font-weight: 600;
}
.job_des_info ul li {
    display: inline-block;
    margin: 0;
    padding-right: 15px;
    font-size: 13px;
}

.jobdetail_tab .about_client  strong, .jobdetail_tab  .client h6 {
    color: #454545;
}
.jobdetail_tab  .about_client h6 {
border-top:1px solid #e1e1e1;
}
.terms_part .default_btn {
    margin-right: 20px;
}
.searchjob_head span {
    margin-right: 13px;
}

.searchjob_bx .side_action_btn {
    width: 16%;
}
.post_result .casting_list_head span {
    margin-right: 16px;
}
#make_film .form_light_bg .form-group:nth-child(2) {
    border-right: 1px solid rgba(255,255,255,0.15);
}
.message{
   position: relative;
};

.message .editmessage{
    position: absolute;
    visibility: hidden;
    transform: translateY(-10px);
    
}


.message:hover .editmessage{
    visibility: visible;
    text-transform: capitalize;
}
.merit-score-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background: green;//linear-gradient(273deg, #1af604 0%, #1ae601 25%, #52f885 51%, #d5ff35 100%);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width .6s ease;
}
.progress.merit-score {
    height: 24px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 13px;
}
.score-container{
    margin: 8px 0 0;
    font-weight: 400;
    color: #444;
    position: relative;
}
.progress{
    background-color: #f706063b !important;
}

.terms_part a.default_btn:hover, .terms_part a.default_btn:active, .terms_part a.default_btn:focus {
    color: #fff !important;
}
.audition_list .custom_space{
  padding: 0 10px;
}

.title-ellipsis {
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden;    /* Hides the overflow content */
    text-overflow: ellipsis; /* Adds ellipsis (...) */
  }
  .film-type-ellipsis {
    white-space: nowrap;       /* Prevents text from wrapping to the next line */
    overflow: hidden;          /* Hides any text that overflows the container */
    text-overflow: ellipsis;   /* Adds '...' when text is too long */
    max-width: 100%;           /* Ensures it takes the full width of its container */
    display: inline-block;     /* Ensures the span behaves correctly for truncation */
    vertical-align: middle;    /* Aligns the icon and text vertically */
  }
  .postjop_casting_btn {
    display: flex;
    justify-content: space-between; /* Optional: Adjust spacing between buttons */
    align-items: center;
    gap: 2px; /* Optional: Set space between buttons */
    margin: 5px 0px;
  }
  
  .postjop_casting_btn a {
    text-decoration: none;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 11px;
    color: #333;
    background-color: #f9f9f9;
  }
  
  .postjop_casting_btn a:hover {
    background-color: #c62537;
    color: white;
  }
  
  .sidebar_part_bg{
    background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
  }
  .active1{
    background-color: white;
    color: black;
  }
  .active1 a{
    color: black;
  }
  .navtext{
    color: black;
  }



  .postjob_tab1 .nav-tabs {
    border: none;
}
.postjob_tab1, .bid_info {
    margin: 0 0 30px;
    background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    border: none;
    border-radius: 50px;
}
.postjob_tab1 .nav-tabs li {
    width: 25%;
    text-align: center;
    margin: -1px 0 -3px;
}
.postjob_tab1 .nav-tabs li a {
    padding: 14px 6px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin: 0;
    color: #fff;
    font-size: 13px;
}
.casting_list_btn {
    margin: 14px 0 0;
}
.postjob_tab1 .nav-tabs > li.active > a, .postjob_tab1 .nav-tabs > li.active > a:focus, .postjob_tab1 .nav-tabs > li.active > a:hover {
    color: #fff !important;
    cursor: default;
    background-color: rgba(201, 198, 198, 0.3);
    border: transparent;
    border-radius: 50px;
    font-weight: 600;
  }
.postjob_tab1 .nav>li>a:focus, .postjob_tab1 .nav>li>a:hover{
    background-color: transparent;
    color: #fff !important;
    font-weight: 500;
}