@import './jobpost';
@import './userProfile';
@import './notification';
@import './audition';
@import 'homepage';
@import 'buttons.scss';
@charset "utf-8";

/* CSS Document */
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 400;
  line-height: 24px;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}
a:hover {
  color: #2769a1;
}
img {
  max-width: 100%;
}
a {
  color: #025dc3;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: #3a3a3a;
  font-weight: 500;
  font-family: "Montserrat", "Open Sans", sans-serif;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 34px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  margin: 0;
}

button:focus,
input:focus {
  box-shadow: none;
  outline: none !important;
}
/* button */
.blue_btn,
.default_btn {
  background: linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  border: none;
  border-radius: 3px;
  color: #fff !important;
  display: inline-block;
  font-size: 15px;
  padding: 7px 25px;
}
.default_btn {
  background: #1683f3;
}
.blue_btn:hover,
.blue_btn:focus {
  transition: all ease-in-out 0.6s;
  color: #fff;
  background: #003e7e;
}
.pink_btn:hover,
.pink_btn:focus {
  transition: all ease-in-out 0.6s;
  color: #db1a47;
  background: #ffa22b;
}
.stroke_btn {
  border: 1px solid #1683f3;
  display: inline-block;
  background: transparent;
  color: #1683f3;
  padding: 7px 30px;
  border-radius: 3px;
  font-size: 15px;
}

/* input */
input[type="text"],
select,
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 8px 15px;
  font-size: 13px;
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.form-control {
  height: 45px;
  box-shadow: none;
}
label {
  font-weight: 600;
  font-size: 13px;
  margin:0;
}
textarea {
  height: 80px !important;
}
::-webkit-input-placeholder {
  color: #9a9a9a;
  opacity: 1;
}
::-moz-placeholder {
  opacity: 1;
  color: #9a9a9a;
}
:-ms-input-placeholder {
  color: #9a9a9a;
  opacity: 1;
}

input[type="radio"] {
  margin: 10px 0 0;
}

/* new css */
.swal2-container {
  zoom: 1.5;
}

.search-wrapper {
  background: #fff;
  margin: 0 0 15px;
  padding:0 !important;
}
.search-wrapper input[type="text"] {
  width: auto;
  padding: 3px ​10p;
  vertical-align: middle;
}
.search-wrapper span {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 5px;
  margin: 0 8px;
  vertical-align: middle;
}
.multiselect-container ul li input[type="checkbox"] {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  vertical-align: text-top;
}
.multiselect-container ul li {
  padding: 3px 10px;
}
.postjob_tab {
  background: transparent;
  margin-top: -60px;
}
.profile_area {
  margin-top: 15px;
}

/* post job css */
.tab_head{margin: 0 0 30px;}
.tab_head h4{margin: 0 0 30px; font-weight: 700;}

/************ main css ************/

/* nav bar */
.top_head {
  position: relative;
}
.search input {
  border: 1px solid #ddd;
  border-radius: 30px;
  width: auto;
  color: #333;
  padding: 7px 35px 7px 16px;
  min-width: 600px;
}
.logo {
  font-size: 30px;
  color: #fff;
  display: block;
  font-family: "Montserrat", "Open Sans", sans-serif;
  height: auto;
  padding: 0 15px;
}
.logo img {
  width: 122px;
}
.logo:hover {
  color: #fff;
}
.logo span {
  font-weight: 600;
  color: #1683f3;
}
.top_nav {
  position: relative;
  margin: 6px 0;
}
.navigation_part {
  background: #111111;
}
.navbar {
  border: none;
  margin: 0;
}
.navbar-nav {
  display: inline-block;
  float: none;
  margin: 0 auto -7px;
}
.navbar-nav > li > a {
  padding: 27px 14px;
}
.dropdown-menu {
  left: auto;
  right: 0;
}
.menu_drop > li > a {
  padding: 12px 12px;
}
.navigation_part .nav > li > a:focus,
.navigation_part .nav > li > a:hover,
.navigation_part .nav .open > a,
.navigation_part .nav .open > a:focus,
.navigation_part .nav .open > a:hover {
  background: transparent;
  color: #59abff;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  border-bottom: 3px solid #59abff;
}
.dropdown-menu > li > a {
  white-space: normal;
  padding: 4px 12px 4px 0;
}
.top_nav ul li {
  display: inline-block;
  padding: 0;
  float: none;
  vertical-align: middle;
}
.top_nav ul li a {
  color: #fff;
  font-family: "Montserrat", "Open Sans", sans-serif;
}
.top_nav ul li .dropdown-menu > li > a {
  color: #333;
  padding: 3px 10px;
}
.top_nav .search,
.top_nav .top_side_link {
  display: inline-block;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #337ab7;
  color: #66c0ff;
}
.top_nav ul li a:hover,
.top_nav ul li a:focus {
  color: #24baef;
  background: transparent;
}
.search .btn {
  position: absolute;
  right: 0;
  top: -2px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  background: transparent;
  color: #333;
  font-size: 17px;
}
.top_nav ul li a.dropdown-toggle img {
  width: 23px;
}
.top_nav .search {
  position: relative;
  margin: 12px 0 0 0;
}
.navigation_part .dropdown-menu {
  min-width: 200px;
}
header .dropdown-menu > li > a:focus,
header .dropdown-menu > li > a:hover {
  color: #59abff !important;
  text-decoration: none;
  background-color: transparent;
}
.mega_links {
  width: 20%;
  float: left;
}
.menu_drop {
  float: left;
  margin: 20px 0 0;
}
.mega_links h5 a {
  color: #222 !important;
}
.mega_links h5 a:hover {
  color: #24baef !important;
}

.mega-dropdown {
  position: static !important;
}
.mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin: 12px 0 0 !important;
  box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .mega-dropdown-menu {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.9);
  }

  .warning {
    display: none;
  }
}

.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}
.mega-dropdown-menu > li > ul > li {
  list-style: none;
  display: block;
}
.mega_links:last-child {
  border: none;
}
.mega_links {
  width: 20%;
  float: left !important;
  padding: 10px 20px !important;
  border-right: 1px solid #ddd;
  min-height: 180px;
}
.mega_links h5 {
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 15px 5px;
}
.mega_links:last-child {
  border: none;
}
.mega-dropdown-menu > li > ul > li > a {
  display: block;
  color: #222;
  padding: 3px 5px;
}
.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
}
.mega-dropdown-menu .dropdown-header {
  font-size: 18px;
  color: #ff3546;
  padding: 5px 60px 5px 5px;
  line-height: 30px;
}
.top_nav ul li a img {
  display: inline-block;
  width: 20px;
  margin-right: 4px;
  vertical-align: sub;
}
.menu_drop svg {
  fill: #ff3838;
  margin: 0 5px;
}

.tooltip > .tooltip-inner {
  margin-top: -15px;
}
.tooltip.bottom .tooltip-arrow {
  top: -15px;
}
.mobile_view {
  display: none !important;
}

/* flash news */
#flash_news {
  background: #000;
  overflow: hidden;
}
.wrap {
  width: 87%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
  height: 25px;
  color: #fff;
  font-family: "Montserrat", "Open Sans", sans-serif;
  float: left;
}
.jctkr-label {
  height: 25px;
  padding: 7px 17px 10px 0;
  line-height: 11px;
  font-size: 14px;
  color: #000;
  cursor: default;
  background: #eae100;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  display: inline-block;
  width: 12%;
  float: left;
  margin-right: 1%;
}
.jctkr-label:before {
  content: "";
  position: absolute;
  left: -99%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #eae100;
  z-index: 0;
}
.jctkr-label:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 7px solid #eae100;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  right: -7px;
  top: 8px;
}
[class*="js-conveyor-"] ul {
  display: inline-block;
  opacity: 0.5;
}
[class*="js-conveyor-"] ul li {
  padding: 5px 15px;
  line-height: 16px;
}
.jctkr-wrapper,
.jctkr-wrapper * {
  box-sizing: border-box;
}
.jctkr-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 25px;
  vertical-align: top;
  overflow: hidden;
}
.jctkr-wrapper ul {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.jctkr-wrapper.jctkr-initialized ul {
  opacity: 1;
}
.jctkr-wrapper ul li {
  display: inline-block;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 12px;
}

/* slider section */
#slider_part .banner_text {
  position: absolute;
  left: 4rem;
  top: 16rem;
  z-index: 9;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}
#slider_part .banner_text h1 {
  color: #fff;
  font-weight: 600;
  line-height: 52px;
  text-shadow: none;
  margin: 0 0 20px;
}
#slider_part {
  position: relative;
}
#slider_part .carousel-fade .carousel-inner .item {
  transition-property: opacity;
}
#slider_part .carousel-fade .carousel-inner .item,
#slider_part .carousel-fade .carousel-inner .active.left,
#slider_part .carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
#slider_part .carousel-fade .carousel-inner .active,
#slider_part .carousel-fade .carousel-inner .next.left,
#slider_part .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
#slider_part .carousel-fade .carousel-inner .next,
#slider_part .carousel-fade .carousel-inner .prev,
#slider_part .carousel-fade .carousel-inner .active.left,
#slider_part .carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
#slider_part .carousel-fade .carousel-control {
  z-index: 99;
  width: 35px;
  height: 35px;
  background: #000;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  top: auto;
}
#slider_part .carousel-fade .carousel-control.left {
  left: -45px;
}
#slider_part .carousel-fade .carousel-control.right {
  right: -45px;
}
#slider_part .carousel,
#slider_part .carousel-inner,
#slider_part .carousel-inner .item {
  height: 520px;
}
#slider_part .carousel-inner .item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
#slider_part .shadow {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  top: 0;
  z-index: 9;
}

/* top banner section */
#top_banner {
  padding: 50px 0;
  background: #131722;
}
#top_banner h2 {
  color: #fff;
}
.gallery_info {
  margin: 0 13px;
}
.gallery_info img {
  height: 240px;
  object-fit: cover;
  width: 100%;
}
.gallery_info h5 {
  color: #ffffff;
  margin: 2px 0;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  padding: 0 0 5px;
}
.gallery_info p span {
  float: right;
  font-weight: 600;
}
.user_info p {
  font-style: italic;
}
.user_info h5 {
  font-weight: 600;
  color: #f7bb0c;
}
#top_banner .owl-buttons {
  position: absolute;
  top: 85px;
  width: 100%;
}
#top_banner .owl-next {
  right: 0;
  position: absolute;
}
#top_banner .owl-prev {
  left: -0;
  position: absolute;
}
#top_banner .owl-controls .owl-buttons div {
  background: rgba(0, 0, 0, 0.5);
  color: #d7d7d7;
  display: inline-block;
  margin: 29px 5px;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
}
#top_banner .owl-carousel .owl-wrapper-outer {
  padding-bottom: 10px;
}
.gallery_info small {
  color: #ef2673;
  margin-top: 8px;
  display: block;
}
.inside_info {
  padding: 10px 0 0;
  font-size: 13px;
  color: #949cb0;
}
.movie_bg {
  position: absolute;
  opacity: 0.1;
  object-fit: cover;
  height: 440px;
}
#top_banner .nav-tabs > li > a {
  margin-right: 0;
  line-height: 1.42857143;
  border-bottom: none;
  font-size: 14px;
  color: #949cb0;
  font-weight: 600;
}
#top_banner .nav-pills > li.active > a,
#top_banner .nav-pills > li.active > a:focus,
#top_banner .nav-pills > li.active > a:hover {
  color: #24baef;
  background-color: transparent;
  border: none;
  margin: 0;
  border-radius: 0;
}
#top_banner .nav-tabs > li > a:hover {
  border-color: transparent;
  background: transparent;
  border-bottom: none;
  color: #24baef;
}
#top_banner .nav-pills > li {
  float: none;
  display: inline-block;
}
#top_banner .nav-tabs {
  border: none;
}
.gallery_info p,
.gallery_info h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* news gallery section */
#news_gallery {
  padding: 50px 0 40px;
}
.big_img img {
  height: 400px;
  object-fit: cover;
}
.small_img img {
  height: 160px;
  width: 100%;
  object-fit: cover;
}
#news_gallery .small_img {
  margin-bottom: 20px;
}
#news_gallery .nav-tabs li,
#movie_status .nav-tabs li {
  display: inline-block;
}
#news_gallery .nav-tabs li a,
#movie_status .nav-tabs li a {
  background: transparent;
  color: #9b9b9b;
  border: none;
  font-weight: 600;
  font-size: 14px;
}
#news_gallery .nav-tabs li a:hover,
#news_gallery .nav-tabs li.active a,
#news_gallery .nav-tabs li a:focus,
#news_gallery a h5:hover,
.gallery_info h5:hover {
  color: #24baef;
}
#news_gallery h2,
#top_banner h2,
#movie_status h2 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  margin: 0;
  position: relative;
  overflow: hidden;
}
#news_gallery .tab-content {
  padding: 30px 15px 0;
}
#top_banner .tab-content {
  padding: 30px 0 0;
}
#news_gallery .nav-tabs,
#movie_status .nav-tabs {
  margin: 0 0 4px;
  border: none;
  display: inline-block;
  float: none;
}
#news_gallery small {
  font-size: 12px;
  display: block;
  margin: 0 0 10px;
}
#news_gallery h5 {
  font-size: 15px;
  font-weight: 700;
  margin: 10px 0 0;
  font-family: "Open Sans";
}
#news_gallery .hover_img img,
.movie_img img {
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
#news_gallery .hover_img,
.movie_img,
#movie_status .side_movie_img {
  overflow: hidden;
  position: relative;
}
#news_gallery .hover_img:hover img,
.movie_img:hover img,
#movie_status .side_movie_img:hover img {
  transform: scale(1.1);
}
#news_gallery .hover_img:hover:after,
.movie_img:hover:after,
#movie_status .side_movie_img:hover:after {
  content: "";
  background: rgba(78, 154, 248, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
#news_gallery .blue_btn {
  background: #fff;
  border: 1px solid #1683f3;
  color: #1683f3 !important;
  margin: 25px 0 10px;
}
#news_gallery .blue_btn:hover,
#news_gallery .blue_btn:focus {
  background: #1683f3;
  color: #fff !important;
}
#news_gallery h2:after,
#movie_status h2:after,
#top_banner h2:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #e8e8e8;
  width: 100%;
  top: 50%;
  margin-left: 15px;
}
#news_gallery .bottom_btn {
  position: relative;
}
#news_gallery .bottom_btn:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  top: 44px;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #e8e8e8;
}

/* news part */
#news_part {
  background: #f3f5f8;
  padding: 30px 0 20px;
}
.news_text .blue_btn {
  margin-top: 13px;
  padding: 3px 15px;
  font-size: 12px !important;
  display: inline-block !important;
}
.news_text h4 {
  margin: 0 0 10px;
  font-size: 18px;
  text-transform: none;
  font-weight: 600;
}
.category,
.trend_post {
  background: #fff;
  border: 1px solid #ddd;
}
.category ul {
  padding: 0 15px 10px;
}
.category ul li {
  padding: 9px 0 9px 20px;
  border-bottom: 1px dashed #ddd;
  position: relative;
  font-size: 14px;
  line-height: 21px;
}
.news_img img {
  height: 214px;
  width: 100%;
  object-fit: cover;
}
.category ul li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  top: 20px;
  background: #1683f3;
  left: 0;
}
.category ul li a,
.trend_post ul li a {
  color: #337ab7;
}
.category ul li a:hover {
  text-decoration: underline;
}

/* make a film section */
#make_film_section img {
  position: absolute;
  right: -30px;
  top: -16rem;
  max-width: 80rem;
}
#make_film_section {
  height: 550px;
  background: linear-gradient(20deg, #131722 50%, #dc1838 42%);
  position: relative;
  overflow: hidden;
}
.make_film_info {
  position: absolute;
  bottom: 94px;
  color: #fff;
}
.make_film_info h2 {
  color: #fff;
  font-weight: 600;
  margin: 0 0 20px;
}
.make_film_info p {
  font-size: 20px;
}
.film_cate_part span {
  display: inline-block;
  padding: 10px 41px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  margin-right: 10px;
}
.film_cate_part {
  margin: 40px 0;
}

/* home page last section */
#movie_status {
  padding: 50px 0;
  background: #131722;
  color: #949cb0;
}

#last_section {
  padding: 30px 0 40px;
}
.trend_post ul {
  padding: 0 11px;
}
.trendy_txt {
  display: flex;
  margin: 0 0 30px;
}
#movie_status .trendy_txt > img {
  margin-right: 13px;
  height: 100px;
  width: 70px;
  object-fit: cover;
}
#movie_status h2 {
  color: #fff;
}
.mid_area {
  display: inline-flex;
  align-items: center;
  margin: 30px 0 40px;
}
#movie_status .side_movie_img img {
  width: 200px;
  height: 260px;
  object-fit: cover;
  max-width: none;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
#movie_status .full_movie_detail {
  margin-left: 30px;
}
.trendy_txt small em,
.mid_area small em {
  color: #9b9b9b;
}
.trendy_txt small span {
  font-weight: 700;
  color: #24baef;
}
.mid_area h6 {
  font-size: 22px;
  margin: 5px 0 15px;
  font-weight: 600;
  font-family: "Montserrat", "Open Sans", sans-serif;
  color: #fff;
}
.mid_area .full_movie_detail .blue_btn {
  margin: 25px 0 0;
}
.main_head {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.trend_post ul li small {
  display: block;
  line-height: 18px;
}
.trend_post ul li small span {
  color: #1683f3;
}
#movie_status .trendy_txt h6 {
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat", "Open Sans", sans-serif;
  color: #fff;
  line-height: 23px;
  margin: 0 0 15px;
}
#movie_status .trendy_txt {
  display: flex;
  margin: 0 0 15px;
  align-items: center;
  border-bottom: 1px solid #37373a;
  padding: 0 0 15px;
}

#movie_status h2:after,
#top_banner h2:after {
  background-color: #575761;
}

#movie_status .nav-tabs li a:hover,
#movie_status .nav-tabs li.active a,
#movie_status .nav-tabs li a:focus,
#movie_status a h5:hover,
.gallery_info h5:hover {
  color: #24baef;
}

.outer_bx {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 15px 0;
  background: #fff;
}
.category h5,
.trend_post h5 {
  background: #202020;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  text-align: center;
}
.category ul li {
  padding: 12px 0 12px 20px;
  border-bottom: 1px dashed #ddd;
  position: relative;
}
.trend_post ul li {
  padding: 12px 0;
  border-bottom: 1px dashed #ddd;
  position: relative;
}
.category ul li:last-child {
  border: none;
}
.trend_post ul li:last-child {
  border: none;
  text-align: center;
  background: #ffa22b;
  margin: 0 -12px;
}
.trend_post ul li:last-child a {
  color: #fff;
}

/* auditions */
#auditions {
  padding: 60px 0;
  background: #131722;
  position: relative;
}
#auditions .event_cal {
  padding: 0 20px;
}
.audition_head {
  //background: url(${process.env.PUBLIC_URL + "/audtion_head_banner.jpg"});
  padding: 70px 40px 35px;
  background-position: 0 bottom;
  margin: 0 -40px 15px;
}
.audition_head h2 {
  position: relative;
  color: #fff;
}
.head_img {
  position: absolute;
  left: 0;
  width: 130px;
  top: 0;
  z-index: 9;
}
.event_bg {
  background: #003e7e;
  padding: 0 40px;
  border-radius: 6px;
  margin: 0 0 0;
  overflow: hidden;
}
.event_cal ul li {
  display: flex;
  padding: 18px 0;
  align-items: center;
}
.event_cal ul li .date {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  width: 80px;
  background: #dd1141;
  height: 80px;
  text-align: center;
  border-radius: 6px;
}
.event_cal ul li .event_detail {
    margin-left: 20px;
}
.event_cal ul li .event_detail h4 {
  font-weight: 600;
  margin: 0 0 6px;
  font-size: 20px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;
}
.event_cal ul li .event_detail small {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #dde5ee;
}

.event_cal .btn_part {
  margin: 13px -40px 0;
  text-align: center;
  background: #1683f3;
  padding: 20px;
}
.event_cal .btn_part a {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
.event_cal .btn_part {
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.event_cal .btn_part:hover {
  background: #2691ff;
}

.need_work {
  padding: 0 3rem;
}
.work_icon {
  width: 25%;
  height: 130px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  max-width: 130px;
  min-width: 130px;
  position: relative;
  z-index: 1;
}
.work_bx h4 {
  color: #fff;
  margin: 25px 0 8px;
  font-size: 22px;
}
.work_bx {
  color: #eee;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 20px;
  display: inline-flex;
}
.work_bx:last-child {
  margin: 0;
}
.work_head h2 {
  color: #fff;
  margin: 25px 0;
}

.work_info span {
  background: #dc1838;
  display: inline-block;
  width: 88px;
  text-align: center;
  border-radius: 6px;
  padding: 1px 5px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  left: -7px;
  top: -14px;
}
.work_info {
  padding: 0 0 0 16px;
  margin: 20px 0 10px;
  position: relative;
  width: 75%;
}
.work_icon img {
  width: 70px;
  margin: 28px auto 0;
}

/** footer part **/
footer {
  background: #19224f;
  font-family: "Montserrat", "Open Sans", sans-serif;
  padding: 0 0 35px;
  color: #fff;
}
.top_links {
  border-bottom: 1px solid #3f4467;
  margin: 0 0 35px;
}
.top_links ul li {
  display: inline-block;
  padding: 30px 40px 25px;
}
.footer_links {
  margin: 25px 0 0;
}
.footer_info_part h3 {
  color: #fff;
  margin: 0 0 15px;
  font-weight: 300;
  font-size: 28px;
}
.footer_info_part {
  color: #bec0cd;
}

.footer_info_part .blue_btn {
  margin: 20px 0 0;
}
.footer_links ul li,
.copyright ul li {
  display: inline-block;
  padding-right: 26px;
}
footer h6 {
  color: #fff;
  font-weight: 700;
}
footer img {
  margin: 0 auto 10px;
  max-width: 170px;
}
.footer_links ul li a,
.top_links ul li a {
  color: #fff;
}
.social_icons ul li a {
  color: #bec0cd;
  font-size: 20px;
}
.social_icons ul li a:hover {
  color: #fff;
}
.copyright ul li a {
  color: #bec0cd;
  font-size: 12px;
}
.social_icons ul li {
  display: inline-block;
  padding: 1px 16px 0 0;
}
.footer_links ul li:hover a {
  color: #59abff;
}
.copyright {
  color: #949cb0;
  margin: 25px 15px 0;
  padding: 0;
  font-size: 13px;
}
footer .logo a {
  margin: 10px 0 20px;
  color: #fff;
}

.footer_links ul {
  display: inline-block;
  vertical-align: top;
}
.footer_links ul li:hover {
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  color: #ef2673;
}

/*************** inner pages *****************/

/* login page */

#login {
  position: absolute;
  height: 100%;
  top: auto;
  bottom: auto;
  align-items: center;
  display: flex;
  width: 100%;
}
#login .container-fluid {
  width: 100%;
}
#login .logo {
  font-size: 30px;
  color: #fff;
  display: block;
  font-family: "Montserrat", "Open Sans", sans-serif;
  height: auto;
  padding: 20px 27px 0;
}
#login .logo img {
  width: 180px;
}
#login .form-control {
  height: 44px;
  box-shadow: none;
}
.login_footer {
  padding: 20px 0 25px;
}
#login h4 {
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 10px;
}
#login .form_head_part p {
  margin: 0 0 40px;
}
.register_info h4 {
  padding: 0 0 25px;
}

.login_footer .copyright {
  margin: 12px 0 0;
}
.login_footer .social_icons ul li a {
  font-size: 14px;
  color: #72758a;
}

.login_footer .social_icons ul li {
  padding: 10px 16px 0;
}
#login .setup-content,
#login .form-space {
  padding: 30px 30px 0;
  margin: 0 auto;
}
.login_head a {
  color: #fff;
}
#login .btn {
  margin: 6px 0 10px;
}
#login .bg_box {
  background: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
  padding: 0 0 20px;
  border-radius: 6px;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  #login .bg_box {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  .warning {
    display: none;
  }
}
#login .stroke_btn {
  border: 1px solid #1683f3;
  background: transparent;
  color: #1683f3;
  padding: 7px 20px;
  margin-left: 18px !important;
}
#login .upload_resume_field .stroke_btn {
  margin: 10px 0 !important;
}
#login .inside_label {
  margin: 10px 0 0;
}
.login_footer {
  padding: 20px 0 15px;
  text-align: center;
}
.login_bg {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.register_page .register_info {
  padding: 30px !important;
}

#login .register_type {
  height: 600px;
  overflow: auto;
}

/* casting notice */
[data-toggle="collapse"] {
  cursor: pointer;
}
.inner_head {
  margin: 20px 0;
}
.inner_head h2 small {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  margin: 10px 0 0;
}
.inner_head .right_info {
  float: right;
}
.other_casting_info {
  padding: 14px 20px !important;
  background: #f1f4ff !important;
  margin: 5px 0 30px !important;
}
.other_casting_info p,
.top_left_head {
  display: inline-block;
}
.other_casting_info img {
  float: right;
  max-width: 270px;
}
#all_list,
#inner_form {
  background: #e8eeff;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
#all_list .shadow_bg {
  margin: 0;
}
.filter_points .list-group span {
  float: right;
}
.filter_points h6 {
  padding: 12px 0 5px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #36a2ff;
}
#all_list .shadow_bg a {
  color: #333;
}
#all_list .shadow_bg a:hover {
  color: #025dc3;
}
.filter_points .list-group {
  margin: 0;
  background: #fff;
  border-radius: 5px;
  padding: 5px 2px 7px;
  box-shadow: 0 0 8px -3px rgba(0, 0, 0, 0.2);
}
.filter_points .list-group .list-group-item {
  position: relative;
  display: block;
  padding: 8px 15px;
  margin-bottom: 0;
  background-color: transparent;
  border: none;
}
.list-group-item{background: transparent; border: none;}
.filter_points .check_bx {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  line-height: 20px;
  padding-left: 0;
  padding-right: 22px;
}
.side_bar_tab h5 {
  font-size: 14px;
  padding-left: 21px;
  font-weight: 700;
  text-transform: uppercase;
  color: #b4b4b4;
}
.filter_points h6[aria-expanded="true"] .fa {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.filter_points h6 .fa {
  float: right;
}
.filter_points .checkmark {
  position: absolute;
  top: 3px;
  right: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #1683f3;
  left: auto;
}
.filter_points .list-group .half_fields input[type="text"] {
  width: 34% !important;
}
.filter_points .list-group .half_fields {
  border-top: 1px solid #efefef;
  margin: 12px 14px 0;
  padding: 17px 0 9px;
}
#filter-sidebar h3 {
  padding: 0 0 15px;
  color: #222;
  border-bottom: 1px solid #d5d5d5;
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 22px;
}
.casting_bx {
  border: 1px solid #ddd;
  padding: 0 15px 0;
  margin: 10px 0 0;
}
.casting_bx_right {
  display: inline-block;
  float: right;
  text-align: right;
}
.casting_bx_left {
  display: inline-block;
}
.casting_bx_left strong,
.casting_bx_right strong,
.casting_bx_right small {
  display: block;
}
.casting_bx ul li h6 {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}
.casting_bx ul li:first-child {
  border: none;
}
.casting_bx ul li .blue_btn {
  float: right;
  padding: 3px 15px;
  font-size: 14px;
}
.casting_bx ul li {
  border-top: 1px dashed #ced5dd;
  padding: 8px 15px 5px;
  background: #f3f7fe;
  margin: 0 -15px;
}
.casting_bx_left h4 {
  color: #1683f3;
  margin: 0 0 5px;
}
.filter_fields .form-group,
.fiter_option h6 {
  display: inline-block;
}
.filter_fields {
  float: right;
}
.filter_fields select {
  width: auto;
  display: inline-block;
  margin: 0 10px;
}

.filter_points .list-group .list-group-item label {
  font-weight: 300;
}
.filter_points .list-group .list-group-item .stroke_btn {
  padding: 5px 10px;
  vertical-align: top;
  font-size: 14px;
}
.filter_points .list-group .list-group-item input[type="text"] {
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  font-size: 13px;
  border: 1px solid #ddd;
}
.filter_points .list-group .double_input input[type="text"] {
  width: 36%;
}

/* people page */
.people_table img {
  width: 94px;
  display: inline-block;
  float: left;
  height: 98px;
  object-fit: cover;
  margin-right: 20px;
}
.social_meter_info ul li {
  display: inline-block;
  margin-right: 3px;
}
.social_meter_info ul li a {
  width: 25px;
  height: 25px;
  background: #ebf1f3;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  color: #7e7e7e;
  font-size: 12px;
  display: inline-block;
}
.people_table tr td {
  vertical-align: middle !important;
  border-bottom: 1px dashed #bdd7e9;
  border-top: none !important;
  padding: 15px 0 !important;
}

.people_table tr td h5 {
  margin: 17px 0 5px;
  font-size: 18px;
}
.people_table thead tr th {
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
}
.inner_head a {
  color: #337ab7;
}
.green_clr {
  color: #12c639;
}
.red_clr {
  color: red;
}
.parent-collapsed {
  display: none;
}

/* your page */
.add_primary_img input[type="file"] {
  display: none;
}
.add_primary_img label {
  cursor: pointer;
  color: #337ab7;
}
.add_primary_img .fa {
  font-size: 56px;
  display: block;
  margin: 0 0 10px;
  color: #194d89;
}
.bottom_desc .inside_img a {
  color: #025dc3;
}
.your_page_popup ul li {
  width: 100% !important;
}
.people_center_info .blue_btn {
  text-align: center;
  margin: 10px 5px 10px 0;
  display: inline-block;
}
.your_page .add_primary_img {
  height: 340px;
  background: #cdd7e3;
  text-align: center;
  padding: 12rem 0 0;
}
.add_list .dropdown a {
  border-radius: 3px;
  width: 100%;
  border: 1px solid #fff;
  padding: 6px 15px;
  display: block;
  color: #fff;
  vertical-align: top;
}
.add_list .dropdown {
  display: inline-block;
  min-width: 200px;
}
#people_other_info .resume_data .table tr td {
  padding: 9px 10px;
  font-size: 13px;
}
.resume_data h6 strong {
  font-size: 18px;
  font-weight: 600;
}
.resume_data h6 strong .fa {
  float: right;
}
.add_list .dropdown a .caret {
  float: right;
  margin: 10px 0 0;
}
.add_list .dropdown a .fa {
  margin-right: 5px;
}
.add_list .dropdown-menu li a {
  color: #333;
  border: none;
  padding: 5px 15px;
}
.add_list .dropdown-menu {
  width: 100%;
  border-radius: 0;
  margin: 0;
}
.people_header h3 {
  display: inline-block;
  color: #fff;
  font-size: 36px;
}
.people_header {
  margin: 0 0 10px;
}
.people_header small {
  display: block;
  color: #fff;
}
.inside_img {
  height: 100px;
  width: 100px;
  display: inline-block;
  padding: 2.5rem 0 0;
  background: #eef0f3;
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
  z-index: 9;
  color: #ccc;
}
.inside_img .fa {
  display: block;
  margin: 0 0 5px;
  font-size: 20px;
  color: #aeadc4;
}
.main_text_area h6 a {
  color: #5199d7;
  font-size: 14px;
  margin-left: 10px;
}
.trending_info {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.people_contact h6 {
  background: #e8eeff;
  margin: 0 -20px 10px;
  color: #222;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
}
.company_info_page .add_primary_img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.people_contact h6 a {
  float: right;
  font-size: 15px;
  color: #76a6ff;
}
.people_contact_edit .blue_btn {
  margin: 20px 0 0;
  display: inline-block;
}
#people_other_info .nav-tabs > li.active > a,
#people_other_info .nav-tabs > li.active > a:focus,
#people_other_info .nav-tabs > li.active > a:hover {
  color: #fff;
  cursor: default;
  background-color: #1683f3;
  border: none;
  border-bottom-color: transparent;
  border-radius: 0;
}
#people_other_info .tab-content {
  min-height: 400px;
}
#people_other_info .tab_head {
  display: flex;
  justify-content: space-between;
}
#people_other_info .tab_title,
.tab_links {
  display: inline-block;
  vertical-align: top;
}
.track_point ul li a,
.people_contact_edit a,
.awards_info a,
.inside_detail_info table tr td:first-child a,
.news_text a h4,
.news_text span,
.news_detail a {
  color: #337ab7;
}
#people_other_info .tab_title h6 {
  margin: 0;
  font-weight: 600;
}
#people_other_info .tab_head {
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px;
  border-bottom: 1px solid #eee;
  margin: 0 0 15px;
}
#people_other_info .tab_head .dropdown-menu {
  padding: 10px;
  font-size: 13px;
  min-width: 210px;
}
#people_other_info .edit_tab {
  padding: 10rem 0 0;
}
#people_other_info .edit_tab .blue_btn {
  margin: 15px 0 0;
}
#people_other_info .nav.nav-tabs {
  background: transparent;
  border: none;
}
#people_other_info .nav-tabs > li > a {
  margin: 0;
  border-radius: 0;
}
#people_other_info .nav-tabs > li > a:hover,
#people_other_info .nav-tabs > li > a:focus {
  background: transparent;
  color: #fff;
}
#people_other_info .nav-tabs > li {
  float: none;
  margin-bottom: -2px;
  display: inline-block;
  width:auto;
  padding-right: 40px;
}
#star_graph {
  padding: 40px 0;
}
.side_links a {
  padding: 0 5px;
  font-size: 13px;
  text-decoration: underline;
}
.side_links {
  margin: 8px 0 0;
}

.awards_bx {
  margin: 15px 0;
  border: none;
}
.awards_info {
  padding: 10px 0 5px;
  font-size: 13px;
}
.nav-tabs > li > a:hover {
  border-color: transparent;
}

#inner_form .side_bar_bg,
#inner_form .side_bar_bg:before {
  background: #ffffff;
}

/* track page */
#track_page_header {
  background: #003169;
  padding-top: 50px;
  color: #fff;
  font-size: 20px;
  overflow: hidden;
}
#inner_banner {
  background: #003169;
  color: #fff;
  font-size: 20px;
  overflow: hidden;
  padding: 50px 0;
}
#track_page_header .top_left_info {
  padding: 40px 0 0 15rem;
}
#inner_banner .blue_btn {
  margin: 20px 0 0;
}
#inner_banner.signle_head h2 {
  margin: 0;
}
#track_page_header h2,
#inner_banner h2 {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px;
}
#track_page_header .stroke_btn {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 3px;
  display: inline-block;
  margin: 40px 0 0;
  font-size: 17px;
}
#track_page_header.casting_list_banner .top_left_info {
  padding: 10px 0 0 15rem;
}
#track_page_header .banner_img img {
  max-width: 400px;
}
#track_page_header.video_list .banner_img img {
  max-width: none;
}
#track_page_header.video_list .banner_img img {
  max-width: none;
  max-width: 700px;
}
#track_page_header.video_list .top_left_info {
  padding: 25px 0 0 15rem;
}
.side_bar_bg {
  background: #e8eeff;
  position: absolute;
  height: 100%;
  width: 25%;
  top: 0;
  left: 12px;
}
.side_bar_bg:before {
  content: "";
  left: -100%;
  width: 100%;
  background: #e8eeff;
  height: 100%;
  position: absolute;
  top: 0;
}
#track_page_tab .nav-tabs > li.active > a,
#track_page_tab .nav-tabs > li.active > a:focus,
#track_page_tab .nav-tabs > li.active > a:hover,
#create_breakdown .nav-tabs > li.active > a,
#create_breakdown .nav-tabs > li.active > a:focus,
#create_breakdown .nav-tabs > li.active > a:hover,
#inner_form .nav-tabs > li.active > a,
#inner_form .nav-tabs > li.active > a:focus,
#inner_form .nav-tabs > li.active > a:hover,
#news_page .nav-tabs > li.active > a,
#news_page .nav-tabs > li.active > a:focus,
#news_page .nav-tabs > li.active > a:hover {
  color: #025dc3;
  cursor: default;
  background-color: #fff;
  border: none;
  border-bottom-color: transparent;
  border-radius: 30px 0px 0px 30px;
  border-left: 4px solid #025dc3;
}
#inner_form .nav-tabs > li.active > a,
#inner_form .nav-tabs > li.active > a:focus,
#inner_form .nav-tabs > li.active > a:hover {
  background-color: #e8eeff;
}
#track_page_tab .nav-tabs > li > a:hover,
#track_page_tab .nav-tabs > li > a:focus,
#inner_form .nav-tabs > li > a:hover,
#news_page .nav-tabs > li > a:focus,
#news_page .nav-tabs > li > a:hover,
#news_page .nav-tabs > li > a:focus {
  background-color: transparent;
  color: #025dc3;
}
#track_page_tab .nav-tabs > li > a,
#inner_form .nav-tabs > li > a {
  margin-right: 0;
  border: 1px solid transparent;
  border-radius: 0;
  font-weight: 600;
  color: #222222;
  padding: 15px 20px;
  border-left: 4px solid transparent;
  font-size: 15px;
}
#inner_form .nav-tabs > li > a small {
  font-weight: 300;
  font-style: italic;
  color: #7f7f7f;
}
.right_space {
  padding-right: 0;
}

#track_page_tab,
#list_table {
  padding: 60px 0;
  position: relative;
}
#track_page_tab .nav-tabs > li,
#inner_form .nav-tabs > li {
  float: none;
  margin-bottom: 0;
  display: block;
}

#track_page_tab .tab-content h6,
#inner_form .nav-tabs {
  margin: 0 0 25px;
  font-size: 26px;
  font-weight: 700;
}
#inner_form .nav-tabs {
  border: none;
}
.track_point ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.list_part ul li {
  justify-content: space-between;
}
.track_point ul li .track_info {
  padding-left: 25px;
}

.track_point ul li .track_info a {
  font-size: 20px;
  color: #242424;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.track_point ul li .track_info a:hover {
  color: #025dc3;
}
.track_point ul li .track_info small,
.list_part ul li .list_info small {
  display: block;
  font-style: italic;
  color: #7f7f7f;
}
.track_point ul li img {
  width: 80px;
}

#inner_form .shadow_bg {
  margin: 0 20px;
}
#inner_form.full_form .shadow_bg {
  margin: 0 60px;
}
#inner_form .upload_video .shadow_bg {
  margin: 0 60px !important;
}
#inner_form .upload_video form small {
  color: #b0b0b0;
  font-style: italic;
}
#list_table.videos_table {
  background: #e8eeff;
}

/* list page */
.list_part ul li {
  padding: 18px 0;
  display: flex;
  align-items: center;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.list_part ul li:hover {
  padding: 20px;
  border: 1px solid #eee;
  box-shadow: 0 3px 16px -6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #f7f6ff;
}
.list_part ul li:hover .list_info a {
  color: #025dc3;
}
.list_part ul li:hover .action_btn a {
  color: #fa1d28;
}
.list_part ul li .list_info a {
  font-size: 20px;
  color: #242424;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  white-space: nowrap;
  width: 455px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding-bottom: 2px;
}
.list_part ul li .item_info span {
  background: #d4eaf6;
  padding: 9px 25px;
  font-weight: 600;
  border-radius: 50px;
}
.list_part ul li .action_btn a {
  font-size: 22px;
  color: #c3c3c3;
}
.list_sort h6,
.fiter_option h6 {
  font-size: 16px;
  display: inline-block;
  color: #757575;
  margin-top: 0;
}
.list_sort {
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
  margin: 0 0 10px;
}
.list_sort .form-group {
  display: inline-block;
  float: right;
  margin: 0;
}
.list_sort .form-group label,
.filter_fields .form-group label {
  display: inline-block;
  text-transform: uppercase;
  color: #555;
  font-weight: 600;
}
.list_sort .form-group select,
.filter_fields .form-group select {
  display: inline-block;
  width: auto;
  border: none;
  box-shadow: none;
  padding: 0 5px;
  vertical-align: top;
  height: auto;
}
.list_filter {
  padding-right: 30px;
}
.list_part,
.left_space {
  padding-left: 35px;
}
.list_filter .stroke_btn {
  border: 1px solid #0f7ad5;
  background: transparent;
  color: #0f7ad5;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 3px;
  display: inline-block;
  margin: 10px 0 0;
  font-size: 17px;
  width: 100%;
  text-align: center;
}

#track_page_header.my_list .banner_img img {
  max-width: 320px;
}
#track_page_header.box_office_banner .banner_img img {
  max-width: 590px;
}
#inner_header_part {
  border: 1px solid #ddd;
}

#track_page_header.casting_banner .banner_img img {
  max-width: 430px;
}

/* people detail */
#people_other_info .shadow_bg {
  margin: 0 auto;
}
.tab-pane .news_text {
  font-size: 14px;
}
.tab-pane .news_text span {
  font-size: 13px;
}
.tab-pane .news_text a {
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}
.tab-pane .news_img img {
  height: 165px;
  width: 100%;
  object-fit: cover;
}
.people_header h3 small {
  display: inline-block;
}
.side_details {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 9;
  width: 33%;
}
.side_details .shadow_bg {
  height: 100%;
  margin: 0 auto;
  padding: 0 20px 0;
  overflow: auto;
}
.people_full_info .add_primary_img {
  padding: 0;
  height: 450px;
  position: relative;
  z-index: 9;
}
.people_center_info {
  position: relative;
  z-index: 9;
}
.people_full_info .add_primary_img img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
.people_header .side_links {
  float: right;
}
.people_full_info .inside_img img {
  height: 65px;
  width: 68px;
  object-fit: cover;
  margin: 0 6px 0 0;
}
.people_full_info .inside_img {
  background: transparent;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
}
.inside_img hr {
  border-color: #335272;
}
.bottom_desc {
  margin: 20px 0 0;
  font-weight: 300;
  color: #e0e5e9;
}

.bottom_desc a {
  color: #fff;
  font-weight: 600;
}
.back_link {
  padding: 0 21px;
  font-size: 17px;
  margin: 0 0 10px;
  font-weight: 600;
}

.bottom_desc ul {
  margin: 20px 0 0;
  font-size: 14px;
}
.bottom_desc ul li p,
.bottom_desc ul li .right_detail {
  width: 75%;
  float: left;
  margin: 5px 0;
}
.bottom_desc ul li span {
  font-weight: 600;
  padding-right: 35px;
  width: 25%;
  float: left;
  margin: 5px 0;
}
.awards_bx h6,
.trending h6 {
  background: #e8eeff;
  padding: 10px 18px;
  margin: 0 -20px;
  font-size: 16px;
  font-weight: 600;
}
.people_contact_edit ul li {
  padding: 15px 0;
  border-bottom: 1px dashed #ccc;
  font-size: 13px;
}
.people_contact_edit ul li:first-child {
  padding-top: 0;
}
.people_contact_edit ul li:last-child {
  padding-bottom: 0;
  border: none;
}
.people_contact_edit ul li h5 {
  margin: 0 0 7px;
  font-size: 14px;
}
.people_contact_edit a,
.awards_info a {
  display: block;
}
.people_contact_edit p a {
  display: inline-block;
}
.tab_links .dropdown {
  display: inline-block;
  padding: 0 10px;
}
.tab_links .dropdown input {
  margin-left: 10px;
}
.inside_detail_info img {
  width: 50px;
  margin-right: 7px;
}
.inside_detail_info table tr td p {
  display: inline-table;
  vertical-align: middle;
}
.inside_detail_info {
  font-size: 14px;
}


.award_detail small,
.award_detail p {
  display: block;
  line-height: 22px;
}
.tab_links .form-group label,
.tab_links .form-group select,
#edit_feature_page .right_edit_option label,
#edit_feature_page .right_edit_option select {
  display: inline-block;
  width: auto;
  height: 30px;
  padding: 1px 10px;
}
.tab_links .form-group {
  display: inline-block;
  margin: 0 5px;
}
.thumbnail_image img {
  width: 25%;
  height: 200px;
  object-fit: cover;
  padding: 8px;
  margin: 0 -2px;
}
.video_img img {
  width: 100%;
  height: 143px;
  object-fit: cover;
}
.big_video .video_img img {
  height: 361px;
}
.video_img .fa {
  display: none;
}
.thumbnail_video a:hover .video_img .fa {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 25%;
  font-size: 50px;
  color: #fff;
  margin: 0 auto;
  border-radius: 50%;
  text-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
}
.thumbnail_video .big_video a:hover .video_img .fa {
  top: 37%;
}
.thumbnail_video p {
  font-weight: 600;
  line-height: 20px;
  margin: 10px 0 0;
  font-size: 13px;
  color: #333;
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.thumbnail_video a {
  margin: 10px 0;
}
.video_bx {
  margin: 10px 0;
}

.profession_head {
  position: relative;
  padding: 9rem 0 !important;
}
.pro_right_img {
  position: absolute;
  right: 10rem;
  bottom: 0;
  max-width: 250px;
}
.pro_left_img {
  position: absolute;
  left: 10rem;
  bottom: 0;
  max-width: 250px;
}

/* casting breakdown page */
.casting_edit .blue_btn {
  margin: 20px 0 0;
}
.casting_edit h5 {
  margin: 0 0 10px;
  font-size: 24px;
}
.casting_edit {
  padding: 5rem 0 7rem;
}
#create_breakdown form,
#submission .submission_bg,
.shadow_bg {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 3px 20px -7px rgba(0, 0, 0, 0.3);
}
#create_breakdown form .form-group,
#post_casting_notice .form-group,
#create_breakdown .form-group {
  margin: 0 0 25px;
}
#create_breakdown form .blue_btn {
  margin-right: 6px;
}

#create_breakdown form hr {
  border-color: #dad8d8;
  margin: 5px 0 25px;
}

.bg_part {
  background: #e8eeff !important;
  padding: 5rem 0 6rem !important;
}

#submission .table .detail_info {
  display: flex;
  align-items: start;
}
#submission .table .detail_info img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
}

#submission .table .detail_info a,
.show_list_tabe .table tr td h6 {
  display: block;
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
#submission .table tr td,
.show_list_tabe .table tr td,
.inside_detail_info .table tr td,
#box_office .table tr td,
#account_setting .table tr td,
.videos_table .table tr td {
  background: #fff;
  padding: 15px 10px;
  vertical-align: middle;
  border-top: none;
  border-bottom: 1px dashed #bdd7e9;
}
#submission .table a {
  color: #333;
}
#submission .table a:hover,
#submission .table a:hover .fa,
#account_setting .table a:hover .fa,
.videos_table .table a:hover .fa {
  color: #025dc3;
}
#submission .table a .fa,
#account_setting .table a .fa,
.videos_table .table a .fa {
  font-size: 18px;
  color: #7f7f7f;
  margin: 0 5px;
}

#submission,
#box_office {
  background: #e8eeff;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
#submission .table .detail_info small,
.show_list_tabe .table tr td small {
  color: #7f7f7f;
}

#submission .table tr th,
.show_list_tabe .table tr th,
#all_list .table tr th,
.inside_detail_info .table tr th,
#box_office .table tr th,
#account_setting .table tr th,
.videos_table .table tr th {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #7f7f7f;
  border: none;
  padding: 10px 10px;
}
.inside_detail_info .table tr th {
  font-size: 12px;
}
#submission .table tr th:last-child,
.show_list_tabe .table tr th:last-child {
  text-align: center;
}
#submission .table thead,
.show_list_tabe .table thead,
#all_list .table thead,
.inside_detail_info .table thead,
#box_office .table thead,
#account_setting .table thead,
.videos_table .table thead {
  background: #e8eeff;
}
#submission .alert-info,
#account_setting .cancel_membership .alert-info {
  color: #1683f3;
  background-color: transparent;
  border-color: #1683f3;
  padding: 8px 10px;
  font-size: 14px;
  margin: 40px 0 0;
}

/* Customize the label (the container) */
.table .check_bx {
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 18px;
}

.check_bx {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  /* color: #fff; */
  line-height: 20px;
}

/* Hide the browser's default checkbox */
.check_bx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #1683f3;
}

/* On mouse-over, add a grey background color */
.check_bx:hover input ~ .checkmark {
  outline: 1px solid #1683f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_bx input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_bx .checkmark:after {
  left: 0;
  top: 3px;
  width: 8px;
  height: 8px;
  background: #1683f3;
  right: 0;
  margin: 0 auto;
}

.top_head_info p strong {
  font-weight: 600;
}
.top_head_info p,
.casting_list_head p {
  font-size: 12px;
  color: #7f7f7f;
}
#post_casting_notice .post_tabbing h5 {
  display: inline-block;
  margin: 10px 0 0;
  font-weight: 600;
}
.post_tabbing .nav-tabs li a,
.box_office_head .nav-tabs li a {
  border: none;
  background: #d4eaf6;
  border-radius: 50px;
  padding: 7px 20px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 4px;
  color: #555;
}
.post_tabbing .nav-tabs li:hover a,
.box_office_head .nav-tabs li:hover a {
  color: #025dc3;
}
#post_casting_notice .nav-tabs {
  float: right;
  display: inline-block;
  border: none;
}
.post_tabbing {
  border-bottom: 1px solid #e9e9e9;
  padding: 0 0 20px;
  margin: 0 0 20px;
}
.post_tabbing .nav-tabs > li.active > a,
.post_tabbing .nav-tabs > li.active > a:focus,
.post_tabbing .nav-tabs > li.active > a:hover,
.box_office_head .nav-tabs > li.active > a,
.box_office_head .nav-tabs > li.active > a:focus,
.box_office_head .nav-tabs > li.active > a:hover {
  color: #fff !important;
  cursor: default;
  background-color: #025dc3 !important;
  border-color: transparent !important;
  border-radius: 50px !important;
  border: none;
}
#post_casting_notice .top_head_info,
#create_breakdown .top_head_info,
#inner_form .top_head_info {
  margin-bottom: 30px;
  background: #f4faff;
}

#create_breakdown .nav-tabs {
  border: none;
}
.right_link .stroke_btn {
  margin: 0 0 0 15px;
}

.casting_edit img {
  opacity: 0.2;
  width: 150px;
  margin: 0 0 40px;
}

#list_table .casting_edit {
  padding: 5rem 15px;
}

/* box office page */
#box_office_header img {
  position: absolute;
  height: 350px;
  width: 100%;
  top: 0;
  object-fit: cover;
  opacity: 0.1;
}
#box_office_header {
  background: #ffc543;
  padding: 9rem 0;
  overflow: hidden;
  position: relative;
}
.box_office_txt {
  font-size: 18px;
  line-height: 25px;
}
#box_office tr td h5 {
  display: table-cell;
  font-size: 15px;
}
#box_office tr td h5:hover {
  color: #025dc3;
}
#box_office tr td img {
  width: 40px;
  height: 50px;
  display: inline-block;
  margin-right: 5px;
  object-fit: cover;
  border-radius: 3px;
}
#box_office fieldset select {
  display: inline-block;
  width: auto;
  height: 32px;
  padding: 0 14px;
}
.pagination {
  margin: 10px 0 0;
}
.box_office_head .nav-tabs {
  border: none;
  display: inline-block;
  position: absolute;
  top: 10px;
  z-index: 9;
}
.box_office_head fieldset {
  float: right;
}
.box_office_head {
  margin: 0 0 15px;
  position: relative;
}

/* news page */
#news_page h5 {
  margin: 0 0 15px;
}
#news_page {
  background: #fff;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
.news_detail img {
  width: 100%;
  margin: 0 0 20px;
}
#news_page .tab-content {
  padding-left: 20px;
}
#news_page .nav-tabs > li > a {
  color: #333;
  margin: 4px 0;
  font-weight: 600;
  padding: 10px 17px;
}

#news_page .nav-tabs {
  border: none;
}
#news_page .nav-tabs > li {
  float: none;
}

/* professional steps page */
#profession_step {
  background: #e8eeff;
  padding: 6rem 0;
  position: relative;
}
#profession_step .tab_info small {
  color: #7f7f7f;
}
#profession_step .steps_part {
  position: relative;
  z-index: 9;
  width: 1000px;
  margin: 0 auto;
}
#profession_step .nav-tabs > li {
  width: 31%;
  text-align: center;
  background: #fff;
  margin: 10px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#profession_step .nav-tabs > li.active > a,
#profession_step .nav-tabs > li.active > a:focus,
#profession_step .nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: transparent;
  border: none;
  border-bottom-color: transparent;
}
#profession_step .nav-tabs > li.active > a .fa,
#profession_step .nav > li > a:hover .fa {
  color: #ffa22b;
  border-color: #ffa22b;
}
#profession_step .nav-tabs > li.active > a h5 {
  color: #ffa22b;
}
#profession_step .nav > li > a:focus,
#profession_step .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
#profession_step .nav-tabs > li > a {
  border-radius: 0;
}
#profession_step .nav-tabs > li > a .fa {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  line-height: 60px;
  color: #1683f3;
  font-size: 24px;
  margin: 0 0 20px;
  border: 1px solid #1683f3;
}
#profession_step .shadow_bg {
  margin: 22px 32px 0;
}
#profession_step .skip_btn {
  margin: 20px 32px 0;
}
#profession_step .nav-tabs {
  border: none;
}
.profession_heading {
  color: #fff;
  margin: 20px 0 30px;
}
.profession_heading h1,
.profession_heading h5 {
  color: #fff;
}
.profession_heading h5 {
  font-weight: 400;
  margin: 10px;
}
#profession_step .tab-content .form-group label {
  font-weight: 400;
  vertical-align: middle;
}
#profession_step .tab-content .form-group {
  margin: 0;
}

/* inbox page */
#track_page_header.inbox_banner img {
  max-width: 350px;
}
#inbox_detail .pink_btn {
  padding: 3px 15px;
  font-size: 14px;
}
.industry_people img {
  height: 250px;
  object-fit: cover;
  border: 1px solid #eee;
  width: 100%;
}
.inbox_info {
  padding: 40px 60px 0;
  margin-bottom: 50px;
}

.inbox_head {
  background: #fff;
  border: none;
  padding: 10px 20px;
  height: auto;
}
.inbox_head .stroke_btn {
  float: right;
  margin: 5px 0 0;
}

.industry_people .stroke_btn {
  display: none;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  width: auto;
  border-color: #fff;
  color: #fff;
  margin: 0 20px;
}
.industry_people:hover .stroke_btn {
  display: block;
}
.inbox_head h5 {
  margin: 15px 0;
  display: inline-block;
}
.industry_people h6 {
  font-size: 16px;
  color: #222;
  font-weight: 600;
}
.industry_people h6:hover {
  color: #1683f3;
}
#inbox_detail {
  padding: 40px 0 0;
  background: #e8eeff;
}
.overlay_part {
  position: relative;
}
.overlay_part:hover:after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*

.inbox_info .trendy_txt {
    display: flex;
    margin: 0 0 15px;
    align-items: center;
    border-bottom: 1px solid #cbcbd7;
    padding: 0 0 15px;
}
.inbox_info ul li:last-child .trendy_txt{border:none;}
.inbox_info .trendy_txt > img {
    margin-right: 13px;
    height: 100px;
    width: 70px;
    object-fit: cover;
}
.inbox_info .trendy_txt h6 {
    color: #222;
    font-weight: 600;
    font-size: 17px;
}
.inbox_head {
    background: #fff;
    border: none;
    padding: 10px 20px;
    height: auto;
    margin: 0;
}
.inbox_info {
    padding: 40px 0 0;
    margin-bottom: 50px;
}
.inbox_head h5 {
    margin: 15px 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.inbox_info .stroke_btn {
    width: 100%;
    display: block;
    text-align: center;
}
*/

/* page control */
.switch-field {
  display: inline-block;
  float: right;
}
.page_control .form-group p {
  display: inline-block;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

/* casting breakdown list */
#breakdown_list ul li table {
  margin: 20px 0 0;
}
#breakdown_list ul li table tr td h6 {
  margin: 0;
}
#breakdown_list .breakdown_casting_info {
  margin: 0 60px 20px;
}
#track_page_header.casting_list_banner img {
  max-width: 500px;
}
.casting_list_head {
  display: inline-block;
  margin: 0 0 10px;
}
.casting_list {
  border-bottom: 1px dashed #bdd7e9;
  padding: 0 0 20px;
  margin: 20px 0;
}
.auditions .casting_list img,
.auditions_history .casting_list img {
  width: 90px;
  vertical-align: top;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;
}

.auditions_history .casting_list_btn {
  float: right;
}
.auditions_history .casting_list_head {
  width: 80%;
}

.auditions .casting_list_btn {
  padding: 15px 0 0;
}
.auditions .casting_list_btn .side_date_bx {
  margin: 0;
}
.casting_list_btn .action_btn,
.casting_list_btn .side_date_bx {
  float: right;
  font-size: 18px;
  padding: 3px 15px 5px;
  margin: -13px 0 0;
  border-radius: 20px;
}
.casting_list_btn .side_date_bx {
  background: #e8eeff;
}
.casting_list_btn .action_btn a {
  color: #c3c3c3;
  font-size: 20px;
  margin-left: 17px;
}
.casting_list_btn a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #337ab7 !important;
}
.left_casting_btn {
  display: inline-block;
  float: right;
  vertical-align: top;
}

.left_casting_btn a {
  font-weight: 500;
  font-size: 13px;
  margin-left: 20px;
  padding: 3px 15px;
}
/* account setting pages */
#track_page_header.account_banner .banner_img img {
  max-width: 270px;
  margin: 0 0 20px;
}
.account_bg {
  padding: 60px 0;
  background: #e8eeff;
}

.account_left ul li {
  padding: 10px 15px;
  display: inline-block;
}
.account_left ul {
  padding: 12px 0;
}
.account_left ul li:last-child {
  border: none;
}
.account_left ul li a {
  color: #333;
}
.account_left ul li.active {
  background: #1683f3;
  border-radius: 50px;
}
.account_left ul li.active a,
.account_left ul li.active:hover a {
  color: #fff;
}
.account_left ul li:hover a {
  color: #337ab7;
}
.account_right .form-group label h6 {
  margin: 5px 10px;
  font-weight: 600;
}
.red_bx {
  background: #ff1c41;
}
.account_right .form-group label h6 span {
  font-size: 13px;
}
.account_right .membership_form .form-group input[type="radio"] {
  vertical-align: top;
  margin: 20px 0 0;
}
.account_right .membership_form .form-group input[type="text"] {
  display: inline-block;
  width: 65%;
}
.charges_bx ul li:last-child {
  font-weight: 600;
  border: none;
}
.charges_bx ul li span {
  float: right;
}
.charges_bx ul li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
}
.charges_bx a,
.charges_bx .blue_btn {
  display: block;
  text-align: center;
  margin: 10px 2px;
  width: 49%;
  float: left;
}
.update_detail_form .blue_btn {
  display: block;
  text-align: center;
  margin: 10px 2px;
}
.charges_bx .blue_btn {
  padding: 9px 20px;
}
.account_right .plan_info {
  padding: 10px 0 0;
}
.update_detail_form h5 {
  margin: 0 0 30px;
}
.update_detail_form .form-group a:hover {
  text-decoration: underline;
  color: #337ab7;
}
.email_preference .form-group label small {
  font-weight: 400;
  color: #6c6c6c;
  font-size: 14px;
  display: block;
}
.checkbox_list .form-group label h6 {
  margin: 0;
  font-size: 16px;
}
.checkbox_list .form-group input[type="checkbox"] {
  vertical-align: top;
  margin: 3px 0 0;
}
.checkbox_list {
  border: 1px solid #ddd;
  margin: 3px 0 0 20px;
  background: #f3f5f8;
  border-radius: 6px;
}
.checkbox_list .form-group {
  padding: 12px 15px;
  border-bottom: 1px dashed #ccc;
  margin: 0;
}
.checkbox_list .form-group:last-child {
  border: none;
}
.checkbox_list .form-group label {
  margin: 0;
}
.email_preference fieldset {
  margin: 30px 0 0;
}
.email_preference input[type="radio"] {
  vertical-align: top;
  margin: 5px 4px 0 0;
}
.account_right h5 {
  margin: 0 0 5px;
  font-weight: 600;
}
.payment_method .payment_info {
  display: inline-block;
}
.payment_method ul li a {
  float: right;
}
.payment_method label {
  display: block;
}
.payment_method .double_field select {
  width: 50%;
  display: inline-block;
  margin-right: -2px;
}
.change_payment_mode fieldset {
  background: #f3f5f8;
  padding: 15px 20px;
  border: 1px solid #ddd;
}
.change_payment_mode img {
  max-width: 365px;
  margin: 0 0 20px;
}
.change_payment_mode {
  margin: 50px 0 0;
}
.payment_method .pink_btn {
  margin: 20px 0 0;
}
.cancel_membership .form-group label {
  font-weight: 400;
}
.cancel_membership .form-group {
  margin: 4px 0 15px;
}
.cancel_other input {
  width: 30%;
  display: inline-block;
  margin-left: 10px;
}
.cancel_other .check_bx {
  display: inline-block;
}
.cancel_membership .blue_btn {
  margin: 10px 0 0;
}
.cancel_membership h6 {
  margin: 0 0 15px;
}

/* add a role page */

.input_dropdown .caret {
  float: right;
  margin: 10px 0;
}
.input_dropdown .form-control {
  text-align: left;
}
.input_dropdown .checkbox-menu {
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 10px 10px 2px;
}
.input_dropdown .checkbox-menu input {
  margin-right: 5px;
}
.bottom_btns a {
  margin-right: 5px;
}

/* help page */
.help_page img {
  margin: 15px 0;
}
.help_page h2 {
  margin: 0 0 10px;
  font-size: 28px;
}
.news_detail {
  padding-left: 45px;
}

#news_page.help_page {
  background: #e8eeff;
}
#news_page.help_page .tab-content {
  padding-left: 0;
}
#news_page.help_page .nav-tabs > li.active > a,
#news_page.help_page .nav-tabs > li.active > a:focus,
#news_page.help_page .nav-tabs > li.active > a:hover {
  color: #025dc3;
  cursor: default;
  background-color: transparent;
  border-bottom-color: transparent;
  border-radius: 0;
}
#news_page.help_page .shadow_bg {
  margin: 0;
}

/* privacy page */
#privacy h5 {
  margin: 0 0 15px;
}
#privacy ul {
  padding-left: 20px;
}
#privacy ul li {
  list-style: disc;
  padding: 5px 0;
}

/* edit page popup */
.edit_page ul li {
  display: block;
  padding: 11px 22px;
  background: #fff;
  margin: 10px 8px;
  border-radius: 50px;
  width: 47%;
  float: left;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.edit_page ul li:hover {
  box-shadow: 0 3px 11px -4px rgba(0, 0, 0, 0.2);
  transform: translate(0, -2px);
}
.edit_page ul li:hover a {
  color: #025dc3;
}
.edit_page ul li a {
  color: #333;
}
.edit_page ul li .fa {
  float: right;
  margin: 5px 0 0;
}
.edit_page .modal-content {
  padding: 0 0 15px;
  background-color: #e8eeff;
}
.modal-content:after,
.modal-content:before {
  content: "";
  display: table;
  clear: both;
}
.modal-content {
  position: relative;
  box-shadow: none;
}

.modal-header .close {
  margin-top: -2px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  right: -5px;
  top: -5px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5);
}

/* inner pages tabbing & forms */
.white_bg ul li {
  padding: 5px 0;
}
#inner_form .multi_tab .nav-tabs {
  border: none;
}
#inner_form .multi_tab .nav-tabs > li {
  display: inline-table;
  padding: 0 0;
  width: 33%;
  transition: all ease 0.4s;
  -webkut-transition: all ease 0.4s;
}

#inner_form .multi_tab .nav-tabs > li a {
  padding: 13px 0;
  font-size: 15px;
  font-weight: 400;
}
#inner_form .multi_tab .nav-tabs > li.active > a,
#inner_form .multi_tab .nav-tabs > li.active > a:hover,
#inner_form .multi_tab .nav-tabs > li.active > a:focus {
  border: none;
  border-radius: 50px;
  padding: 13px 20px;
}
#inner_form .multi_tab hr {
  margin-bottom: 5px;
}
#inner_form .multi_tab .shadow_bg {
  padding-bottom: 0;
}
#inner_form .multi_tab .tab-content .shadow_bg {
  padding-bottom: 30px;
}
#inner_form .multi_tab .tab-content {
  margin: 30px 0 0;
}
#inner_form .multi_tab .tab-content hr {
  margin-bottom: 30px;
}
#inner_form .multi_tab .nav-tabs > li:hover {
  transform: translate(0, -2px);
  -webkit-transform: translate(0, -2px);
  padding: 0 20px;
  background: #e8eeff;
  border-radius: 50px;
}

/* video page */
.videos_table table tr td img {
  width: 150px;
  object-fit: cover;
  border-radius: 6px;
}
.videos_table .table tr td:last-child {
  white-space: nowrap;
}

/* image page */
.images_head ul li h6,
#add_name_tag ul li h6,
#edit_cast ul li h6,
.modal-child ul li h6 {
  margin: 0;
}
.images_head ul li h6 strong,
#add_name_tag ul li h6 strong,
#edit_cast ul li h6 strong,
.modal-child ul li h6 strong {
  font-weight: 600;
  font-size: 16px;
}
.image_bx .add_primary_img {
  background: #eff4f5;
  text-align: center;
  min-height: 166px;
  padding: 4rem 0 0;
}
.images_page form {
  margin: 0 0 40px;
}
.images_page .check_bx {
  margin: 10px 0 0;
}
.images_head ul li img,
#add_name_tag ul li img,
#edit_cast ul li img,
.modal-child ul li img {
  width: 55px;
  height: 70px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 3px;
}
.images_head ul li,
#add_name_tag ul li,
#edit_cast ul li,
.modal-child ul li {
  display: flex;
  align-items: center;
}
#add_name_tag fieldset input,
#myModal1 fieldset input {
  width: 86%;
  display: inline-block;
}
#add_name_tag ul li,
#edit_cast ul li,
.modal-child ul li {
  position: relative;
  border-bottom: 1px dashed #ddd;
  padding: 15px 0;
}
#add_name_tag fieldset {
  text-align: center;
}
.thumbnail_info .fa {
  position: absolute;
  right: 5px;
  top: 36%;
  font-size: 20px;
  color: #ced4d9;
}
#add_name_tag .last_btn .stroke_btn,
#edit_cast .stroke_btn {
  width: 100%;
  margin: 15px 0 0;
}
#myModal1 .blue_btn,
#add_name_tag .blue_btn {
  padding: 10px 25px;
}

/* edit featured image */
#edit_feature_page {
  background: #e8eeff;
  padding: 60px 0;
}
#edit_feature_page .add_primary_img {
  padding: 0;
  height: auto;
  min-height: 300px;
}
#edit_feature_page .inside_img input[type="file"] {
  display: none;
}

#edit_feature_page .inside_img label {
  cursor: pointer;
  color: #337ab7;
}
#edit_feature_page .bottom_desc h3 {
  display: inline-block;
}
#edit_feature_page .right_edit_option {
  float: right;
}
#edit_feature_page .bottom_desc {
  color: #535353;
}
#edit_feature_page .main_text_area ul li {
  display: inline-block;
  width: 100px;
  vertical-align: top;
  margin: 5px;
}
#edit_feature_page .inside_img {
  margin: 5px;
}
#edit_feature_page .main_text_area ul li img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.right_edit_option .form-group {
  display: inline-block;
  margin: 0 5px;
}

/* edit cast */
#edit_cast .modal-content,
#myModal1 .modal-content {
  min-height: 600px;
}
#myModal2 .controls .entry {
  margin: 0 0 15px;
}
#myModal2 .pink_btn {
  width: 100%;
}
#edit_cast ul li a .fa-edit {
  right: 35px;
}

/* register page */
#login .register_type hr {
  border-color: #cfcfcf !important;
  margin: 10px 0;
}
.stepwizard-row {
  position: relative;
  background: #b7d2ef;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

#login.register_page .stepwizard-row .btn {
  background: transparent;
  margin: 0;
  padding: 10px;
}
#login.register_page .stepwizard-row .btn-primary {
  background: #1683f3;
}

#login .register_type h4 {
  padding: 30px;
}
.stepwizard-step {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 25%;
  vertical-align: top;
  float: left;
}
.stepwizard-step a {
  width: 100%;
  border-radius: 0;
  border: none;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  opacity: 1 !important;
  margin: 0 0 4px;
  background: #fff;
  display: inline-block;
  font-weight: 600;
}
.stepwizard-step .btn-primary:after {
  border-top: 37px solid transparent;
  border-left: 25px solid #1683f3;
  border-bottom: 37px solid transparent;
  content: "";
  position: absolute;
  right: -25px;
  top: 0;
}
.stepwizard-step .btn-primary:before {
  border-top: 37px solid transparent;
  border-left: 25px solid #b7d2ef;
  border-bottom: 37px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.stepwizard-step:last-child .btn-primary:after,
.stepwizard-step:first-child .btn-primary:before {
  display: none;
}
#login.register_page .btn-primary span {
  color: #1683f3;
}
#login.register_page .btn:active,
#login.register_page .btn:focus {
  box-shadow: none;
}
.upload_resume_field p {
  display: inline-block;
  margin-right: 5px;
}
.upload_resume_field input {
  display: none;
}
.register_page .white_bg {
  margin: 20px 0 10px;
}

.regiester_option ul li img {
  width: 90px;
  margin-right: 15px;
}
.regiester_option ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #bdbdbd;
  padding: 30px 0;
}
.regiester_option ul li:first-child {
  padding-top: 0;
}
.regiester_option ul li:last-child {
  border: none;
  padding-bottom: 0;
}
.regiester_option ul li .option_info {
  width: 100%;
  position: relative;
  color: #333;
}
.option_info h5 {
  margin: 0 0 5px;
}

#register_type {
  padding: 60px 0;
}
.register_page .form-group .dropdown ul {
  width: 100%;
  padding: 10px 20px;
  height: 350px;
  overflow: auto;
}
.register_page .form-group .dropdown ul li h6 {
  background: #eee;
  padding: 8px 10px;
  font-size: 15px;
  font-weight: 600;
}
.register_page .form-group .dropdown .caret {
  float: right;
  margin: 11px;
}
.register_page .form-group .dropdown .dropdown-toggle {
  color: #333;
}

#login .register_type::-webkit-scrollbar-track,
#make_film_detail ul::-webkit-scrollbar-track,
#make_film_option .dropdown-menu::-webkit-scrollbar-track {
  background: #bdc8d5;
}
#login .register_type::-webkit-scrollbar-thumb {
  background: #eee;
}
#login .register_type::-webkit-scrollbar-thumb:horizontal,
#make_film_detail ul::-webkit-scrollbar-thumb:horizontal {
  background: #eee;
}
/* width */
#login .register_type::-webkit-scrollbar,
#make_film_detail ul::-webkit-scrollbar,
#make_film_option .dropdown-menu::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
#make_film_detail ul::-webkit-scrollbar-thumb,
#make_film_option .dropdown-menu::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Track */
#login .register_type::-moz-scrollbar-track {
  background: #bdc8d5;
}

/* Handle */
#login .register_type::-moz-scrollbar-thumb {
  background: #eee;
}

#login .register_type::-moz-scrollbar-thumb:horizontal {
  background: #eee;
}

/* make a film */
.make_film_header .form-control {
  background: transparent;
  width: 300px;
  margin: 30px 0 0;
  color: #ddd;
}

#make_film_option ul li {
  width: 25%;
  float: left;
  padding: 15px 15px;
}
#make_film_option ul li p {
  display: block;
  background: #e8eeff;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
}
#make_film_option ul li p[aria-expanded="true"] {
  background: #1683f3;
  color: #fff;
}

#make_film_option ul li p .fa {
  float: right;
  margin: 6px 0 0;
}
#make_film_option .dropdown-menu li {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 8px 15px;
  font-size: 13px;
}
#make_film_option .dropdown-menu li a {
  font-weight: 600;
}
#make_film_option .dropdown-menu li a em {
  font-weight: 400;
  font-size: 11px;
  float: right;
}
#make_film_option .dropdown-menu {
  right: auto;
  left: 0;
  border: none;
  height: 270px;
  overflow: auto;
}
#make_film_option .dropdown-menu li.search_result {
  background: #e8eeff;
  padding: 3px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
}

#make_film_option .view_all {
  font-weight: 600;
  font-size: 16px;
  padding: 5px 15px;
}

#make_film_option {
  padding: 30px 0;
}

#make_film_detail {
  background: #e8eeff;
  padding: 40px 0;
}
#make_film_detail ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.cate_img .film_left_detail img {
  margin: 0 20px 0 0;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}
.cate_img .film_left_detail {
  display: flex;
  align-items: center;
}
.make_film_contact .cate_img .film_left_detail {
  width: 55%;
}
#make_film_detail ul li h6 {
  margin: 5px 0 2px;
  font-weight: 600;
}
#make_film_detail ul li small {
  font-style: italic;
}
#make_film_detail ul li .fa {
  font-size: 22px;
  color: #c3c3c3;
}
#make_film_detail .white_bg {
  background: #fff;
  padding: 20px 20px 60px;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
}
.film_cat_head h5 {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
}

.cat_inner_head {
  background: #e8eeff;
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 12px 0;
}

#make_film_detail ul {
  height: 480px;
  overflow: auto;
}
#make_film_detail .view_all {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  border-top: 1px solid #eee;
  bottom: 16px;
  padding-top: 14px;
}
.film_bottom_btn .blue_btn {
  margin: 15px 5px;
}

/* feature deatail page */
#celebrity_detail {
  padding: 5rem 0;
}
#celebrity_banner {
  background: #003169;
  padding: 5rem 0;
  color: #fff;
  font-size: 15px;
}
.celebrity_info img {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: -5px -6px 0 #0b8ade;
  border: 6px solid #003169;
}
.celebrity_info h3 {
  font-size: 34px;
  font-weight: 600;
  color: #fff;
}
.star_rating {
  color: #ffc72f;
  margin: 15px 0;
}
.celebrity_info h3 {
  font-size: 34px;
  font-weight: 600;
  color: #fff;
  margin: 25px 0 10px;
}
.action_btns {
  margin: 30px 0 0;
}
.action_btns a {
  min-width: 300px;
  text-align: center;
  border-radius: 50px;
  margin: 8px 0;
  padding: 10px 10px;
  text-transform: none;
  font-weight: 600;
}
.action_btns .pink_btn {
  background: #f11a66;
}
.action_btns .stroke_btn {
  border-color: #fff;
  color: #fff;
}
.celebrity_info a {
  color: #0e9bf5;
}
.dark_bg {
  background: #131722;
}
.light_bg {
  background: #e7edfe;
}
.all_reviews .dark_bg {
  border-radius: 20px;
  padding: 30px;
}
.all_reviews ul li {
  color: #cdcdcd;
  border-bottom: 1px solid #2f3441;
  padding: 20px 0;
}
.review_user {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
}
.all_reviews ul li h6 {
  color: #fff;
  font-weight: 600;
  margin: 0 0 5px;
  font-size: 17px;
}
.all_reviews ul li span {
  color: #f84584;
  font-weight: 600;
}
.review_user {
  display: flex;
  align-items: center;
}
.review_user img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  border: 4px solid #f11a66;
}
.all_reviews h5 {
  color: #339ae3;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 10px;
}
.all_reviews .pagination > li > a,
.all_reviews .pagination > li > span {
  position: relative;
  float: left;
  padding: 2px 7px;
  margin: 0 5px;
  line-height: 1.42857143;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #4b5265;
  border-radius: 50%;
  font-size: 12px;
}
.all_reviews .pagination > li.active > a {
  background: #339ae3;
  border-color: #339ae3;
}
.all_reviews .pagination {
  margin: 20px 0 0;
}
.celebrity_video img {
  border-radius: 15px;
  border: 4px solid #fff;
  box-shadow: 0 5px 12px -2px rgba(0, 0, 0, 0.2);
}
.youtube_icon img {
  border: none;
  border-radius: 50%;
  width: 36px;
  position: absolute;
  bottom: 16px;
  left: 34px;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.4);
}
.celebrity_video {
  position: relative;
  margin-bottom: 30px;
}
.celebrity_video span {
  background: #e8eeff;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  right: 35px;
  border-radius: 20px;
  padding: 1px 12px;
  font-weight: 600;
  color: #003169;
  font-size: 13px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
#how_work {
  color: #fff;
}
#how_work .modal-content h4 {
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
#how_work .modal-content {
  background: #003169;
  padding: 30px 50px;
}
.action_btns p a {
  color: #fff;
}
#how_work .modal-content ul li .left_icon {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  background: #f11a66;
  border-radius: 50%;
  line-height: 38px;
  display: inline-table;
  position: relative;
  z-index: 9;
  font-size: 16px;
}
#how_work .modal-content ul li {
  padding: 9px 14px;
  display: inline-flex;
  align-items: center;
  min-height: 85px;
}
#how_work .modal-content ul li .left_icon:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 102%;
  background: #144782;
  top: 42px;
  left: 20px;
}
#how_work .modal-content ul li:last-child .left_icon:before {
  display: none;
}
#how_work .close {
  position: absolute;
  right: -21px;
  top: -9px;
  color: #fff;
  opacity: 0.6;
  font-size: 25px;
  font-weight: 400;
}

/* feature main page */
#main_feature_detail {
  padding: 5rem 0 5rem;
}
#celebrity_feature_banner {
//  background: "url(${process.env.PUBLIC_URL + " / feature_main_banner.jpg "})"
  padding: 14rem 0;
  background-position: center center;
  background-size: cover;
}
#celebrity_feature_banner h2 {
  color: #fff;
  font-weight: 700;
  line-height: 40px;
}
#main_feature_detail ul li:after,
#main_feature_detail ul li:before,
#main_feature_detail ul:after,
#main_feature_detail ul:before {
  content: "";
  display: table;
  clear: both;
}
#main_feature_detail ul li:hover {
  transform: translate(0, -5px);
}
#main_feature_detail ul li {
  float: left;
  width: 20%;
  transition: all ease-in 0.2s;
}
#main_feature_detail ul li .stars_profile {
  padding: 15px;
}
#main_feature_detail ul li .stars_profile .bg_bx {
  background: #131722;
  border: 4px solid #191f2d;
  border-radius: 12px;
  color: #fff;
}
#main_feature_detail ul li .stars_profile .bg_bx span {
  width: 45px;
  height: 45px;
  background: #f11a66;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  float: right;
  margin-top: -32px;
  position: relative;
  z-index: 9;
  margin-right: -14px;
}
.feature_heading_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 15px;
  padding: 0 15px;
  width: 100%;
}
.feature_heading_part h3 {
  color: #fff;
  font-weight: 600;
  position: relative;
  padding-left: 60px;
}
.feature_heading_part h3:before {
  content: "";
  width: 40px;
  height: 4px;
  background: #f11a66;
  position: absolute;
  left: 0;
  top: 12px;
}
#main_feature_detail ul li .star_detail {
  padding: 10px 20px 15px;
  color: #cdcdcd;
}
#main_feature_detail ul li .star_detail h6,
#main_feature_detail ul li .star_detail p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#main_feature_detail ul li .star_detail h6 {
  font-weight: 600;
  color: #fff;
  margin: 10px 0 4px;
}
.feature_heading_part .blue_btn {
  text-transform: none;
  border-radius: 20px;
  font-weight: 600;
}
#main_feature_detail .cat_list ul li a {
  color: #fff;
  background: #202532;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  margin: 6px 0;
}
#main_feature_detail .cat_list ul li button {
  color: #fff;
  background: #202532;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  margin: 6px 0;
}
#main_feature_detail .cat_list ul li {
  float: none;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
#main_feature_detail .cat_list ul li:hover {
  transform: translate(0, -3px);
}
#main_feature_detail .cat_list ul li a span {
  display: inline-block;
  margin-left: 10px;
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 600;
}
#main_feature_detail .cat_list ul li a.active {
  background: #1683f3;
}
#slider_part {
  position: relative;
  border-radius: 0;
}
header .stroke_btn {
  margin: 0;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
}
#slider_part .owl-carousel {  
  overflow: hidden;
}
.head_part h2 {
  margin: 0 0 30px;
  font-weight: 600;
}
#popular_tag .nav-tabs{border:none;}
#popular_tag .nav-tabs>li>a {
  color: #b5b8bd;
  font-weight: 600;
}
#popular_tag .nav-tabs>li {
  float: none;
  font-size: 20px;
}
#popular_tag .nav-tabs>li.active>a, #popular_tag .nav-tabs>li.active>a:focus, #popular_tag .nav-tabs>li.active>a:hover {
  color: #2196F3 !important;
  background-color: transparent;
  border: none;
  border-bottom-color: transparent;
  border-left: 3px solid #2196F3;
  border-radius: 0;
}
.pill_result li {display: inline-block;}
.pill_result li span {
  display: inline-block;
  background: #19283a;
  margin: 5px;
  border-radius: 50px;
  font-size: 15px;
  padding: 7px 20px;
  color: #f2f2f2;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.pill_result li:hover span {
  background: #0956a6;
}
#popular_tag img {
  border-radius: 10px;
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin: 0 0 20px;
}
#slider_part .overlay{
  background: linear-gradient(to right, #240653 1%, rgba(19, 53, 168, 0.9) 54%, rgba(0, 0, 0, 0) 86%);
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 1;
}
#slider_part .banner_text p {
  font-size: 16px;
  text-shadow: none;
  color: #fff;
  line-height: 25px;
  font-weight: 300;
}
#slider_part .stroke_btn {
  border: 1px solid #1683f3;
  display: inline-block;
  background: #1683f3;
  color: #ffffff;
  padding: 10px 23px;
  border-radius: 0;
  font-size: 18px;
  text-shadow: none;
  width: auto;
  text-align: center;
  margin: 40px 0 0;
}
#slider_part .owl-carousel.owl-drag .owl-item{max-height: 550px;}

/* new design css */
#wallet_banner {
  background: linear-gradient(298deg, #f35524 6%, #c82538 21%, #1c1c1c 84%);
  height: 315px;
  overflow: hidden;
}
.search_part {
  width: 50%;
  padding-right: 6px;
}
.search_job_list .filter_flex {
  display: flex;
  align-items: flex-end;
  margin-bottom: 22px;
  padding: 10px 25px 0;
}
.filter_flex .btn.btn-info {
  background:#c62537;
  border-color:#c62537;
}
.fund_info .blue_btn svg {
  display: block;
  margin: 0 auto 7px;
  font-size: 20px;
}
.fund_info .blue_btn {
  height: 99px;
  border-radius: 0;
}
.wallet_page h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 11px 0 19px;
}
.wallet_page .table tbody tr td:last-child {
  padding-right: 25px;
}
.wallet_page .inside_listing_tab {
  border-radius: 0;
  background: #fff;
  border: 1px solid #e7e7e7;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  border-top: 1px solid #e7e7e7 !important;
}
.wallet_page .table tbody tr td .btn-primary {
  color: #fff;
  background-color: #2e2e2e;
  border-color: #2e2e2e;
}
#user_pro_banner {
  background: linear-gradient(298deg, #f35524 6%, #c82538 21%, #1c1c1c 84%);
  height: 170px;
  overflow: hidden;
}
#user_pro_banner img {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0.1;
  bottom:0;
}
#people_page.default_bg {
  padding: 0 0 40px;
}
.people_detail_banner {
  margin: -101px 0 30px;
}
.score-container h5 {
  font-size: 15px;
  min-height: 35px;
}
.merit-score-bar span {
  font-size: 12px;
}
#people_other_info .tab_title h5 {
  font-weight: 600;
}
#people_page .job_list h5 {
  background: #f7f7f7;
  padding: 11px 51px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  width: 100%;
  clear: both;
  border-radius: 11px 0 0 11px;
  margin: 0 0 17px;
  position: relative;
}
.profile_area .job_list ul li div {
  width: 70%;
}
#people_page .job_list ul {
  min-height: 184px;
}
#people_page .job_list ul li{
  display: flex;
}
.inside_icon {
  width: 40px;
  height: 40px;
  background: #c62537;
  position: absolute;
  color: #fff;
  text-align: center;
  line-height: 45px;
  border-radius: 50px;
  left: 12px;
  top: 0px;
  font-size: 21px;
}
.work_his_icon img {
  opacity: 0.8;
  max-width: 48px;
}
.work_his_icon {
  background: #f7f7f7;
  width: 93px;
  padding: 16px 0;
  margin-right: 20px;
  border-radius: 2px;
  min-width: 93px;
  height: 85px;
  text-align: center;
}
.profile_area .work_head small, .educational_info .edu_info small{
  margin-right: 6px;
  background: #eafbe6;
  padding: 0px 10px;
  text-align: center;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 4px;
  line-height: 22px;
  color: #0b7e25;
  font-weight: 500;
  font-size: 11.5px;
}

.profile_area .work_head a.drop_btn {
    padding: 5px;
    display: inline-block;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 20px;
    border-radius: 50px;
    border: 1px solid #b3b3b3;
    color: #9f9f9f;
    position: absolute;
    right: 0;
    top: 0;
}
.tab_info.client_his ul li:last-child {
  border: none;
  padding: 0;
}
.educational_info .flex_bx {
  align-items: flex-start;
  justify-content: flex-start;
}
.educational_info .work_his_icon {
  padding: 19px 0;
}
#people_page .job_list h5 .inside_icon {
  left: -3px;
}
.profile_area .about_info ul li span {
  width: 46%;
}
.review_bx:last-child {
  display: none;
}
.profile_review {
  border: 1px solid #e7e7e7;
  padding: 16px 20px;
  margin: 15px 0;
  border-radius: 3px;
}
.profile_review i {
  font-weight: 600;
}
#people_page .addi_info ul li {
  display: inline-flex;
  width: 50%;
  margin: 2px 0;
  padding-right: 17px;
}
.profile_area .about_info ul li em {
  font-style: normal;
  width: 70%;
}
.profile_area .about_info .tab_info h5 {
  font-size: 18px;
  margin: 0 0 13px;
  font-weight: 600;
}
#payment_mode .form-group img {
  max-width: 66px;
  border: 1px solid #ddd;
  height: 39px;
  object-fit: contain;
  padding: 5px;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 16px;
  margin-top: -6px;
}
.form_outer {
  border: 1px solid #e7e7e7;
  background: #fff;
  padding: 20px 30px;
  max-width: 550px;
  margin: 0 auto;
}
#payment_mode h5{margin:0 0 30px;}
.payment_qr img {
  max-width: 175px;
}
.payment_qr {
  background: #fff;
  padding: 0;
  display: flex;
  align-items: center;
}
#payment_mode .tab-content .blue_btn {
  width: 100%;
  margin: 10px 0 0;
}
#payment_mode .nav-tabs {
  border: none;
  max-width: 550px;
  margin: -100px auto 0;
}
#payment_mode .nav-tabs > li {
  height: 100px;
  background: #f9f9f9;
  text-align: center;
  margin: 0 0 15px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 4px solid transparent;
}
#payment_mode .nav-tabs > li a {
  color: #585858;
}
#payment_mode .nav-tabs>li.active>a, #payment_mode .nav-tabs>li.active>a:focus, #payment_mode .nav-tabs>li.active>a:hover {
  color: #555;
  cursor: default;
  background-color: transparent;
  border: none;
  border-bottom-color: transparent;
  border-radius: 0;
}
#payment_mode .nav-tabs > li > a:hover {
  border-color: transparent;
  background: transparent;
}
#payment_mode h4 {
  margin: 0 0 20px;
  font-weight: 600;
}
#payment_mode .nav-tabs > li svg {
  font-size: 24px;
  vertical-align: middle;
  display: block;
  margin: 0 auto 9px;
}
#payment_mode .nav-tabs > li.active a {
  color: #db1d33;
}
#payment_mode .nav-tabs > li.active {
  background: #ffffff;
  border-bottom: 4px solid #d51e33;
}
#user_payment {
  background: linear-gradient(298deg, #f35524 6%, #c82538 21%, #1c1c1c 84%);
  height: 205px;
  overflow: hidden;
}
#payment_mode {
  padding-bottom: 50px;
}
.navbar .search_filed input {
  border: none;
}

/***************  responsive ************************/

@media (max-width: 1399px) {
  .search input {
    min-width: 450px;
  }
  #make_film_section img {
    position: absolute;
    right: -50px;
    top: -16rem;
    max-width: 78rem;
  }
  #make_film_section {
    background: linear-gradient(24deg, #131722 52%, #dc1838 47%);
  }
  .film_cate_part span {
    display: inline-block;
    padding: 7px 28px;
  }
}

@media (max-width: 1199px) {
  .search input {
    min-width: 300px;
  }
  .navbar-nav > li > a {
    padding: 9px 8px;
  }
  header .logo {
    padding: 0 8px;
  }
  .jctkr-label {
    font-size: 11px;
  }
  #slider_part .banner_text h1 {
    line-height: 36px;
    font-size: 30px;
  }
  #auditions .event_cal {
    padding: 0 20px;
  }
  .event_bg {
    padding: 0 20px;
  }
  .event_cal ul li .date {
    font-size: 18px;
  }
  .event_cal ul li .event_detail h4 {
    font-size: 18px;
    width: 86%;
  }
  .need_work {
    padding: 0 15px 0 3rem;
  }
  #make_film_section img {
    right: -30px;
    top: -3rem;
    max-width: 60rem;
  }
  .film_cate_part span {
    padding: 5px 24px;
    margin: 7px 5px;
  }
  .make_film_info {
    padding-right: 4rem;
  }
  .film_cate_part {
    margin: 22px 0;
  }
  .make_film_info p {
    font-size: 18px;
  }
  .mid_area h6 {
    font-size: 18px;
    margin: 5px 0 7px;
    line-height: 25px;
  }
  #movie_status .trendy_txt h6 {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 15px;
  }
  .trendy_txt small {
    line-height: 16px;
    display: inline-block;
  }
}

@media screen and (max-width: 767px) {
  .table-responsive thead,
  .table-responsive tbody,
  .table-responsive tfoot,
  .table-responsive tr,
  .table-responsive th,
  .table-responsive td {
    display: block;
  }
  .table-responsive thead {
    display: none;
  }
  .table-responsive .align-center,
  .table-responsive .align-right {
    text-align: inherit;
  }
  .table-responsive tr {
    border-top: 1px solid #ddd;
  }
  .table-responsive tr:first-child {
    border: 0;
  }
  .table-responsive > tbody > tr > td {
    padding-left: 50%;
    border-top-color: rgba(221, 221, 221, 0.5);
  }
  .table-responsive > tbody > tr > td:first-child {
    border: 0;
  }
  .table-responsive [data-label] {
    position: relative;
  }
  .table-responsive [data-label]:before {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    content: attr(data-label);
    font-weight: bold;
  }

  .navbar-fixed-top.visible-xs + .row {
    padding-top: 60px;
  }

  .btn .visible-xs {
    display: inline-block !important;
  }

  .sliding-sidebar {
    position: absolute;
    z-index: 1;
    background: #fff;
    min-height: 100%;
    border-right: 1px solid #ccc;
  }
  .sliding-sidebar.collapse,
  .sliding-sidebar.collapsing {
    display: block !important;
    -webkit-transform: translate3D(-100%, 0, 0);
    transform: translate3D(-100%, 0, 0);
    transition: all 200ms;
  }
  .sliding-sidebar.collapse.in {
    -webkit-transform: translate3D(0, 0, 0);
    transform: translate3D(0, 0, 0);
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.05), 2px 0 0 rgba(0, 0, 0, 0.05),
      3px 0 0 rgba(0, 0, 0, 0.05);
  }
}



@media (max-width: 1199px) { 
  
  #track_page_header .top_left_info {
    padding: 40px 0 0 0;
  }
  #track_page_header .banner_img img,
  #track_page_header.my_list .banner_img img {
    max-width: 100%;
  }
  .top_links ul li {
    padding: 30px 20px 25px;
  }
  .footer_links ul li,
  .copyright ul li {
    padding-right: 20px;
  }
  .list_part ul li .list_info a {
    width: 330px;
  }
}

@media (max-width: 991px) {
  .search input {
    min-width: 356px;
  }
  .navbar-nav > li > a {
    padding: 9px 8px;
  }
  .logo img {
    display: inline-block;
  }
     .top_nav ul li a img {
    margin-right: 0px;
    width: 18px;
  }
  .top_nav ul li a.dropdown-toggle img {
    width: 21px;
  }
 
  #track_page_header h2,
  #inner_banner h2 {
    font-size: 28px;
  }
  #track_page_header .top_left_info {
    font-size: 16px;
    padding: 0;
    line-height: 26px;
  }
  #track_page_header h2,
  #inner_banner h2 {
    margin: 0 0 10px;
  }
  #track_page_header .stroke_btn {
    padding: 7px 30px;
    margin: 26px 0 0;
    font-size: 14px;
  }
  .top_links ul li {
    padding: 30px 12px 25px;
  }
  .footer_info_part h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
  .list_part ul li .item_info span {
    padding: 6px 14px;
  }
  .list_filter .stroke_btn {
    padding: 10px 15px;
  }
  #slider_part .carousel,
  #slider_part .carousel-inner,
  #slider_part .carousel-inner .item {
    height: 400px;
  }
  #slider_part .banner_text {
    left: 3rem;
    top: 9rem;
  }
  #slider_part .banner_text h1 {
    line-height: 32px;
    font-size: 26px;
  }
  #slider_part .banner_text {
    font-size: 17px;
  }
  .work_head h2 {
    margin: 45px 0 20px;
  }
  #top_banner h2,
  #news_gallery h2,
  #top_banner h2,
  #movie_status h2 {
    font-size: 24px;
  }
  #top_banner .nav-tabs > li > a,
  #news_gallery .nav-tabs li a,
  #movie_status .nav-tabs li a {
    margin-right: 0;
    font-size: 13px;
    padding: 10px 4px;
  }
  .mid_area:last-child {
    display: none;
  }
  .big_img img {
    height: 220px;
    width: 100%;
  }
  .big_img {
    margin: 0 0 10px;
  }
  #make_film_section img {
    top: 1rem;
    max-width: 52rem;
    right: -60px;
  }
  .make_film_info h2 {
    margin: 0 0 15px;
    font-size: 30px;
  }
  .make_film_info p {
    font-size: 15px;
  }
  #make_film_section {
    background: linear-gradient(34deg, #131722 55%, #dc1838 47%);
    height: 500px;
  }
  .wrap {
    width: 83%;
  }
  .jctkr-label {
    width: 16%;
  }
}

@media (max-width: 767px) {
  #track_page_tab .nav-tabs > li,
  #inner_form .nav-tabs > li {
    display: inline-block;
  }
  #track_page_tab .nav-tabs > li > a,
  #inner_form .nav-tabs > li > a {
    padding: 8px 20px;
    font-size: 14px;
  }
  #track_page_tab .nav-tabs > li.active > a,
  #track_page_tab .nav-tabs > li.active > a:focus,
  #track_page_tab .nav-tabs > li.active > a:hover,
  #create_breakdown .nav-tabs > li.active > a,
  #create_breakdown .nav-tabs > li.active > a:focus,
  #create_breakdown .nav-tabs > li.active > a:hover,
  #inner_form .nav-tabs > li.active > a,
  #inner_form .nav-tabs > li.active > a:focus,
  #inner_form .nav-tabs > li.active > a:hover,
  #news_page .nav-tabs > li.active > a,
  #news_page .nav-tabs > li.active > a:focus,
  #news_page .nav-tabs > li.active > a:hover {
    border-radius: 25px;
  }
  #track_page_tab .tab-content {
    padding-left: 0;
  }
 
  #track_page_tab .side_bar_bg,
  #list_table .side_bar_bg {
    display: none;
  }
  #track_page_tab,
  #list_table {
    padding: 0 0 60px;
  }
  #track_page_tab .right_space,
  #list_table .list_filter {
    padding: 20px 20px;
    margin: 0 -5px 30px;
  }
  .inner_head {
    margin: 20px 0 30px;
    text-align: center;
  }
  #track_page_header .banner_img img {
    max-width: 250px;
  }
  #track_page_header .banner_img {
    text-align: center;
  }
  #track_page_header {
    padding-top: 25px;
  }
  .track_point ul li .track_info a {
    font-size: 17px;
  }
  .top_links ul li {
    padding: 5px 12px;
  }
  .top_links {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  footer {
    text-align: center;
  }
  .footer_info_part h3 {
    margin: 18px 0 5px;
  }
  .copyright {
    margin: 15px auto 0;
    padding: 0;
    font-size: 13px;
  }
  
  footer .logo {
    text-align: right;
  }
  .list_part,
  .left_space {
    padding-left: 15px;
  }
  .main_head {
    display: block;
  }
  #top_banner .nav-tabs > li > a,
  #news_gallery .nav-tabs li a,
  #movie_status .nav-tabs li a {
    padding: 10px 8px;
  }
  #news_gallery .tab-content {
    padding: 12px 15px 0;
  }
  #news_gallery .blue_btn {
    margin: 5px 0 10px;
  }
  #news_gallery .bottom_btn:after {
    top: 26px;
  }
  #make_film_section img {
    top: -17rem;
    max-width: 46rem;
    right: -75px;
  }
  #make_film_section {
    background: linear-gradient(20deg, #131722 59%, #dc1838 47%);
  }
  .make_film_info {
    bottom: 54px;
  }
  .mobile_view {
    display: block !important;
  }
  .web_view {
    display: none !important;
  }
  .search input {
    min-width: 100%;
  }
  .top_nav .search {
    position: absolute;
    width: 90%;
    padding: 9px 10px 0;
    z-index: 1;
    top: 50px;
    left: 0;
  }
  .search .btn {
    right: 10px;
    top: 6px;
  }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 6px;
  }
  .navbar-toggle .icon-bar {
    background: #fff;
  }
  .top_nav .navbar-collapse ul {
    width: 100%;
  }
  .top_nav .navbar-collapse ul li {
    display: block;
    padding: 0;
    float: none;
    width: 100%;
    text-align: center;
  }
  .top_nav ul .mega-dropdown {
    width: 100%;
  }
  .navbar-nav .menu_drop .open .mega-dropdown-menu  {
    position: absolute;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.9);
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    left: 0;
    width: 100%;
    right: 0;
    height: 400px;
    overflow: auto;
  }
  .mega_links {
    width: 50%;
  }
}

@media (max-width: 575px) {
  footer .logo img {
    width: 110px;
  }
  .social_icons ul li {
    padding: 18px 9px 0;
  }
  .footer_links {
    margin: 10px 0 0;
  }
  .top_links ul li {
    padding: 2px 8px;
  }
  #track_page_tab .tab-content h6,
  #inner_form .nav-tabs {
    margin: 0 0 15px;
    font-size: 21px;
  }
  #track_page_header h2,
  #inner_banner h2 {
    font-size: 22px;
  }
  .list_part ul li {
    display: block;
  }
  .list_part ul li .list_info a {
    width: 100%;
  }
  .work_icon {
    width: 100%;
    height: 75px;
    max-width: 75px;
    min-width: 75px;
    margin: 0 auto;
  }
  .work_icon img {
    width: 45px;
    margin: 15px auto 0;
  }
  .work_bx {
    display: block;
    text-align: center;
  }
  .work_info {
    padding: 0;
    width: 100%;
  }
  .need_work {
    padding: 0 20px;
  }
  .work_info span {
    left: 0;
    right: 0;
    margin: 6px auto 0;
  }
  .work_bx h4 {
    margin: 40px 0 8px;
  }
  .work_head h2 {
    margin: 45px 0 30px;
    font-size: 24px;
    text-align: center;
  }
  #movie_status h2:after,
  #top_banner h2:after {
    display: none;
  }
  #make_film_section img {
    top: -17rem;
    max-width: 37rem;
    right: -26px;
  }
  #make_film_section {
    height: 560px;
  }
  .film_cate_part {
    margin: 12px 0 22px;
  }
  .make_film_info h2 {
    font-size: 28px;
  }
  .full {
    width: 100%;
  }
  #movie_status li:last-child .trendy_txt {
    border-bottom: 1px solid #37373a;
  }
  .mid_area {
    display: block;
  }
  #movie_status .side_movie_img img {
    width: 100%;
    height: 185px;
  }
  #movie_status .full_movie_detail {
    margin-left: 0;
    margin-top: 10px;
  }
  #top_banner .nav-tabs > li > a,
  #news_gallery .nav-tabs li a,
  #movie_status .nav-tabs li a {
    padding: 4px 7px;
  }
  #top_banner h2,
  #news_gallery h2,
  #top_banner h2,
  #movie_status h2 {
    font-size: 24px;
    margin: 0 0 10px;
  }
  .mid_area {
    margin: 10px 0 40px;
  }
  #news_gallery small {
    font-size: 12px;
    display: block;
    margin: 4px 0 10px;
    line-height: 16px;
  }
  #news_gallery h5 {
    font-size: 13px;
  }
  #news_gallery .small_img {
    margin-bottom: 20px;
    min-height: 245px;
  }
  .big_img {
    margin: 0 0 25px;
  }
  .audition_head h2 {
    font-size: 28px;
  }
  .audition_head {
    padding: 43px 40px 28px;
  }
  .event_cal ul li .date {
    font-size: 16px;
  }
  .event_cal ul li .date:after {
    right: -30px;
  }
  .event_cal ul li .event_detail {
    width: 80%;
  }
  .event_cal ul li .event_detail h4 {
    font-size: 18px;
    width: 80%;
  }
  .event_cal ul li .event_detail small {
    font-size: 11px;
  }
  #top_banner {
    padding-top: 0;
  }
  .head_img {
    width: 90px;
  }
  #auditions {
    padding: 35px 0 60px;
  }
  #slider_part .banner_text {
    left: 0;
    top: 9rem;
  }
  #slider_part .banner_text h1 {
    line-height: 28px;
    font-size: 22px;
  }
  #slider_part .banner_text {
    font-size: 16px;
    line-height: 22px;
  }
  .top_nav .search {
    min-width: auto;
    width: 85%;
  }
  .search input {
    min-width: auto;
    width: 100%;
  }
  .mega_links {
    width: 100%;
    min-height: auto;
  }
  .jctkr-label,
  .wrap {
    width: 100%;
    text-align: center;
  }
  .jctkr-label:after {
    content: "";
    position: absolute;
    right: -99%;
    top: 0;
    width: 100%;
    height: 100%;
    background: #eae100;
    z-index: 0;
  }
}

/* chat send button css */
.cgZnmU {
  display: flex;
  border: 0.2rem solid rgb(69, 255, 255);
  border-radius: 0.8rem;
  background-color: rgb(62, 62, 62);
}

.hfqhoP {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255);
}

.bQBzzj {
  font-family: cerebri, sans-serif;
  font-weight: 400;
  letter-spacing: -0.01rem;
  line-height: 1.8rem;
  background-color: rgb(62, 62, 62) !important;
  outline: none;
  flex: 1 1 0%;
  font-size: 1.4rem !important;
  border-bottom: none !important;
  box-shadow: none !important;
  padding: 1.6rem 5rem 1.6rem 2.4rem !important;
  border-top-left-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important;
}

.jTfAtm:not(:disabled) {
  cursor: pointer;
}

.jTfAtm {
  display: inline-block;
  appearance: none;
  border: 0.1rem solid rgb(69, 255, 255);
  transition: border-color 216ms ease-in-out 0s,
    background-color 216ms ease-in-out 0s;
  width: inherit;
  position: relative;
  outline: none;
  text-decoration: none;
  background-color: rgb(69, 255, 255);
  color: rgb(16, 16, 16);
  font-family: cerebri, sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: -0.01rem;
  line-height: 2.2rem;
  padding: 1rem 1.8rem;
  border-radius: 0.6rem;
}

.ljMbZC {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  opacity: 1;
}
.app{
  display: table;
  margin: 0 auto;
}
.skills_part{
  textarea{
    height: auto !important;
  }
}
.tax-details-container{
    padding: 0 !important;
}
.tax-details-container h5 {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 0;
}
.field-info {
  background: #b7d2ef;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;
  height: 16px;
  width: 16px;
  line-height: 14px;
  text-align: center;
}
.field-info img {
  height: 7px !important;
  width: 7px !important;
}
.tooltp {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltp .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  font-size: 11px;
  padding: 5px;
  line-height: 16px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 137px;
  top: 100%;
  left: 50%;
  margin-left: -60px; 
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltp:hover .tooltiptext {
  visibility: visible;
}
.tooltp .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -3px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.disable{
  span{
    opacity: 0.5;
  cursor: not-allowed !important;
  }
}
.card-wrappers {
  display: flex;
  flex-wrap: wrap;
  background: #eff2f3;
  padding: 9px 12px 6px;
  border-radius: 6px;
}
.card {
  min-width: 24%;
  margin: 0px 4px 4px 0px;
  white-space: nowrap;
  color: #646464cc;
  line-height: 19px;
  font-size: 13px;
}
.m-1{
  margin: 2px !important;
}



.cropper {
  position: fixed;
  z-index: 999999;
  border-radius: 10px;
  width: 450px;
  height: 333px;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 58px rgba(0,0,0,0.3);
  border: 1px solid #fff;
}
.action-btns{
  position: absolute;
    bottom: 0;
    background: #eee;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 0 15px;
}
.aspect-ratios {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 6px;
  color: #3a3a3a;
  font-weight: 600;
  font-size: 13px;
}

.can_btn {
  border-radius: 3px;
  padding: 4px 12px;
  background: transparent;
  color: #1683f3;
  border: 1px solid #1683f3;
  font-size: 13px;
  font-weight: 600;
}
.ok_btn{
  border: none;
  border-radius: 3px;
  padding: 5px 12px;
  background: linear-gradient(45deg, rgba(59,173,227,1) 0%, rgba(87,111,230,1) 25%, rgba(152,68,183,1) 51%, rgba(255,53,127,1) 100%);
  color: #fff;
  margin-left: 9px;
  font-size: 13px;
  font-weight: 600;
}
.input-radio{
    padding-right: 8px;
    display: flex;
    align-items: center;
}
.aspect-ratios input[type=radio] {
  margin: 0;
  margin-right: 4px;
  display: initial;
  margin-left: 6px;
}
.MuiOutlinedInput-input {
  padding: 14px !important;
  font-size: 16px;
}