.my-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;
  }
  .my-row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  .razorpay-btn{
    cursor: pointer;
    background: #bb3ea3;
    border-radius: 2px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    width: 100%;
    border: none;
  }
  .pt-5{
    padding-top: 2rem;
  }

.celebrity_detail .people_list_info h6 {
  font-weight: 600;
}
.people_short_info {
  margin: 0;
}
.item-label{
  font-weight: 400;
  
}