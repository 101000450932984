@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* CSS Document */
body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 400;
  line-height: 24px;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: inherit;
}
a:hover {
  color: #2769a1;
}
img {
  max-width: 100%;
}
a {
  color: #327ac6;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: #3a3a3a;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 34px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  margin: 0;
}


button:focus,
input:focus {
  box-shadow: none;
  outline: none !important;
}
/* button */
.blue_btn,
.default_btn {
  background: #c62537;
  border: none;
  border-radius: 3px;
  color: #fff !important;
  display: inline-block;
  font-size: 15px;
  padding: 9px 25px;
  position: relative;
}
.default_btn {
  background: #1683f3;
}
.blue_btn:hover,
.blue_btn:focus {
  transition: all ease-in-out 0.6s;
  color: #fff !important;
  background: #cd1227;
}
.blue_btn a:hover, .blue_btn a:focus{ color: #fff !important;}
.pink_btn:hover,
.pink_btn:focus {
  transition: all ease-in-out 0.6s;
  color: #db1a47;
  background: #ffa22b;
}
.stroke_btn {
  border: 1px solid #1683f3;
  display: inline-block;
  background: transparent;
  color: #1683f3;
  padding: 8px 30px;
  border-radius: 3px;
  font-size: 15px;
}

/* input */
input[type="text"],
select,
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 8px 15px;
  font-size: 13px;
  background: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.form-control {
  height: 44px;
  box-shadow: none;
}
.form-control:focus{box-shadow: none;}
label {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0;
}
textarea {
  height: 80px !important;
}
::-webkit-input-placeholder {
  color: #9a9a9a;
  opacity: 1;
}
::-moz-placeholder {
  opacity: 1;
  color: #9a9a9a;
}
:-ms-input-placeholder {
  color: #9a9a9a;
  opacity: 1;
}

input[type="radio"] {
  margin: 10px 0 0;
}

#slider_part .stroke_btn:visited {
  color: #fff !important;
}
#slider_part .stroke_btn:hover {
  background: #11a0f1;
}

/* navigation */
.heading.header_btn .blue_btn {
  margin-left: 20px;
  height: 44px;
}
.header_btn {
  display: flex;
  justify-content: space-between;
}
.menu_items img {
  width: 23px;
}
.menu_items {
  display: flex !important;
  justify-content: right;
  width: 77%;
}
.menu_items>li>a:focus, .menu_items>li>a:hover {
  background-color: transparent;
}
header .navbar-header {
  padding-left: 0;
  padding-right: 0;
}
a.dropdown-toggle.toggle-icon {
  padding: 0 9px;
}
a.dropdown-toggle.toggle-icon svg {
  fill: #fff;
  font-size: 26px;
  margin: 0;
  padding: 0;
}
header .flex_bx {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 85%;
}

/* make my film page */
.modal{z-index: 999;}
.no_padding{padding: 0;}
.table_info_bx, .make_film_header {
  position: relative;
  z-index: 2;
}
.form_light_bg {
  background: rgba(255,255,255,0.10);
  border-radius: 8px;
  margin: 0 0 20px;
  position: relative;
  z-index: 4;
}

.form_light_bg .form-control::-webkit-input-placeholder {
  color: #fff;
}
.form_light_bg .form-control::-moz-placeholder {
  color: #fff;
}
.form_light_bg .form-control:-ms-input-placeholder {
  color: #fff;
}
.form_light_bg .css-1wa3eu0-placeholder {
  color: #fff;
}
.form_light_bg .css-2b097c-container div svg {
  color: #b9bec6 !important;
  width: 17px;
}
.form_light_bg .css-1uccc91-singleValue, .form_light_bg .css-b8ldur-Input{color: #fff;}
.css-yk16xz-control:focus {
  box-shadow: none !important;
}
.css-yk16xz-control{min-height: 44px !important;}
.form_light_bg .css-1okebmr-indicatorSeparator{display:none;}
.form_light_bg .css-2b097c-container div{background:transparent;  border:none;}

.css-2b097c-container{font-size: 13px; height: 44px !important; }
#make_film .css-2b097c-container{ z-index: 9;}
.form_light_bg .css-2b097c-container div:nth-child(3){background: #fff;}
.form_light_bg input[type="text"]{border-radius:0; color:#fff; background:transparent; border:none; border-left: 1px solid rgba(255,255,255,0.15); }
.form_light_bg .form-group{margin:0;}
.form_light_bg .form-group:first-child input[type="text"] {
  border-left: none;
  border-right: 1px solid rgba(255,255,255,0.15);
}
.bg_img {
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}
#track_page_header.make_film_header{overflow: visible;}
#make_film{padding:5px 0 50px; position: relative;}
.faded_bg{ background: #e8eeff;}
.default_bg{position: relative; padding: 24px 0; background:#f9f9f9;}
.film_cat_menu {
  display: inline-block;
}
.film_cat_menu .dropdown-submenu {
  position: relative;
}
#make_film .people_table tr td h5 {
  margin: 11px 0 3px;
  font-size: 15px;
  font-weight: 600;
}
.film_cat_menu .dropdown-submenu>.dropdown-menu {
  top: -6rem;
  right: 102%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 5px;
  width: 350px;
  border: none;
}
.film_cat_menu .dropdown-submenu>.dropdown-menu li:last-child {
  border: none;
}
.film_cat_menu .dropdown-submenu:hover>.dropdown-menu {
  display: block;
}
.film_cat_menu .dropdown-submenu>.dropdown-menu li a, #job_cat ul li a {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #343434;
}
.film_cat_menu .dropdown-submenu ul {
  max-height: 277px;
  overflow: auto;
}

.film_cat_menu .dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}


.film_cat_menu .dropdown-submenu>.dropdown-menu li a {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 8px 0 0;
}
.film_cat_menu .dropdown-submenu>.dropdown-menu li:hover {
  background: #f5f5f5;
}
.film_cat_menu .dropdown-submenu>.dropdown-menu li {
  border-bottom: 1px solid #eee;
  padding: 1px 15px;
  display: flex;
  align-items: center;
}
.film_cat_menu .dropdown-submenu .input_field {
  padding: 9px 15px 14px;
  border-bottom: 1px solid #eee;
}
.film_cat_menu .add_icon.selected_icon, #job_cat .add_icon.selected_icon{
  color: #fff;
  background: #1683f3;
  border-color: #1683f3;
}
.film_cat_menu .dropdown-submenu>.dropdown-menu li small{color: #333;}

.film_cat_menu .add_icon, #job_cat .add_icon{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 24px;
  font-size: 10px;
  color: #b4b4b4;
}
.film_cat_menu .multi-level {
  right: auto;
  width: 350px;
  top: 98%;
  border: none;
  height: 350px;
  overflow: auto;
}
.film_cat_menu .multi-level:before {
  content: '';
  position: absolute;
  top: -7px;
  left: 20px;
  border-left: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-right: 6px solid transparent;
}
.film_cat_menu .multi-level li a {
  padding: 6px 15px;
  font-size: 13px;
}
.list_info h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}
.film_cat_menu .rating .star svg, #job_cat .rating .star svg{
  font-size: 9px;
}
.film_cat_menu  .rating {
  margin: 0;
}
.small_profile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 11px;
}
.small_profile, .list_info {
  float: left;
}
#make_film .shadow_bg{margin:0;}
.user_cat_info {
  width: 360px;
  white-space: nowrap;
}
#make_film .people_table tr td small {
  font-size: 13px;
  color: #575757;
}
#make_film .people_table tr td .stroke_btn svg {
  vertical-align: middle;
}
#make_film .people_table tr td .stroke_btn {
  font-size: 13px;
  padding: 5px 15px;
}
.people_table .user_cat_info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
#make_film .people_table tr td {
  border: none;
  padding: 8px 0 !important;
  border-bottom: 1px solid #eee;
}
#make_film .people_table tr td .delete_icon {
  color: #b6b7b8;
  margin: 5px 0 0;
  font-size: 16px;
}
#make_film .people_table tr td .invite_icon svg {
  color: #025dc3;
  margin-right: 6px;
  vertical-align: top;
  margin-top: 3px;
}
#make_film .people_table tr:hover .user_cat_info h5 {
  color:#025dc3;}
#make_film .people_table .table_label {
  background: #f4f7fa;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
}

#make_film .people_table .table_label td {
  padding: 7px 15px !important;
}
.table_head h5{font-weight: 700; margin: 0 0 20px;}

#make_film .table{margin:0;}
#make_film .people_table tfoot tr td {
  padding-bottom: 0 !important;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 28px !important;
}
#make_film .more_list {
  border-top: 1px solid #e1e1e1;
  padding: 20px 0 0;
  margin: 5px 0 0;
  text-transform: uppercase;
  font-weight: 500;
}

.make_film_header .form-group {
  color:#333;
}
.css-2613qy-menu{ background: #fff !important;}
.film_cat_menu .blue_btn {
  width: 100%;
  padding: 11px 15px;
  text-transform: none;
  letter-spacing: 0;
  background: transparent;
  font-size: 14px;
  border-left: 1px solid rgba(255,255,255,0.15);
  border-radius: 0;
}
.film_cat_menu .blue_btn svg {
  float: right;
  margin: 4px 0 0;
}

.all_contact_link {
  margin: 30px 0 0;
}
#track_page_header.make_film_header .top_left_info h2 {
  margin: 0 0 8px;
}
#track_page_header.make_film_header .top_left_info {
  padding-top: 0;
}
.top_left_info .form-group {
  margin: 22px 0 0;
  font-size: 14px;
}
#view_more_list .modal-body {
  height: auto;
  overflow: auto;
}
#invite_people .modal-body {
  font-size: 16px;
}
#invite_people .application_btn {
  margin: 40px 0 20px;
}
#invite_people .icon_bx {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #c0c6ca;
  font-size: 30px;
  color: #c0c6ca;
  line-height: 70px;
  margin: 20px auto 20px;
}
.make_film_header{margin:20px 0 0;}
.all_contact_link .blue_btn {
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 16px;
}
.head_right_part .blue_btn {
  margin-left: 15px;
}
#make_film .people_table .table_label small {
  font-size: 13px;
  text-transform: none;
  font-weight: 500;
  color: #327ac6;
}
#job_cat ul li {
  display: block;
  width: 100%;
  padding: 9px 0;
  border-top: 1px solid #ddd;
}
#job_cat .cat_info {
  display: flex; align-items: center;
}
#job_cat .small_profile, #job_cat .list_info{float: none; text-align: left;}
#job_cat ul li .rating .star {margin:0; display: inline-flex;}
#job_cat ul li .small_profile img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
#job_cat .list_info h6{font-size: 14px;}
#job_cat .input_field {
  margin: 0 0 20px;
}
#job_cat ul {
  height: 380px;
  overflow: auto;
}


/* create profile page */
.create_profile_page {
  background: url("../images/create_profile_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.create_profile_page:after {
  content: '';
  background: url("../images/bg_side_img.png");
  position: fixed;
  left: 29px;
  width: 100%;
  height: 65%;
  background-repeat: no-repeat;
  background-size: 24%;
  bottom: 0;
}
#login.create_profile_page .register_type{height: auto;}
#login.create_profile_page .setup-content{height:480px; overflow: auto; padding-bottom: 20px;}
.create_profile_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  font-weight: 600;
  font-size: 20px;
}
.avatar-wrapper .profile-pic {
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: inherit;
  background: #fff;
  border: 1px solid #ddd;
  margin-right: 25px;
  object-fit: cover;
}
.avatar-wrapper input{display: none;}
.avatar-wrapper {
  display: flex;
  align-items: center;
  width: max-content;
}
.avatar-wrapper:hover {
  cursor: pointer;
}
.avatar-wrapper label .upload-button svg {
  margin-right: 9px;
}
.avatar-wrapper label .upload-button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.avatar-wrapper .profile-pic:after {
  font-family: FontAwesome;
  content: "";
  top: 67px;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 112px;
  background: #fff;
  color: #b6cbde;
  text-align: center;
}
.create_profile_head a svg {
  vertical-align: middle;
}
#login.create_profile_page .panel-default {
  background: transparent;
  margin: 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #cfcfcf;
  border-radius: 0;
}
#login.create_profile_page .panel-default .panel-title, #login.create_profile_page .panel-default .panel-title a {
  color: #2769a1 !important; font-weight: 600;
}

#login.create_profile_page .panel-default>.panel-heading {
    color: #333;
    background-color: transparent;
    padding: 15px 0;
}
.double_field .center_label {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 10px 0 0;
  z-index: -1;
}
.double_field input {
  width: 48%;
  float: left;
  margin: 0 1%;
  position: relative;
  z-index: 0;
}
#terms .modal-dialog h6 {
  font-weight: 600;
  font-size: 16px;
  color: #163355;
}
#terms .modal-dialog {
  width: 700px;
  font-size: 13px;
  line-height: 22px;
}

/* range slider */
.range-slider span {
  font-weight: 600;
  margin: 4px 0 0;
  display: inline-block;
}

/* new css */
#change_term .application_btn {
  margin-left: 0;
}
#change_term .application_btn .blue_btn {
  margin-right: 12px;
}
#change_term .terms_bx ul li label {
  width: 50%;
  font-weight: 400;
  padding-right: 20px;
}
.terms_part .stroke_btn {
  margin: 10px 0 0;
}
.job_list.skills_part h6 {
  margin: 0 0 10px;
}
.message_part h2 {
  font-size: 20px;
}
.message_part h1 {
  font-size: 18px;
  margin: 0 0 20px;
}
.upload_resume_field img {
  max-width: 34px;
  height: auto !important;
  margin-left: 5px;
}
.tab-content a.default_btn:hover, .tab-content a.default_btn:active{
  color: #fff !important;
}
.user_side_area .people_center_info, .user_side_area .side_details {
  z-index: 0;
}
.wallet_page .table thead th {
  white-space: nowrap;
  font-size: 13px;
  padding: 15px 0 15px 25px;
  font-weight: 600;
}
.wallet_page .table tbody tr td {
  font-size: 14px;
  padding: 15px 0 15px 25px;
}
.wallet_page .dropdown .dropdown-item button svg {
  margin-right: 8px;
  margin-top: 5px;
}
.wallet_page .dropdown .dropdown-item button {
  font-size: 12px;
  text-align: left;
  display: flex;
  line-height: 15px;
  background: transparent;
  border: none;
  color: #767676;
  border-bottom: 1px solid #ddd;
}
.wallet_page .dropdown .dropdown-item button:hover, .wallet_page .dropdown .dropdown-item button:active {
  color: #337ab7;
}
.wallet_page .dropdown .dropdown-item:last-child button {
  border: none;
}
.wallet_page .btn-primary{
padding: 4px 12px;
font-size: 13px;
}
.pagination .page-link {
  color: #333 !important;
  opacity: 1;
}
.pagination .disable span {
  opacity: 0.8;
}
.pagination .page-link:hover {
  color: #fff ;
  opacity: 1 !important;
  cursor: pointer;
}
.small_para .tab-pane p {
  font-size: 14px;
  line-height: 23px;
}
label:empty {
  display: none;
}
.css-1hwfws3 {
  overflow-y: auto !important;
  height: 42px !important;
}
ul.notes li {
  list-style: inside disc;
  padding-left: 0;
}
ul.notes {
  font-size: 13px;
  padding-top: 7px;
  color: #656b70;
}
.days_list .check_bx {
  display: inline-block;
  margin-right: 15px;
  font-size: 13px;
  padding-left: 21px;
}
.people_detail_banner .dark_bg p {
  margin: 0 0 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #3a3a3a;
}
.people_detail_banner .dark_bg svg{color: #44a0ff;}
.people_detail_banner .dark_bg p:last-child {
  margin: 0;
}
.people_detail_banner .dark_bg hr {
  border-color: #373d55;
  margin: 15px 0;
}
.pagination>li>a, .pagination>li>span {
  position: relative;
  float: none;
  padding: 0;
  margin: 0 4px;
  line-height: 1.42857143;
  color: #939393;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #eee;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  line-height: 27px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
  z-index: 2;
  color: #ffffff;
  background-color: #c62537;
  border-color: #c62537;
}
.pagination>li>a:hover, .pagination>li>span:hover {color:#333 ; background: transparent;}
.default_bg .stroke_btn{border: 1px solid #2cb1ff; color: #2cb1ff;}
strong {
  font-weight: 600;
}
.modal-backdrop {
  z-index: 9;
  opacity: 0.85;
}
#track_page_tab .form-group {
  font-size: 12.5px;
  line-height: 20px;
}
.inner_head p {
  margin: 12px 0 0;
  color: #fff;
  font-weight: 300;
}
.inner_head h2 {
  color: #fff;
  font-size: 28px;
}
.pagination>li>a svg {
  vertical-align: middle;
}
/* .side_space{padding:0 60px;} */
.css-g1d714-ValueContainer {
  height: 42px !important;
  overflow-y: auto !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-left: 9px;
}
.slider_check input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input[type="radio"] {
  margin: 0 0 0 5px;
  z-index: 99;
  position: relative;
  opacity: 0;
}
.slider_check {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider_check:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}
input:checked + .slider_check {
  background-color: #2196F3;
}

input:focus + .slider_check {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider_check:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider_check.round {
  border-radius: 34px;
}

.slider_check.round:before {
  border-radius: 50%;
}
.toggle_input p {
  margin: 0 0 15px;
}
.toggle_input label .switch {
  margin-left: 10px;
}
.toggle_input label {
  margin-right: 30px;
}
.job_list_filter {
  margin: 0 57px 20px;
}

/************ main css ************/

/* nav bar */
.top_head {
  position: relative;
  background: #031837;
}
.search input {
  border: 1px solid #ddd;
  border-radius: 30px;
  width: auto;
  color: #333;
  padding: 7px 35px 7px 16px;
  min-width: 600px;
}
.logo {
  font-size: 30px;
  color: #fff;
  display: block;
  font-family: "Montserrat", "Open Sans", sans-serif;
  height: auto;
  padding: 0 15px;
}
.logo img {
  width: 145px;
  padding: 5px 5px;
}
.logo:hover {
  color: #fff;
}
.logo span {
  font-weight: 600;
  color: #1683f3;
}
.top_nav {
  position: relative;
  margin: 6px 0;
}
.navigation_part {
  background: #111111;
}
.navbar {
  border: none;
  margin: 0;
}
.navbar-nav {
  display: inline-block;
  float: none;
  margin: 0 auto -7px;
}
.navbar-nav > li > a {
  padding: 27px 14px;
}
.navbar-nav > li > .stroke_btn {
  padding: 7px 19px;
  font-weight: 500;
  color: #1683f3;
}
.dropdown-menu {
  left: auto;
  right: 0;
}
.menu_drop > li > a {
  padding: 12px 12px;
}
.navigation_part .nav > li > a:focus,
.navigation_part .nav > li > a:hover,
.navigation_part .nav .open > a,
.navigation_part .nav .open > a:focus,
.navigation_part .nav .open > a:hover {
  background: transparent;
  color: #59abff;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  border-bottom: 3px solid #59abff;
}
.dropdown-menu > li > a {
  white-space: normal;
  padding: 4px 12px 4px 0;
}
.top_nav ul li {
  display: inline-block;
  padding: 0;
  float: none;
  vertical-align: middle;
}
.top_nav ul li a {
  color: #fff;
  font-family: "Montserrat", "Open Sans", sans-serif;
}
.top_nav ul li .dropdown-menu > li > a {
  color: #333;
  padding: 3px 10px;
}
.top_nav .search,
.top_nav .top_side_link {
  display: inline-block;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #337ab7;
  color: #66c0ff;
}
.top_nav ul li a:hover,
.top_nav ul li a:focus {
  color: #24baef;
  background: transparent;
}
.search .btn {
  position: absolute;
  right: 0;
  top: -2px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: none;
  background: transparent;
  color: #333;
  font-size: 17px;
}
.top_nav ul li a.dropdown-toggle img {
  width: 23px;
}
.top_nav .search {
  position: relative;
  margin: 12px 0 0 0;
}
.navigation_part .dropdown-menu {
  min-width: 200px;
}
header .dropdown-menu > li > a:focus,
header .dropdown-menu > li > a:hover {
  color: #59abff !important;
  text-decoration: none;
  background-color: transparent;
}
.mega_links {
  width: 20%;
  float: left;
}
.menu_drop {
  float: left;
  margin: 20px 0 0;
}
.mega_links h5 a {
  color: #222 !important;
}
.mega_links h5 a:hover {
  color: #24baef !important;
}

.mega-dropdown {
  position: static !important;
}
.mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  margin: 12px 0 0 !important;
  box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .mega-dropdown-menu {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.9);
  }

  .warning {
    display: none;
  }
}

.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0 0 16px ;
}
.mega-dropdown-menu > li > ul > li {
  list-style: none;
  display: block;
}
.mega_links:last-child {
  border: none;
}
.mega_links {
  width: 20%;
  float: left !important;
  padding: 10px 15px !important;
  border-right: 1px solid #ddd;
  min-height: 180px;
}
footer .mega_links h5{ color:#fff;}
.mega_links h5 {
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 15px 5px;
 
}
header .mega_links h5 {
  font-weight: 600;
  padding: 0 0 7px 5px;
  color:#327ac6;
}
.mega_links:last-child {
  border: none;
}
.mega-dropdown-menu > li > ul > li > a {
  display: block;
  color: #222;
  padding: 3px 5px;
}
.mega-dropdown-menu > li ul > li > a:hover,
.mega-dropdown-menu > li ul > li > a:focus {
  text-decoration: none;
}
.mega-dropdown-menu .dropdown-header {
  font-size: 18px;
  color: #ff3546;
  padding: 5px 60px 5px 5px;
  line-height: 30px;
}
.top_nav ul li a img {
  display: inline-block;
  width: 20px;
  margin-right: 4px;
  vertical-align: sub;
}
.menu_drop svg {
  fill: #ff3838;
  margin: 0 5px;
}

.tooltip > .tooltip-inner {
  margin-top: -15px;
}
.tooltip.bottom .tooltip-arrow {
  top: -15px;
}
.mobile_view {
  display: none !important;
}
.navbar-nav>li>.dropdown-menu {
  margin-top: 0;
  border-radius: 6px;
}

/* flash news */
#flash_news {
  background: #000;
  overflow: hidden;
}
.wrap {
  width: 87%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0;
  height: 25px;
  color: #fff;
  font-family: "Montserrat", "Open Sans", sans-serif;
  float: left;
}
.jctkr-label {
  height: 25px;
  padding: 7px 17px 10px 0;
  line-height: 11px;
  font-size: 14px;
  color: #000;
  cursor: default;
  background: #eae100;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  display: inline-block;
  width: 12%;
  float: left;
  margin-right: 1%;
}
.jctkr-label:before {
  content: "";
  position: absolute;
  left: -99%;
  top: 0;
  width: 100%;
  height: 100%;
  background: #eae100;
  z-index: 0;
}
.jctkr-label:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 7px solid #eae100;
  border-bottom: 5px solid transparent;
  content: "";
  position: absolute;
  right: -7px;
  top: 8px;
}
[class*="js-conveyor-"] ul {
  display: inline-block;
  opacity: 0.5;
}
[class*="js-conveyor-"] ul li {
  padding: 5px 15px;
  line-height: 16px;
}
.jctkr-wrapper,
.jctkr-wrapper * {
  box-sizing: border-box;
}
.jctkr-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 25px;
  vertical-align: top;
  overflow: hidden;
}
.jctkr-wrapper ul {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.jctkr-wrapper.jctkr-initialized ul {
  opacity: 1;
}
.jctkr-wrapper ul li {
  display: inline-block;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 12px;
}

/* slider section */
#slider_part .banner_text {
  position: absolute;
  left: 5rem;
  top: 0;
  z-index: 9;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
  display: inline-flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}
#slider_part .banner_text h1 {
  color: #fff;
  font-weight: 600;
  line-height: 40px;
  text-shadow: none;
  margin: 0 0 20px;
  font-size: 32px;
}
#slider_part {
  position: relative;
}
#slider_part .carousel-fade .carousel-inner .item {
  transition-property: opacity;
}
#slider_part .carousel-fade .carousel-inner .item,
#slider_part .carousel-fade .carousel-inner .active.left,
#slider_part .carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
#slider_part .carousel-fade .carousel-inner .active,
#slider_part .carousel-fade .carousel-inner .next.left,
#slider_part .carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
#slider_part .carousel-fade .carousel-inner .next,
#slider_part .carousel-fade .carousel-inner .prev,
#slider_part .carousel-fade .carousel-inner .active.left,
#slider_part .carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
#slider_part .carousel-fade .carousel-control {
  z-index: 99;
  width: 35px;
  height: 35px;
  background: #000;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  top: auto;
}
#slider_part .carousel-fade .carousel-control.left {
  left: -45px;
}
#slider_part .carousel-fade .carousel-control.right {
  right: -45px;
}
#slider_part .carousel,
#slider_part .carousel-inner,
#slider_part .carousel-inner .item {
  height: 400px;
}
#slider_part .carousel-inner .item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
#slider_part .shadow {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  top: 0;
  z-index: 9;
}

/* top banner section */
#top_banner {
  padding: 50px 0;
  background: #06101e;
}
#top_banner h2 {
  color: #fff;
}
.gallery_info {
  margin: 0 6px;
  background: #0d1b2e;
  border-radius: 6px;
  overflow: hidden;
}
.gallery_info img {
  height: 240px;
  object-fit: cover;
  width: 100%;
  opacity: 0.9;
}
.gallery_info h5 {
  color: #ffffff;
  margin: 2px 0;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  padding: 0 0 3px;
}
.inside_info p {
  font-size: 13px;
  color: #bbb;
  font-weight: 400;
}
.gallery_info p span {
  float: right;
  font-weight: 600;
}
.user_info p {
  font-style: italic;
}
.user_info h5 {
  font-weight: 600;
  color: #f7bb0c;
}
#top_banner .owl-buttons {
  position: absolute;
  top: 85px;
  width: 100%;
}
#top_banner .owl-next {
  right: 0;
  position: absolute;
}
#top_banner .owl-prev {
  left: -0;
  position: absolute;
}
#top_banner .owl-controls .owl-buttons div {
  background: rgba(0, 0, 0, 0.5);
  color: #d7d7d7;
  display: inline-block;
  margin: 29px 5px;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
}
#top_banner .owl-carousel .owl-wrapper-outer {
  padding-bottom: 10px;
}
.gallery_info small {
  color: #ef2673;
  margin-top: 8px;
  display: block;
}
.inside_info {
  padding: 14px 17px;
  font-size: 12px;
  color: #d8d9db;
}
.movie_bg {
  position: absolute;
  opacity: 0.1;
  object-fit: cover;
  height: 440px;
}
#top_banner .nav-tabs > li > a {
  margin-right: 0;
  line-height: 1.42857143;
  border-bottom: none;
  font-size: 14px;
  color: #949cb0;
  font-weight: 600;
}
#top_banner .nav-pills > li.active > a,
#top_banner .nav-pills > li.active > a:focus,
#top_banner .nav-pills > li.active > a:hover {
  color: #24baef;
  background-color: transparent;
  border: none;
  margin: 0;
  border-radius: 0;
}
#top_banner .nav-tabs > li > a:hover {
  border-color: transparent;
  background: transparent;
  border-bottom: none;
  color: #24baef;
}
#top_banner .nav-pills > li {
  float: none;
  display: inline-block;
}
#top_banner .nav-tabs {
  border: none;
}
.gallery_info p,
.gallery_info h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* news gallery section */
#news_gallery {
  padding: 50px 0 40px;
}
.big_img img {
  height: 400px;
  object-fit: cover;
}
.small_img img {
  height: 160px;
  width: 100%;
  object-fit: cover;
}
#news_gallery .small_img {
  margin-bottom: 20px;
}
#news_gallery .nav-tabs li,
#movie_status .nav-tabs li {
  display: inline-block;
}
#news_gallery .nav-tabs li a,
#movie_status .nav-tabs li a {
  background: transparent;
  color: #9b9b9b;
  border: none;
  font-weight: 600;
  font-size: 14px;
}
#news_gallery .nav-tabs li a:hover,
#news_gallery .nav-tabs li.active a,
#news_gallery .nav-tabs li a:focus,
#news_gallery a h5:hover,
.gallery_info h5:hover {
  color: #24baef;
}
#news_gallery h2,
#top_banner h2,
#movie_status h2 {
  flex: none;
  margin: 0 0 35px;
  position: relative;
  overflow: unset;
}
#news_gallery .tab-content {
  padding: 30px 15px 0;
}
#top_banner .tab-content {
  padding: 30px 0 0;
}
#news_gallery .nav-tabs,
#movie_status .nav-tabs {
  margin: 0 0 4px;
  border: none;
  display: inline-block;
  float: none;
}
#news_gallery small {
  font-size: 12px;
  display: block;
  margin: 0 0 10px;
}
#news_gallery h5 {
  font-size: 15px;
  font-weight: 700;
  margin: 10px 0 0;
  font-family: "Open Sans";
}
#news_gallery .hover_img img,
.movie_img img {
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
#news_gallery .hover_img,
.movie_img,
#movie_status .side_movie_img {
  overflow: hidden;
  position: relative;
  border-radius: 9px;
  background: #000;
}
#news_gallery .hover_img:hover img,
.movie_img:hover img,
#movie_status .side_movie_img:hover img {
  transform: scale(1.1);
  opacity: 1;
}
#news_gallery .hover_img:hover:after,
.movie_img:hover:after,
#movie_status .side_movie_img:hover:after {
  content: "";
  background: rgba(78, 154, 248, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
#news_gallery .blue_btn {
  background: #fff;
  border: 1px solid #1683f3;
  color: #1683f3 !important;
  margin: 25px 0 10px;
}
#news_gallery .blue_btn:hover,
#news_gallery .blue_btn:focus {
  background: #1683f3;
  color: #fff !important;
}
#news_gallery h2:after,
#movie_status h2:after,
#top_banner h2:after {
  position: absolute;
  content: none;
  height: 1px;
  background-color: #e8e8e8;
  width: 100%;
  top: 50%;
  margin-left: 15px;
}
#news_gallery .bottom_btn {
  position: relative;
}
#news_gallery .bottom_btn:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  top: 44px;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #e8e8e8;
}

/* news part */
#news_part {
  background: #f3f5f8;
  padding: 30px 0 20px;
}
.news_text .blue_btn {
  margin-top: 13px;
  padding: 3px 15px;
  font-size: 12px !important;
  display: inline-block !important;
}
.news_text h4 {
  margin: 0 0 10px;
  font-size: 18px;
  text-transform: none;
  font-weight: 600;
}
.category,
.trend_post {
  background: #fff;
  border: 1px solid #ddd;
}
.category ul {
  padding: 0 15px 10px;
}
.category ul li {
  padding: 9px 0 9px 20px;
  border-bottom: 1px dashed #ddd;
  position: relative;
  font-size: 14px;
  line-height: 21px;
}
.news_img img {
  height: 214px;
  width: 100%;
  object-fit: cover;
}
.category ul li:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  top: 20px;
  background: #1683f3;
  left: 0;
}
.category ul li a,
.trend_post ul li a {
  color: #337ab7;
}
.category ul li a:hover {
  text-decoration: underline;
}

/* make a film section */
#make_film_section img {
  position: absolute;
  right: -30px;
  top: -16rem;
  max-width: 80rem;
}
#make_film_section {
  height: auto;
  background: linear-gradient(20deg, #06101e 50%, #dc1838 42%);
  position: relative;
  overflow: hidden;
}
.make_film_info {
  position: relative;
  bottom: 0;
  color: #fff;
}
.make_film_info h2 {
  color: #fff;
  font-weight: 600;
  margin: 0 0 20px;
}
.make_film_info p {
  font-size: 20px;
}
.film_cate_part span {
  display: inline-block;
  background: #161f2c;
  margin: 5px;
  border-radius: 50px;
  font-size: 13px;
  padding: 3px 15px;
  color: #e1e1e1;
  border: none;
}
.film_cate_part {
  margin: 40px 0;
}

/* home page last section */
#movie_status {
  padding: 50px 0 40px;
  background: #06101e;
  color: #949cb0;
}

#last_section {
  padding: 30px 0 40px;
}
.trend_post ul {
  padding: 0 11px;
}
.trendy_txt {
  display: flex;
  margin: 0 0 30px;
}
#movie_status .trendy_txt > img {
  margin-right: 13px;
  height: 100px;
  width: 70px;
  object-fit: cover;
}
#movie_status h2 {
  color: #fff;
  margin: 0 0 20px;
}
#movie_status .main_head {
  margin: 0 0 5px;
}
.mid_area {
  display: inline-flex;
  align-items: center;
  margin:15px 0 30px;
}
#movie_status .mid_area{margin:0 0 30px;}
#movie_status .side_movie_img img {
  width: 200px;
  height: 209px;
  object-fit: cover;
  max-width: none;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  border-radius: 7px;
}
#movie_status .full_movie_detail {
  margin-left: 30px;
}
.trendy_txt small em,
.mid_area small em {
  color: #9b9b9b;
}
.trendy_txt small span {
  font-weight: 700;
  color: #24baef;
}
.mid_area h6 {
  font-size: 21px;
  margin:10px 0 11px;
  font-weight: 600;
  font-family: "Montserrat", "Open Sans", sans-serif;
  color: #fff;
}
.mid_area .full_movie_detail .blue_btn {
  margin: 25px 0 0;
}
.main_head {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.trend_post ul li small {
  display: block;
  line-height: 18px;
}
.trend_post ul li small span {
  color: #1683f3;
}
#movie_status .trendy_txt h6 {
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat", "Open Sans", sans-serif;
  color: #fff;
  line-height: 23px;
  margin: 0 0 15px;
}
#movie_status .trendy_txt {
  display: flex;
  margin: 0 0 15px;
  align-items: center;
  border-bottom: 1px solid #37373a;
  padding: 0 0 15px;
}

#movie_status h2:after,
#top_banner h2:after {
  background-color: #575761;
}

#movie_status .nav-tabs li a:hover,
#movie_status .nav-tabs li.active a,
#movie_status .nav-tabs li a:focus,
#movie_status a h5:hover,
.gallery_info h5:hover {
  color: #24baef;
}

.outer_bx {
  border: 1px solid #ddd;
  padding: 15px;
  margin: 15px 0;
  background: #fff;
}
.category h5,
.trend_post h5 {
  background: #202020;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  text-align: center;
}
.category ul li {
  padding: 12px 0 12px 20px;
  border-bottom: 1px dashed #ddd;
  position: relative;
}
.trend_post ul li {
  padding: 12px 0;
  border-bottom: 1px dashed #ddd;
  position: relative;
}
.category ul li:last-child {
  border: none;
}
.trend_post ul li:last-child {
  border: none;
  text-align: center;
  background: #ffa22b;
  margin: 0 -12px;
}
.trend_post ul li:last-child a {
  color: #fff;
}

/* auditions */
#auditions {
  padding: 5px 0 55px;
  background: #06101e;
  position: relative;
}
#auditions .event_cal {
  padding: 0 20px;
}
.audition_head {
  background: url("/images/audtion_head_banner.jpg");
  padding: 70px 40px 35px;
  background-position: 0 bottom;
  margin: 0 -40px 15px;
}
.audition_head h2 {
  position: relative;
  color: #fff;
}
.head_img {
  position: absolute;
  left: 0;
  width: 130px;
  top: 0;
  z-index: 9;
}
.event_bg {
  background: #04234f;
  padding: 0 25px;
  border-radius: 6px;
  margin: 0 0 0;
  overflow: hidden;
}
#movie_status .stroke_btn {
  margin-top: 15px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
#movie_status .stroke_btn:hover, #movie_status .stroke_btn:active, #auditions .stroke_btn:hover, #auditions .stroke_btn:active{
  background: #0956a6;
  color: #fff !important;
}
.event_cal ul li {
  display: flex;
  padding: 8px 0;
  align-items: center;
}
.event_cal ul li .date span {
  font-size: 30px;
}
.event_cal ul li .date {
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  width: 65px;
  background: #dd1141;
  height: 60px;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0 0;
}
.event_cal ul li .event_detail {
  margin-left: 20px;
  width: 80%;
}
.event_cal ul li .event_detail h4 {
  font-weight: 500;
  margin: 0 0 6px;
  font-size: 17px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.event_cal ul li .event_detail small {
  display: block;
  font-size: 13px;
  line-height: 20px;
  color: #dde5ee;
}
.event_cal .btn_part {
  margin: 13px -40px 0;
  text-align: center;
  background: #0956a6;
  padding: 14px 20px;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  letter-spacing: 0;
}
.event_cal .btn_part a {
  text-transform: uppercase;
  color: #fff !important;
  font-size: 15px;
  letter-spacing: 1px;
}
.event_cal .btn_part:hover {
  background: #2691ff;
}

.need_work {
  padding: 0 3rem;
}
.work_icon {
  width: 25%;
  height: 130px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  max-width: 130px;
  min-width: 130px;
  position: relative;
  z-index: 1;
}
.work_bx h4 {
  color: #fff;
  margin: 25px 0 8px;
  font-size: 22px;
}
.work_bx {
  color: #eee;
  font-weight: 300;
  font-size: 14px;
  margin: 0 0 20px;
  display: inline-flex;
}
.work_bx:last-child {
  margin: 0;
}
.work_head h2 {
  color: #fff;
  margin: 25px 0;
}

.work_info span {
  background: #dc1838;
  display: inline-block;
  width: 88px;
  text-align: center;
  border-radius: 6px;
  padding: 1px 5px;
  font-weight: 400;
  color: #fff;
  position: absolute;
  left: -7px;
  top: -14px;
}
.work_info {
  padding: 0 0 0 16px;
  margin: 20px 0 10px;
  position: relative;
  width: 75%;
}
.work_icon img {
  width: 70px;
  margin: 28px auto 0;
}

/** footer part **/
footer {
  background: #222;
  font-family: "Montserrat", "Open Sans", sans-serif;
  padding:30px 0 35px;
  color: #fff;
}
.footer-menu li a {
  color: #d3d3d3;
  padding: 3px 0;
  display: block;
}
.footer-menu li a:hover{color:#f21c65;}
.top_links {
  border-bottom: 1px solid #3f4467;
  margin: 0 0 35px;
}
.top_links ul li {
  display: inline-block;
  padding: 30px 40px 25px;
}
.footer_links {
  margin: 25px 0 0;
}
.footer_info_part h3 {
  color: #fff;
  margin: 0 0 15px;
  font-weight: 300;
  font-size: 28px;
}
.footer_info_part {
  color: #bec0cd;
}

.footer_info_part .stroke_btn {
  margin: 20px 0 0;
  color:#fff;
  border-color: #fff;
}
.footer_links ul li,
.copyright ul li {
  display: inline-block;
  padding-right: 26px;
}
footer h6 {
  color: #fff;
  font-weight: 700;
}
footer img {
  margin: 0 auto 10px;
  max-width: 170px;
}
.footer_links ul li a,
.top_links ul li a {
  color: #fff;
}
.social_icons ul li a {
  color: #bec0cd;
  font-size: 20px;
}
.social_icons ul li a:hover {
  color: #fff;
}
.copyright ul li a {
  color: #bec0cd;
  font-size: 12px;
}
.social_icons ul li {
  display: inline-block;
  padding: 1px 16px 0 0;
}
.footer_links ul li:hover a {
  color: #59abff;
}
.copyright {
  color: #949cb0;
  margin: 25px 15px 0;
  padding: 0;
  font-size: 13px;
}
footer .logo a {
  margin: 10px 0 20px;
  color: #fff;
}

.footer_links ul {
  display: inline-block;
  vertical-align: top;
}
.footer_links ul li:hover {
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  color: #ef2673;
}

/*************** inner pages *****************/

/* login page */

#login {
  position: relative;
 height: 100vh;
  top: auto;
  bottom: auto;
  align-items: center;
  display: flex;
  width: 100%;
}
.social_login .google_login {
  background: #EA4335;
}
.other_option span {
  display: inline-block;
  width: 26px;
  height: 26px;
  background: #e1e4e5;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  line-height: 26px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(0,0,0,0.15);
}
.other_option:after {
  border-top: 1px solid rgba(0,0,0,0.1);
  content: '';
  position: absolute;
  top: 11px;
  left: 0;
  width: 100%;
}
.other_option {
  text-align: center;
  position: relative;
  margin: 17px 0 0;
}
.social_login a {
  width: 31.3%;
  float: left;
  padding:8px 10px;
  text-align: center;
  margin: 25px 1% 20px;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
}
.social_login .facebook_login {
  background: #3b5998;
}
.social_login {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#login .container-fluid {
  width: 100%;
}
.btn_action_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#login .logo {
  font-size: 30px;
  color: #fff;
  display: block;
  font-family: "Montserrat", "Open Sans", sans-serif;
  height: auto;
  padding: 20px 27px 0;
}
#login .logo img {
  width: 180px;
}
#login .form-control {
  height: 44px;
  box-shadow: none;
}
.login_footer {
  padding: 20px 0 25px;
}
#login h4 {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 10px;
}
#login .form_head_part p {
  margin: 0 0 40px;
}
.register_info h4 {
  padding: 0 0 25px;
}
.otp_field label {
  display: block;
}
.otp_field .form-control {
  width: 50px;
  display: inline-block;
  margin-right: 8px;
  text-align: center;
}

.login_footer .copyright {
  margin: 12px 0 0;
}
.login_footer .social_icons ul li a {
  font-size: 14px;
  color: #72758a;
}

.login_footer .social_icons ul li {
  padding: 10px 16px 0;
}
#login .setup-content,
#login .form-space {
  padding: 20px 30px 0;
  margin: 0 auto;
}
.guest_login {
  margin-left: 10px;
}
.social_login a svg {
  vertical-align: middle;
  margin-right: 7px;
}
.login_form .form-group a {
  font-size: 13px;
}
.login_head a {
  color: #fff;
}
#login .btn {
  margin: 6px 0 10px;
}
#login .bg_box {
  background: rgba(255, 255, 255, 0.85);
  margin: 0 auto;
  padding: 0 0 5px;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  #login .bg_box {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  .warning {
    display: none;
  }
}
#login .stroke_btn {
  border: 1px solid #1683f3;
  background: transparent;
  color: #1683f3;
  padding: 8px 20px;
  margin-left: 0 !important;
}
#login .create_profile_head .stroke_btn { margin: 25px 0 24px !important; padding: 5px 20px;}
#login .upload_resume_field .stroke_btn {
  margin: 10px 0 !important;
  font-size: 13px;
  padding: 4px 15px;
}
#login .inside_label {
  margin: 10px 0 0;
}
.login_footer {
  padding: 20px 0 15px;
  text-align: center;
}
.login_bg {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.register_page .register_info {
  padding: 30px !important;
}

#login .register_type {
  height: 600px;
  overflow: auto;
}

/* casting notice */
[data-toggle="collapse"] {
  cursor: pointer;
}
.inner_head {
  margin: 0 0 40px;
}
.inner_head h2 small {
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  margin: 10px 0 0;
}
.inner_head .right_info {
  float: right;
}
.other_casting_info {
  padding: 14px 20px !important;
  background: #f1f4ff !important;
  margin: 5px 0 30px !important;
}
.other_casting_info p,
.top_left_head {
  display: inline-block;
}
.other_casting_info img {
  float: right;
  max-width: 270px;
}
#all_list,
#inner_form {
  background: #e8eeff;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
#all_list .shadow_bg {
  margin: 0;
}
.filter_points .list-group span {
  float: right;
}
.filter_points h6, .search_part h6 {
  padding: 12px 0 5px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #181818 !important;
}
#all_list .shadow_bg a {
  color: #333;
}
#all_list .shadow_bg a:hover {
  color: #025dc3;
}
.filter_points .list-group {
  margin: 0;
  background:transparent;
  border-radius: 5px;
  padding: 5px 2px 7px;
  box-shadow:none;
}
.filter_points .list-group .list-group-item {
  position: relative;
  display: block;
  padding: 4px 0;
  margin-bottom: 0;
  background-color: transparent;
  border: none;
}
.list-group-item{background: transparent; border: none;}
.filter_points .check_bx {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  line-height: 20px;
  padding-left: 0;
  padding-right: 22px;
}
.side_bar_tab h5 {
  font-size: 14px;
  padding-left: 21px;
  font-weight: 700;
  text-transform: uppercase;
  color: #b4b4b4;
}
.filter_points h6[aria-expanded="true"] .fa {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.filter_points h6 .fa {
  float: right;
}
.filter_points .checkmark {
  position: absolute;
  top: 3px;
  right: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #a5a5a5;
  left: auto;
}
.filter_points .list-group .half_fields input[type="text"] {
  width: 34% !important;
}
.filter_points .list-group .half_fields {
  border-top: 1px solid #efefef;
  margin: 12px 14px 0;
  padding: 17px 0 9px;
}
#filter-sidebar h3 {
  padding: 0 0 15px;
  color: #1c1c1c;
  border-bottom: 1px solid #ebebeb;
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 18px;
}
.casting_bx {
  border: 1px solid #ddd;
  padding: 0 15px 0;
  margin: 10px 0 0;
}
.casting_bx_right {
  display: inline-block;
  float: right;
  text-align: right;
}
.casting_bx_left {
  display: inline-block;
}
.casting_bx_left strong,
.casting_bx_right strong,
.casting_bx_right small {
  display: block;
}
.casting_bx ul li h6 {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}
.casting_bx ul li:first-child {
  border: none;
}
.casting_bx ul li .blue_btn {
  float: right;
  padding: 3px 15px;
  font-size: 14px;
}
.casting_bx ul li {
  border-top: 1px dashed #ced5dd;
  padding: 8px 15px 5px;
  background: #f3f7fe;
  margin: 0 -15px;
}
.casting_bx_left h4 {
  color: #1683f3;
  margin: 0 0 5px;
}
.filter_fields .form-group,
.fiter_option h6 {
  display: inline-block;
}
.filter_fields {
  float: right;
}
.filter_fields select {
  width: auto;
  display: inline-block;
  margin: 0 10px;
}

.filter_points .list-group .list-group-item label {
  font-weight: 400;
  color: #323232;
}
.filter_points .list-group .list-group-item .stroke_btn {
  padding: 5px 10px;
  vertical-align: top;
  font-size: 14px;
}
.filter_points .list-group .list-group-item input[type="text"] {
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  font-size: 13px;
  border: 1px solid #ddd;
}
.filter_points .list-group .double_input input[type="text"] {
  width: 36%;
}

/* people page */
.people_table img {
  width: 94px;
  display: inline-block;
  float: left;
  height: 98px;
  object-fit: cover;
  margin-right: 20px;
}
.social_meter_info ul li {
  display: inline-block;
  margin-right: 3px;
}
.social_meter_info ul li a {
  width: 25px;
  height: 25px;
  background: #ebf1f3;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  color: #7e7e7e;
  font-size: 12px;
  display: inline-block;
}
.people_table tr td {
  vertical-align: middle !important;
  border-bottom: 1px dashed #bdd7e9;
  border-top: none !important;
  padding: 15px 0 !important;
}

.people_table tr td h5 {
  margin: 17px 0 5px;
  font-size: 18px;
}
.people_table thead tr th {
  text-align: left;
  font-size: 13px;
  text-transform: uppercase;
  padding: 10px 0;
}
.inner_head a {
  color: #337ab7;
}
.green_clr {
  color: #12c639;
}
.red_clr {
  color: red;
}
.parent-collapsed {
  display: none;
}

/* your page */

.add_primary_img input[type="file"] {
  display: none;
}
.add_primary_img label {
  cursor: pointer;
  color: #337ab7;
}
.add_primary_img .fa {
  font-size: 56px;
  display: block;
  margin: 0 0 10px;
  color: #194d89;
}
.bottom_desc .inside_img a {
  color: #025dc3;
}
.your_page_popup ul li {
  width: 100% !important;
}
.people_center_info .blue_btn {
  text-align: center;
  margin: 10px 5px 10px 0;
  display: inline-block;
}
.your_page .add_primary_img {
  height: 340px;
  background: #cdd7e3;
  text-align: center;
  padding: 12rem 0 0;
}
.add_list .dropdown a {
  border-radius: 3px;
  width: 100%;
  border: 1px solid #fff;
  padding: 6px 15px;
  display: block;
  color: #fff;
  vertical-align: top;
}
.add_list .dropdown {
  display: inline-block;
  min-width: 200px;
}
#people_other_info .resume_data .table tr td {
  padding: 9px 10px;
  font-size: 13px;
}
.resume_data h6 strong {
  font-size: 18px;
  font-weight: 600;
}
.resume_data h6 strong .fa {
  float: right;
}
.add_list .dropdown a .caret {
  float: right;
  margin: 10px 0 0;
}
.add_list .dropdown a .fa {
  margin-right: 5px;
}
.add_list .dropdown-menu li a {
  color: #333;
  border: none;
  padding: 5px 15px;
}
.add_list .dropdown-menu {
  width: 100%;
  border-radius: 0;
  margin: 0;
}
.people_header h3 {
  display: inline-block;
  color: #3a3a3a;
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0 0;
}

.people_header {
    line-height: 21px;
    font-weight: 300;
    background: #fff;
    padding: 20px 20px 0;
    border-radius: 0;
    margin: 15px 0;
    border: 1px solid #e7e7e7;
}
.people_header p{color:#3a3a3a; font-weight: 400;}
.people_header small {
  display: block;
  color: #3a3a3a;
  font-weight: 400;
  font-size: 14px;
}
.inside_img {
  height: 100px;
  width: 100px;
  display: inline-block;
  padding: 2.5rem 0 0;
  background: #eef0f3;
  text-align: center;
  border: 1px solid #ddd;
  position: relative;
  z-index: 9;
  color: #ccc;
}
.inside_img .fa {
  display: block;
  margin: 0 0 5px;
  font-size: 20px;
  color: #aeadc4;
}
.main_text_area h6 a {
  color: #5199d7;
  font-size: 14px;
  margin-left: 10px;
}
.trending_info {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.people_contact h6 {
  background: transparent;
  margin: 0 -20px 4px;
  padding: 20px 20px 5px;
  font-size: 13px;
  font-weight: 500;
  border-top: 1px solid #f1f1f1;
  color: #3a3a3a;
}
.profile_info_bx p {
  font-size: 14px;
}
.company_info_page .add_primary_img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.people_contact h6 a {
  float: right;
  font-size: 15px;
  color: #76a6ff;
}
.people_contact_edit .blue_btn {
  margin: 20px 0 0;
  display: inline-block;
}
#people_other_info .nav-tabs > li.active > a, #people_other_info .nav-tabs > li.active > a:focus, #people_other_info .nav-tabs > li.active > a:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  border-bottom-color: transparent;
  border-radius: 50px;
  padding: 7px 20px;
  font-weight: 500;
}
#people_other_info .tab-content {
  min-height: 400px;
}
#people_other_info .tab_head {
  display: flex;
  justify-content: space-between;
}

#people_other_info .tab_title,
.tab_links {
  display: inline-block;
  vertical-align: top;
}
.track_point ul li a,
.people_contact_edit a,
.awards_info a,
.inside_detail_info table tr td:first-child a,
.news_text a h4,
.news_text span,
.news_detail a {
  color: #337ab7;
}
#people_other_info .tab_title h6 {
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  color: #3a3a3a !important;
}
#people_other_info .tab_head {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;
  margin: 0 0 25px;
}
#people_other_info .tab_head .dropdown-menu {
  padding: 10px;
  font-size: 13px;
  min-width: 210px;
}
#people_other_info .edit_tab {
  padding: 10rem 0 0;
}
#people_other_info .edit_tab .blue_btn {
  margin: 15px 0 0;
}
#people_other_info .nav.nav-tabs {
  background: transparent;
  border: none;
}
#people_other_info .nav-tabs > li > a {
  margin: 0;
  border-radius: 0;
}
#people_other_info .nav-tabs > li > a:hover,
#people_other_info .nav-tabs > li > a:focus {
  background: transparent;
  color: #fff !important;
}
#people_other_info .nav-tabs > li {
  float: none;
  margin-bottom: -2px;
  display: inline-block;
}
#star_graph {
  padding: 40px 0;
}
.side_links a {
  padding: 0 5px;
  font-size: 13px;
  text-decoration: underline;
}
.side_links {
  margin: 8px 0 0;
}

.awards_bx {
  margin: 15px 0;
  border: none;
}
.awards_info {
  padding: 10px 0 5px;
  font-size: 13px;
}
.nav-tabs > li > a:hover {
  border-color: transparent;
}

#inner_form .side_bar_bg,
#inner_form .side_bar_bg:before {
  background: #ffffff;
}

/* track page */
#track_page_header {
  background: #003169;
  padding-top: 50px;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
}
#inner_banner {
  background: #003169;
  color: #fff;
  font-size: 20px;
  overflow: hidden;
  padding: 50px 0;
}
#track_page_header .top_left_info {
  padding: 40px 0 0 15rem;
}
#inner_banner .blue_btn {
  margin: 20px 0 0;
}
#inner_banner.signle_head h2 {
  margin: 0;
}
#track_page_header h2,
#inner_banner h2 {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px;
}
#track_page_header .stroke_btn {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 3px;
  display: inline-block;
  margin: 40px 0 0;
  font-size: 17px;
}
#track_page_header.casting_list_banner .top_left_info {
  padding: 10px 0 0 15rem;
}
#track_page_header .banner_img img {
  max-width: 400px;
}
#track_page_header.video_list .banner_img img {
  max-width: none;
}
#track_page_header.video_list .banner_img img {
  max-width: none;
  max-width: 700px;
}
#track_page_header.video_list .top_left_info {
  padding: 25px 0 0 15rem;
}
.side_bar_bg {
  background: #e8eeff;
  position: absolute;
  height: 100%;
  width: 25%;
  top: 0;
  left: 12px;
}
.side_bar_bg:before {
  content: "";
  left: -100%;
  width: 100%;
  background: #e8eeff;
  height: 100%;
  position: absolute;
  top: 0;
}
#track_page_tab .nav-tabs > li.active > a,
#track_page_tab .nav-tabs > li.active > a:focus,
#track_page_tab .nav-tabs > li.active > a:hover,
#create_breakdown .nav-tabs > li.active > a,
#create_breakdown .nav-tabs > li.active > a:focus,
#create_breakdown .nav-tabs > li.active > a:hover,
#inner_form .nav-tabs > li.active > a,
#inner_form .nav-tabs > li.active > a:focus,
#inner_form .nav-tabs > li.active > a:hover,
#news_page .nav-tabs > li.active > a,
#news_page .nav-tabs > li.active > a:focus,
#news_page .nav-tabs > li.active > a:hover {
  color: #fff !important;
  cursor: default;
  background-color: rgb(44 161 217);
  border: none;
  border-bottom-color: transparent;
  border-radius: 30px 0px 0px 30px;
  border-left: 4px solid rgb(255 255 255);
}
#inner_form .nav-tabs > li.active > a,
#inner_form .nav-tabs > li.active > a:focus,
#inner_form .nav-tabs > li.active > a:hover {
  background-color: #e8eeff;
}
#track_page_tab .nav-tabs > li > a:hover,
#track_page_tab .nav-tabs > li > a:focus,
#inner_form .nav-tabs > li > a:hover,
#news_page .nav-tabs > li > a:focus,
#news_page .nav-tabs > li > a:hover,
#news_page .nav-tabs > li > a:focus {
  background-color: transparent;
  color: #08afff;
}
#track_page_tab .nav-tabs > li > a,
#inner_form .nav-tabs > li > a {
  margin-right: 0;
  border: 1px solid transparent;
  border-radius: 0;
  font-weight: 400;
  color: #fff;
  padding: 15px 20px;
  border-left: 4px solid transparent;
  font-size: 14px;
}
#inner_form .nav-tabs > li > a small {
  font-weight: 300;
  font-style: italic;
  color: #7f7f7f;
}
.right_space {
  padding-right: 0;
}
#track_page_tab .nav-tabs {
  border: none;
  height: 100%;
  background: rgba(0,0,0,0.4);
  padding: 20px 0 20px 20px;
  border-radius: 8px;
}
#track_page_tab,
#list_table {
  padding: 60px 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
#track_page_tab .nav-tabs > li,
#inner_form .nav-tabs > li {
  float: none;
  margin-bottom: 0;
  display: block;
}

#track_page_tab .tab-content h6,
#inner_form .nav-tabs {
  margin: 0 0 25px;
  font-size: 26px;
  font-weight: 700;
}
#inner_form .nav-tabs {
  border: none;
}
.track_point ul li {
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.list_part ul li {
  justify-content: space-between;
}
.track_point ul li .track_info {
  padding-left: 25px;
}

.track_point ul li .track_info a {
  font-size: 20px;
  color: #242424;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.track_point ul li .track_info a:hover {
  color: #025dc3;
}
.track_point ul li .track_info small,
.list_part ul li .list_info small {
  display: block;
  font-style: italic;
  color: #7f7f7f;
}
.track_point ul li img {
  width: 80px;
}

#inner_form .shadow_bg {
  margin: 0 20px;
}
#inner_form.full_form .shadow_bg {
  margin: 0 60px;
}
#inner_form .upload_video .shadow_bg {
  margin: 0 60px !important;
}
#inner_form .upload_video form small {
  color: #b0b0b0;
  font-style: italic;
}
#list_table.videos_table {
  background: #e8eeff;
}

/* list page */
.list_part ul li {
  padding: 18px 0;
  display: flex;
  align-items: center;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.list_part ul li:hover {
  padding: 20px;
  border: 1px solid #eee;
  box-shadow: 0 3px 16px -6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: #f7f6ff;
}
.list_part ul li:hover .list_info a {
  color: #025dc3;
}
.list_part ul li:hover .action_btn a {
  color: #fa1d28;
}
.list_part ul li .list_info a {
  font-size: 20px;
  color: #242424;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  white-space: nowrap;
  width: 455px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding-bottom: 2px;
}
.list_part ul li .item_info span {
  background: #d4eaf6;
  padding: 9px 25px;
  font-weight: 600;
  border-radius: 50px;
}
.list_part ul li .action_btn a {
  font-size: 22px;
  color: #c3c3c3;
}
.list_sort h6,
.fiter_option h6 {
  font-size: 16px;
  display: inline-block;
  color: #757575;
  margin-top: 0;
}
.list_sort {
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
  margin: 0 0 10px;
}
.list_sort .form-group {
  display: inline-block;
  float: right;
  margin: 0;
}
.list_sort .form-group label,
.filter_fields .form-group label {
  display: inline-block;
  text-transform: uppercase;
  color: #555;
  font-weight: 600;
}
.list_sort .form-group select,
.filter_fields .form-group select {
  display: inline-block;
  width: auto;
  border: none;
  box-shadow: none;
  padding: 0 5px;
  vertical-align: top;
  height: auto;
}
.list_filter {
  padding-right: 30px;
}
.list_part,
.left_space {
  padding-left: 35px;
}
.list_filter .stroke_btn {
  border: 1px solid #0f7ad5;
  background: transparent;
  color: #0f7ad5;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 3px;
  display: inline-block;
  margin: 10px 0 0;
  font-size: 17px;
  width: 100%;
  text-align: center;
}

#track_page_header.my_list .banner_img img {
  max-width: 320px;
}
#track_page_header.box_office_banner .banner_img img {
  max-width: 590px;
}
#inner_header_part {
  border: 1px solid #ddd;
}

#track_page_header.casting_banner .banner_img img {
  max-width: 430px;
}

/* people detail */
#people_other_info .shadow_bg {
  margin: 0 auto;
}
.tab-pane .news_text {
  font-size: 14px;
}
.tab-pane .news_text span {
  font-size: 13px;
}
.tab-pane .news_text a {
  font-size: 14px;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}
.tab-pane .news_img img {
  height: 165px;
  width: 100%;
  object-fit: cover;
}
.people_header h3 small {
  display: inline-block;
}
.side_details {
  position: relative;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 9;
  width: 100%;
}
.side_details.hire_talent_side {
  width: auto;
}
.side_details .shadow_bg {
  height: 100%;
  margin:0 auto 15px;
  padding: 0 20px 0;
  overflow: auto;
  background: #fff;
  color:#3a3a3a;
  border:1px solid #e7e7e7;
  border-radius: 0;
  box-shadow: none;
}
.people_full_info .add_primary_img {
  padding: 0;
  height: 450px;
  position: relative;
  z-index: 9;
}
.people_center_info {
  position: relative;
  z-index: 9;
}
.people_full_info .add_primary_img img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
.people_header .side_links {
  float: right;
}
.people_full_info .inside_img img {
  height: 65px;
  width: 68px;
  object-fit: cover;
  margin: 0 6px 0 0;
}
.people_full_info .inside_img {
  background: transparent;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
}
.inside_img hr {
  border-color: #335272;
}
.bottom_desc {
  margin: 35px 0 0;
  font-weight: 300;
  color: #e0e5e9;
  position: relative;
}

.bottom_desc a {
  color: #fff;
  font-weight: 600;
}
.back_link {
  padding: 0 21px;
  font-size: 17px;
  margin: 0 0 10px;
  font-weight: 600;
}

.bottom_desc ul {
  margin: 20px 0 0;
  font-size: 14px;
}
.bottom_desc ul li p,
.bottom_desc ul li .right_detail {
  width: 75%;
  float: left;
  margin: 5px 0;
}
.bottom_desc ul li span {
  font-weight: 600;
  padding-right: 35px;
  width: 25%;
  float: left;
  margin: 5px 0;
}
.awards_bx h6,
.trending h6 {
  background: #e8eeff;
  padding: 10px 18px;
  margin: 0 -20px;
  font-size: 16px;
  font-weight: 600;
}
.people_contact_edit ul li {
  padding: 15px 0;
  border-bottom: 1px dashed #ccc;
  font-size: 13px;
}
.people_contact_edit ul li:first-child {
  padding-top: 0;
}
.people_contact_edit ul li:last-child {
  padding-bottom: 0;
  border: none;
}
.people_contact_edit ul li h5 {
  margin: 0 0 7px;
  font-size: 14px;
}
.people_contact_edit a,
.awards_info a {
  display: block;
}
.people_contact_edit p a {
  display: inline-block;
}
.tab_links .dropdown {
  display: inline-block;
  padding: 0 10px;
}
.tab_links .dropdown input {
  margin-left: 10px;
}
.inside_detail_info img {
  width: 50px;
  margin-right: 7px;
}
.inside_detail_info table tr td p {
  display: inline-table;
  vertical-align: middle;
}
.inside_detail_info {
  font-size: 14px;
}

.award_detail small,
.award_detail p {
  display: block;
  line-height: 22px;
}
.tab_links .form-group label,
.tab_links .form-group select,
#edit_feature_page .right_edit_option label,
#edit_feature_page .right_edit_option select {
  display: inline-block;
  width: auto;
  height: 30px;
  padding: 1px 10px;
}
.tab_links .form-group {
  display: inline-block;
  margin: 0 5px;
}
.thumbnail_image img {
  width: 25%;
  height: 200px;
  object-fit: cover;
  padding: 8px;
  margin: 0 -2px;
}
.video_img img {
  width: 100%;
  height: 143px;
  object-fit: cover;
}
.big_video .video_img img {
  height: 361px;
}
.video_img .fa {
  display: none;
}
.thumbnail_video a:hover .video_img .fa {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 25%;
  font-size: 50px;
  color: #fff;
  margin: 0 auto;
  border-radius: 50%;
  text-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
}
.thumbnail_video .big_video a:hover .video_img .fa {
  top: 37%;
}
.thumbnail_video p {
  font-weight: 600;
  line-height: 20px;
  margin: 10px 0 0;
  font-size: 13px;
  color: #333;
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.thumbnail_video a {
  margin: 10px 0;
}
.video_bx {
  margin: 10px 0;
}

.profession_head {
  position: relative;
  padding: 9rem 0 !important;
}
.pro_right_img {
  position: absolute;
  right: 10rem;
  bottom: 0;
  max-width: 250px;
}
.pro_left_img {
  position: absolute;
  left: 10rem;
  bottom: 0;
  max-width: 250px;
}

/* casting breakdown page */
.casting_edit .blue_btn {
  margin: 20px 0 0;
}
.casting_edit h5 {
  margin: 0 0 10px;
  font-size: 24px;
}
.casting_edit {
  padding: 5rem 0 7rem;
}
#create_breakdown form,
#submission .submission_bg,
.shadow_bg {
  background: #fff;
  padding: 25px;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #e7e7e7;
}
#create_breakdown form .form-group,
#post_casting_notice .form-group,
#create_breakdown .form-group {
  margin: 0 0 25px;
}
#create_breakdown form .blue_btn {
  margin-right: 6px;
}

#create_breakdown form hr {
  border-color: #dad8d8;
  margin: 5px 0 25px;
}

.bg_part {
  background: #e8eeff !important;
  padding: 5rem 0 6rem !important;
}

#submission .table .detail_info {
  display: flex;
  align-items: start;
}
#submission .table .detail_info img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
}

#submission .table .detail_info a,
.show_list_tabe .table tr td h6 {
  display: block;
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
#submission .table tr td,
.show_list_tabe .table tr td,
.inside_detail_info .table tr td,
#box_office .table tr td,
#account_setting .table tr td,
.videos_table .table tr td {
  background: #fff;
  padding: 15px 10px;
  vertical-align: middle;
  border-top: none;
  border-bottom: 1px dashed #bdd7e9;
}
#submission .table a {
  color: #333;
}
#submission .table a:hover,
#submission .table a:hover .fa,
#account_setting .table a:hover .fa,
.videos_table .table a:hover .fa {
  color: #025dc3;
}
#submission .table a .fa,
#account_setting .table a .fa,
.videos_table .table a .fa {
  font-size: 18px;
  color: #7f7f7f;
  margin: 0 5px;
}

#submission,
#box_office {
  background: #e8eeff;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
#submission .table .detail_info small,
.show_list_tabe .table tr td small {
  color: #7f7f7f;
}

#submission .table tr th,
.show_list_tabe .table tr th,
#all_list .table tr th,
.inside_detail_info .table tr th,
#box_office .table tr th,
#account_setting .table tr th,
.videos_table .table tr th {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #7f7f7f;
  border: none;
  padding: 10px 10px;
}
.inside_detail_info .table tr th {
  font-size: 12px;
}
#submission .table tr th:last-child,
.show_list_tabe .table tr th:last-child {
  text-align: center;
}
#submission .table thead,
.show_list_tabe .table thead,
#all_list .table thead,
.inside_detail_info .table thead,
#box_office .table thead,
#account_setting .table thead,
.videos_table .table thead {
  background: #e8eeff;
}
#submission .alert-info,
#account_setting .cancel_membership .alert-info {
  color: #1683f3;
  background-color: transparent;
  border-color: #1683f3;
  padding: 8px 10px;
  font-size: 14px;
  margin: 40px 0 0;
}

/* Customize the label (the container) */
.table .check_bx {
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 18px;
}

.check_bx {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  /* color: #fff; */
  line-height: 20px;
}

/* Hide the browser's default checkbox */
.check_bx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #1683f3;
}

/* On mouse-over, add a grey background color */
.check_bx:hover input ~ .checkmark {
  outline: none;
  border-color: #8d8d8d;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check_bx input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check_bx .checkmark:after {
  left: 0;
  top: 3px;
  width: 7px;
  height: 7px;
  background: #333;
  right: 0;
  margin: 0 auto;
}

.top_head_info p strong {
  font-weight: 600;
}
.top_head_info p,
.casting_list_head p {
  font-size: 12px;
  color: #3a3a3a;
}
#post_casting_notice .post_tabbing h5 {
  display: inline-block;
  margin: 10px 0 0;
  font-weight: 600;
}
.post_tabbing .nav-tabs li a,
.box_office_head .nav-tabs li a {
  border: none;
  background: #d4eaf6;
  border-radius: 50px;
  padding: 7px 20px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 4px;
  color: #555;
}
.post_tabbing .nav-tabs li:hover a,
.box_office_head .nav-tabs li:hover a {
  color: #025dc3;
}
#post_casting_notice .nav-tabs {
  float: right;
  display: inline-block;
  border: none;
}
.post_tabbing {
  border-bottom: 1px solid #e9e9e9;
  padding: 0 0 20px;
  margin: 0 0 20px;
}
.post_tabbing .nav-tabs > li.active > a,
.post_tabbing .nav-tabs > li.active > a:focus,
.post_tabbing .nav-tabs > li.active > a:hover,
.box_office_head .nav-tabs > li.active > a,
.box_office_head .nav-tabs > li.active > a:focus,
.box_office_head .nav-tabs > li.active > a:hover {
  color: #fff !important;
  cursor: default;
  background-color: #025dc3 !important;
  border-color: transparent !important;
  border-radius: 50px !important;
  border: none;
}
#post_casting_notice .top_head_info,
#create_breakdown .top_head_info,
#inner_form .top_head_info {
  margin-bottom: 30px;
  background: #f4faff;
}

#create_breakdown .nav-tabs {
  border: none;
}
.right_link .stroke_btn {
  margin: 0 0 0 15px;
}

.casting_edit img {
  opacity: 0.2;
  width: 150px;
  margin: 0 0 40px;
}

#list_table .casting_edit {
  padding: 5rem 15px;
}

/* box office page */
#box_office_header img {
  position: absolute;
  height: 350px;
  width: 100%;
  top: 0;
  object-fit: cover;
  opacity: 0.1;
}
#box_office_header {
  background: #ffc543;
  padding: 9rem 0;
  overflow: hidden;
  position: relative;
}
.box_office_txt {
  font-size: 18px;
  line-height: 25px;
}
#box_office tr td h5 {
  display: table-cell;
  font-size: 15px;
}
#box_office tr td h5:hover {
  color: #025dc3;
}
#box_office tr td img {
  width: 40px;
  height: 50px;
  display: inline-block;
  margin-right: 5px;
  object-fit: cover;
  border-radius: 3px;
}
#box_office fieldset select {
  display: inline-block;
  width: auto;
  height: 32px;
  padding: 0 14px;
}
.pagination {
  margin: 10px 0 0;
}
.box_office_head .nav-tabs {
  border: none;
  display: inline-block;
  position: absolute;
  top: 10px;
  z-index: 9;
}
.box_office_head fieldset {
  float: right;
}
.box_office_head {
  margin: 0 0 15px;
  position: relative;
}

/* news page */
#news_page h5 {
  margin: 0 0 15px;
}
#news_page {
  background: #fff;
  overflow: hidden;
  padding: 60px 0;
  position: relative;
}
.news_detail img {
  width: 100%;
  margin: 0 0 20px;
}
#news_page .tab-content {
  padding-left: 20px;
}
#news_page .nav-tabs > li > a {
  color: #333;
  margin: 4px 0;
  font-weight: 600;
  padding: 10px 17px;
}

#news_page .nav-tabs {
  border: none;
}
#news_page .nav-tabs > li {
  float: none;
}

/* professional steps page */
#profession_step {
  background: #e8eeff;
  padding: 6rem 0;
  position: relative;
}
#profession_step .tab_info small {
  color: #7f7f7f;
}
#profession_step .steps_part {
  position: relative;
  z-index: 9;
  width: 1000px;
  margin: 0 auto;
}
#profession_step .nav-tabs > li {
  width: 31%;
  text-align: center;
  background: #fff;
  margin: 10px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#profession_step .nav-tabs > li.active > a,
#profession_step .nav-tabs > li.active > a:focus,
#profession_step .nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: transparent;
  border: none;
  border-bottom-color: transparent;
}
#profession_step .nav-tabs > li.active > a .fa,
#profession_step .nav > li > a:hover .fa {
  color: #ffa22b;
  border-color: #ffa22b;
}
#profession_step .nav-tabs > li.active > a h5 {
  color: #ffa22b;
}
#profession_step .nav > li > a:focus,
#profession_step .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;
}
#profession_step .nav-tabs > li > a {
  border-radius: 0;
}
#profession_step .nav-tabs > li > a .fa {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  line-height: 60px;
  color: #1683f3;
  font-size: 24px;
  margin: 0 0 20px;
  border: 1px solid #1683f3;
}
#profession_step .shadow_bg {
  margin: 22px 32px 0;
}
#profession_step .skip_btn {
  margin: 20px 32px 0;
}
#profession_step .nav-tabs {
  border: none;
}
.profession_heading {
  color: #fff;
  margin: 20px 0 30px;
}
.profession_heading h1,
.profession_heading h5 {
  color: #fff;
}
.profession_heading h5 {
  font-weight: 400;
  margin: 10px;
}
#profession_step .tab-content .form-group label {
  font-weight: 400;
  vertical-align: middle;
}
#profession_step .tab-content .form-group {
  margin: 0;
}

/* inbox page */
#track_page_header.inbox_banner img {
  max-width: 350px;
}
#inbox_detail .pink_btn {
  padding: 3px 15px;
  font-size: 14px;
}
.industry_people img {
  height: 250px;
  object-fit: cover;
  border: 1px solid #eee;
  width: 100%;
}
.inbox_info {
  padding: 40px 60px 0;
  margin-bottom: 50px;
}

.inbox_head {
  background: #fff;
  border: none;
  padding: 10px 20px;
  height: auto;
}
.inbox_head .stroke_btn {
  float: right;
  margin: 5px 0 0;
}

.industry_people .stroke_btn {
  display: none;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  width: auto;
  border-color: #fff;
  color: #fff;
  margin: 0 20px;
}
.industry_people:hover .stroke_btn {
  display: block;
}
.inbox_head h5 {
  margin: 15px 0;
  display: inline-block;
}
.industry_people h6 {
  font-size: 16px;
  color: #222;
  font-weight: 600;
}
.industry_people h6:hover {
  color: #1683f3;
}
#inbox_detail {
  padding: 40px 0 0;
  background: #e8eeff;
}
.overlay_part {
  position: relative;
}
.overlay_part:hover:after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*

.inbox_info .trendy_txt {
    display: flex;
    margin: 0 0 15px;
    align-items: center;
    border-bottom: 1px solid #cbcbd7;
    padding: 0 0 15px;
}
.inbox_info ul li:last-child .trendy_txt{border:none;}
.inbox_info .trendy_txt > img {
    margin-right: 13px;
    height: 100px;
    width: 70px;
    object-fit: cover;
}
.inbox_info .trendy_txt h6 {
    color: #222;
    font-weight: 600;
    font-size: 17px;
}
.inbox_head {
    background: #fff;
    border: none;
    padding: 10px 20px;
    height: auto;
    margin: 0;
}
.inbox_info {
    padding: 40px 0 0;
    margin-bottom: 50px;
}
.inbox_head h5 {
    margin: 15px 0;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}

.inbox_info .stroke_btn {
    width: 100%;
    display: block;
    text-align: center;
}
*/

/* page control */
.switch-field {
  display: inline-block;
  float: right;
}
.page_control .form-group p {
  display: inline-block;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

/* casting breakdown list */
#breakdown_list ul li table {
  margin: 20px 0 0;
}
#breakdown_list ul li table tr td h6 {
  margin: 0;
}
#breakdown_list .breakdown_casting_info {
  margin: 0 60px 20px;
}
#track_page_header.casting_list_banner img {
  max-width: 500px;
}
.casting_list_head {
  display: inline-block;
  margin: 0 0 10px;
  width: 80%;
}

.casting_list {
  border-bottom: 1px dashed #bdd7e9;
  padding: 0 0 20px;
  margin: 20px 0;
}
.auditions .casting_list img,
.auditions_history .casting_list img {
  width: 90px;
  vertical-align: top;
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 5px;
}

.auditions_history .casting_list_btn {
  float: right;
}
.auditions_history .casting_list_head {
  width: 80%;
}

.auditions .casting_list_btn {
  padding: 15px 0 0;
}
.auditions .casting_list_btn .side_date_bx {
  margin: 0;
}
.casting_list_btn .action_btn, .casting_list_btn .side_date_bx {
  float: right;
  font-size: 18px;
  padding: 0;
  margin: -6px 0 0;
  border-radius: 20px;
}
.casting_list_btn .side_date_bx {
  background: #e8eeff;
}
.casting_list_btn .action_btn a {
  color: #949497;
  font-size: 14px;
  margin-left: 8px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #ddd;
  text-align: center;
  line-height: 32px;
}
.casting_list_btn a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #337ab7 !important;
}
.left_casting_btn {
  background: #f6f6f6;
  padding: 20px;
  border-radius: 6px;
  margin-left: 25px;
  text-align: center;
  float: none;
  width: 25%;
  min-height: 170px;
}
.disc_brief {
  font-size: 12px;
  line-height: 20px;
  margin: 10px 0;
}
.left_casting_btn .stroke_btn {
  margin: 7px auto;
  min-width: 137px;
  text-align: center;
}
.flex_bx {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.left_casting_btn a {
  font-weight: 500;
  font-size: 13px;
  margin-left: 20px;
  padding: 3px 15px;
}
.casting_list_head h3 {
  margin: 0 0 1px;
  font-size: 17px;
  font-weight: 600;
}
.discrption{display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
}
/* account setting pages */
#track_page_header.account_banner .banner_img img {
  max-width: 270px;
  margin: 0 0 20px;
}
.account_bg {
  padding: 60px 0;
  background: #e8eeff;
}

.account_left ul li {
  padding: 10px 15px;
  display: inline-block;
}
.account_left ul {
  padding: 12px 0;
}
.account_left ul li:last-child {
  border: none;
}
.account_left ul li a {
  color: #333;
}
.account_left ul li.active {
  background: #1683f3;
  border-radius: 50px;
}
.account_left ul li.active a,
.account_left ul li.active:hover a {
  color: #fff;
}
.account_left ul li:hover a {
  color: #337ab7;
}
.account_right .form-group label h6 {
  margin: 5px 10px;
  font-weight: 600;
}
.red_bx {
  background: #ff1c41;
}
.account_right .form-group label h6 span {
  font-size: 13px;
}
.account_right .membership_form .form-group input[type="radio"] {
  vertical-align: top;
  margin: 20px 0 0;
}
.account_right .membership_form .form-group input[type="text"] {
  display: inline-block;
  width: 65%;
}
.charges_bx ul li:last-child {
  font-weight: 600;
  border: none;
}
.charges_bx ul li span {
  float: right;
}
.charges_bx ul li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
}
.charges_bx a,
.charges_bx .blue_btn {
  display: block;
  text-align: center;
  margin: 10px 2px;
  width: 49%;
  float: left;
}
.update_detail_form .blue_btn {
  display: block;
  text-align: center;
  margin: 10px 2px;
}
.charges_bx .blue_btn {
  padding: 9px 20px;
}
.account_right .plan_info {
  padding: 10px 0 0;
}
.update_detail_form h5 {
  margin: 0 0 30px;
}
.update_detail_form .form-group a:hover {
  text-decoration: underline;
  color: #337ab7;
}
.email_preference .form-group label small {
  font-weight: 400;
  color: #6c6c6c;
  font-size: 14px;
  display: block;
}
.checkbox_list .form-group label h6 {
  margin: 0;
  font-size: 16px;
}
.checkbox_list .form-group input[type="checkbox"] {
  vertical-align: top;
  margin: 3px 0 0;
}
.checkbox_list {
  border: 1px solid #ddd;
  margin: 3px 0 0 20px;
  background: #f3f5f8;
  border-radius: 6px;
}
.checkbox_list .form-group {
  padding: 12px 15px;
  border-bottom: 1px dashed #ccc;
  margin: 0;
}
.checkbox_list .form-group:last-child {
  border: none;
}
.checkbox_list .form-group label {
  margin: 0;
}
.email_preference fieldset {
  margin: 30px 0 0;
}
.email_preference input[type="radio"] {
  vertical-align: top;
  margin: 5px 4px 0 0;
}
.account_right h5 {
  margin: 0 0 5px;
  font-weight: 600;
}
.payment_method .payment_info {
  display: inline-block;
}
.payment_method ul li a {
  float: right;
}
.payment_method label {
  display: block;
}
.payment_method .double_field select {
  width: 50%;
  display: inline-block;
  margin-right: -2px;
}
.change_payment_mode fieldset {
  background: #f3f5f8;
  padding: 15px 20px;
  border: 1px solid #ddd;
}
.change_payment_mode img {
  max-width: 365px;
  margin: 0 0 20px;
}
.change_payment_mode {
  margin: 50px 0 0;
}
.payment_method .pink_btn {
  margin: 20px 0 0;
}
.cancel_membership .form-group label {
  font-weight: 400;
}
.cancel_membership .form-group {
  margin: 4px 0 15px;
}
.cancel_other input {
  width: 30%;
  display: inline-block;
  margin-left: 10px;
}
.cancel_other .check_bx {
  display: inline-block;
}
.cancel_membership .blue_btn {
  margin: 10px 0 0;
}
.cancel_membership h6 {
  margin: 0 0 15px;
}

/* add a role page */

.input_dropdown .caret {
  float: right;
  margin: 10px 0;
}
.input_dropdown .form-control {
  text-align: left;
}
.input_dropdown .checkbox-menu {
  width: 100%;
  margin: 0;
  border-radius: 0;
  padding: 10px 10px 2px;
}
.input_dropdown .checkbox-menu input {
  margin-right: 5px;
}
.bottom_btns a {
  margin-right: 5px;
}

/* help page */
.brief_intro {
  display: block;
  display: -webkit-box;
  max-height: 55px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13.5px;
  line-height: 22px;
  margin: 0 0 5px;
}
.help_page img {
  margin: 15px 0;
}
.help_page h2 {
  margin: 0 0 10px;
  font-size: 28px;
}
.news_detail {
  padding-left: 45px;
}

#news_page.help_page {
  background: #e8eeff;
}
#news_page.help_page .tab-content {
  padding-left: 0;
}
#news_page.help_page .nav-tabs > li.active > a,
#news_page.help_page .nav-tabs > li.active > a:focus,
#news_page.help_page .nav-tabs > li.active > a:hover {
  color: #025dc3;
  cursor: default;
  background-color: transparent;
  border-bottom-color: transparent;
  border-radius: 0;
}
#news_page.help_page .shadow_bg {
  margin: 0;
}

/* privacy page */
#privacy h5 {
  margin: 0 0 15px;
}
#privacy ul {
  padding-left: 20px;
}
#privacy ul li {
  list-style: disc;
  padding: 5px 0;
}

/* edit page popup */
.edit_page ul li {
  display: block;
  padding: 11px 22px;
  background: #fff;
  margin: 10px 8px;
  border-radius: 50px;
  width: 47%;
  float: left;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.edit_page ul li:hover {
  box-shadow: 0 3px 11px -4px rgba(0, 0, 0, 0.2);
  transform: translate(0, -2px);
}
.edit_page ul li:hover a {
  color: #025dc3;
}
.edit_page ul li a {
  color: #333;
}
.edit_page ul li .fa {
  float: right;
  margin: 5px 0 0;
}
.edit_page .modal-content {
  padding: 0 0 15px;
  background-color: #e8eeff;
}
.modal-content:after,
.modal-content:before {
  content: "";
  display: table;
  clear: both;
}
.modal-content {
  position: relative;
}

.modal-header .close {
  margin-top: -2px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  right: -5px;
  top: -5px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5);
}

/* inner pages tabbing & forms */
.white_bg ul li {
  padding: 5px 0;
}
#inner_form .multi_tab .nav-tabs {
  border: none;
}
#inner_form .multi_tab .nav-tabs > li {
  display: inline-table;
  padding: 0 0;
  width: 33%;
  transition: all ease 0.4s;
  -webkut-transition: all ease 0.4s;
}

#inner_form .multi_tab .nav-tabs > li a {
  padding: 13px 0;
  font-size: 15px;
  font-weight: 400;
}
#inner_form .multi_tab .nav-tabs > li.active > a,
#inner_form .multi_tab .nav-tabs > li.active > a:hover,
#inner_form .multi_tab .nav-tabs > li.active > a:focus {
  border: none;
  border-radius: 50px;
  padding: 13px 20px;
}
#inner_form .multi_tab hr {
  margin-bottom: 5px;
}
#inner_form .multi_tab .shadow_bg {
  padding-bottom: 0;
}
#inner_form .multi_tab .tab-content .shadow_bg {
  padding-bottom: 30px;
}
#inner_form .multi_tab .tab-content {
  margin: 30px 0 0;
}
#inner_form .multi_tab .tab-content hr {
  margin-bottom: 30px;
}
#inner_form .multi_tab .nav-tabs > li:hover {
  transform: translate(0, -2px);
  -webkit-transform: translate(0, -2px);
  padding: 0 20px;
  background: #e8eeff;
  border-radius: 50px;
}

/* video page */
.videos_table table tr td img {
  width: 150px;
  object-fit: cover;
  border-radius: 6px;
}
.videos_table .table tr td:last-child {
  white-space: nowrap;
}

/* image page */
.images_head ul li h6,
#add_name_tag ul li h6,
#edit_cast ul li h6,
.modal-child ul li h6 {
  margin: 0;
}
.images_head ul li h6 strong,
#add_name_tag ul li h6 strong,
#edit_cast ul li h6 strong,
.modal-child ul li h6 strong {
  font-weight: 600;
  font-size: 16px;
}
.image_bx .add_primary_img {
  background: #eff4f5;
  text-align: center;
  min-height: 166px;
  padding: 4rem 0 0;
}
.images_page form {
  margin: 0 0 40px;
}
.images_page .check_bx {
  margin: 10px 0 0;
}
.images_head ul li img,
#add_name_tag ul li img,
#edit_cast ul li img,
.modal-child ul li img {
  width: 55px;
  height: 70px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 3px;
}
.images_head ul li,
#add_name_tag ul li,
#edit_cast ul li,
.modal-child ul li {
  display: flex;
  align-items: center;
}
#add_name_tag fieldset input,
#myModal1 fieldset input {
  width: 86%;
  display: inline-block;
}
#add_name_tag ul li,
#edit_cast ul li,
.modal-child ul li {
  position: relative;
  border-bottom: 1px dashed #ddd;
  padding: 15px 0;
}
#add_name_tag fieldset {
  text-align: center;
}
.thumbnail_info .fa {
  position: absolute;
  right: 5px;
  top: 36%;
  font-size: 20px;
  color: #ced4d9;
}
#add_name_tag .last_btn .stroke_btn,
#edit_cast .stroke_btn {
  width: 100%;
  margin: 15px 0 0;
}
#myModal1 .blue_btn,
#add_name_tag .blue_btn {
  padding: 10px 25px;
}

/* edit featured image */
#edit_feature_page {
  background: #e8eeff;
  padding: 60px 0;
}
#edit_feature_page .add_primary_img {
  padding: 0;
  height: auto;
  min-height: 300px;
}
#edit_feature_page .inside_img input[type="file"] {
  display: none;
}

#edit_feature_page .inside_img label {
  cursor: pointer;
  color: #337ab7;
}
#edit_feature_page .bottom_desc h3 {
  display: inline-block;
}
#edit_feature_page .right_edit_option {
  float: right;
}
#edit_feature_page .bottom_desc {
  color: #535353;
}
#edit_feature_page .main_text_area ul li {
  display: inline-block;
  width: 100px;
  vertical-align: top;
  margin: 5px;
}
#edit_feature_page .inside_img {
  margin: 5px;
}
#edit_feature_page .main_text_area ul li img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.right_edit_option .form-group {
  display: inline-block;
  margin: 0 5px;
}

/* edit cast */
#edit_cast .modal-content,
#myModal1 .modal-content {
  min-height: 600px;
}
#myModal2 .controls .entry {
  margin: 0 0 15px;
}
#myModal2 .pink_btn {
  width: 100%;
}
#edit_cast ul li a .fa-edit {
  right: 35px;
}

/* register page */
#login .register_type hr {
  border-color:#cfcfcf !important;
  margin: 10px 0;
}
.stepwizard-row {
  position: relative;
  background: #b7d2ef;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

#login.register_page .stepwizard-row .btn {
  background: transparent;
  margin: 0;
  padding: 10px 10px 5px;
}
#login.register_page .stepwizard-row .btn-primary {
  background: #1683f3;
}

#login .register_type h4 {
  padding: 30px 30px 16px;
}
.stepwizard-step {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 14.2%;
  vertical-align: top;
  float: left;
}
.stepwizard-step a {
  width: 100%;
  border-radius: 0;
  border: none;
}
.btn-circle {
  width: 26px;
  height: 26px;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  border-radius: 50%;
  opacity: 1 !important;
  margin: 0 0 4px;
  background: #fff;
  display: inline-block;
  font-weight: 600;
}
.stepwizard-step p{font-size: 14px;}
.stepwizard-step .btn-primary:after {
  border-top: 35px solid transparent;
  border-left: 14px solid #1683f3;
  border-bottom: 35px solid transparent;
  content: "";
  position: absolute;
  right: -14px;
  top: 0;
}
.stepwizard-step .btn-primary:before {
  border-top: 35px solid transparent;
  border-left: 14px solid #b7d2ef;
  border-bottom: 35px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.stepwizard-step:last-child .btn-primary:after,
.stepwizard-step:first-child .btn-primary:before {
  display: none;
}
#login.register_page .btn-primary span {
  color: #1683f3;
}
#login.register_page .btn:active,
#login.register_page .btn:focus {
  box-shadow: none;
}
.upload_resume_field p {
  display: inline-block;
  margin-right: 5px;
}
.upload_resume_field input {
  display: none;
}
.register_page .white_bg {
  margin: 20px 0 10px;
}

.regiester_option ul li img {
  width: 90px;
  margin-right: 15px;
}
.regiester_option ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #bdbdbd;
  padding: 30px 0;
}
.regiester_option ul li:first-child {
  padding-top: 0;
}
.regiester_option ul li:last-child {
  border: none;
  padding-bottom: 0;
}
.regiester_option ul li .option_info {
  width: 100%;
  position: relative;
  color: #333;
}
.option_info h5 {
  margin: 0 0 5px;
}

#register_type {
  padding: 60px 0;
}
.register_page .form-group .dropdown ul {
  width: 100%;
  padding: 10px 20px;
  height: 350px;
  overflow: auto;
}
.register_page .form-group .dropdown ul li h6 {
  background: #eee;
  padding: 8px 10px;
  font-size: 15px;
  font-weight: 600;
}
.register_page .form-group .dropdown .caret {
  float: right;
  margin: 11px;
}
.register_page .form-group .dropdown .dropdown-toggle {
  color: #333;
}

#login .register_type::-webkit-scrollbar-track,
#make_film_detail ul::-webkit-scrollbar-track,
#login.create_profile_page .setup-content::-webkit-scrollbar-track,
#make_film_option .dropdown-menu::-webkit-scrollbar-track {
  background: #bdc8d5;
}
#login .register_type::-webkit-scrollbar-thumb, 
#login.create_profile_page .setup-content::-webkit-scrollbar-thumb {
  background: #eee;
}
#login .register_type::-webkit-scrollbar-thumb:horizontal,
#make_film_detail ul::-webkit-scrollbar-thumb:horizontal,
#login.create_profile_page .setup-content::-webkit-scrollbar-thumb:horizontal{
  background: #eee;
}
/* width */
#login .register_type::-webkit-scrollbar,
#make_film_detail ul::-webkit-scrollbar,
#login.create_profile_page .setup-content::-webkit-scrollbar,
#make_film_option .dropdown-menu::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
#make_film_detail ul::-webkit-scrollbar-thumb,
#make_film_option .dropdown-menu::-webkit-scrollbar-thumb,
#login.create_profile_page .setup-content::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Track */
#login .register_type::-moz-scrollbar-track, #login.create_profile_page .setup-content::-moz-scrollbar-track{
  background: #bdc8d5;
}

/* Handle */
#login .register_type::-moz-scrollbar-thumb, 
#login.create_profile_page .setup-content::-moz-scrollbar-thumb {
  background: #eee;
}

#login .register_type::-moz-scrollbar-thumb:horizontal, 
#login.create_profile_page .setup-content::-moz-scrollbar-thumb:horizontal {
  background: #eee;
}

/* make a film */
.make_film_header .form-control {
  background: transparent;
  width: 300px;
  margin: 30px 0 0;
  color: #ddd;
}

#make_film_option ul li {
  width: 25%;
  float: left;
  padding: 15px 15px;
}
#make_film_option ul li p {
  display: block;
  background: #e8eeff;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
}
#make_film_option ul li p[aria-expanded="true"] {
  background: #1683f3;
  color: #fff;
}

#make_film_option ul li p .fa {
  float: right;
  margin: 6px 0 0;
}
#make_film_option .dropdown-menu li {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 8px 15px;
  font-size: 13px;
}
#make_film_option .dropdown-menu li a {
  font-weight: 600;
}
#make_film_option .dropdown-menu li a em {
  font-weight: 400;
  font-size: 11px;
  float: right;
}
#make_film_option .dropdown-menu {
  right: auto;
  left: 0;
  border: none;
  height: 270px;
  overflow: auto;
}
#make_film_option .dropdown-menu li.search_result {
  background: #e8eeff;
  padding: 3px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
}

#make_film_option .view_all {
  font-weight: 600;
  font-size: 16px;
  padding: 5px 15px;
}

#make_film_option {
  padding: 30px 0;
}

#make_film_detail {
  background: #e8eeff;
  padding: 40px 0;
}
#make_film_detail ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.cate_img .film_left_detail img {
  margin: 0 20px 0 0;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}
.cate_img .film_left_detail {
  display: flex;
  align-items: center;
}
.make_film_contact .cate_img .film_left_detail {
  width: 55%;
}
#make_film_detail ul li h6 {
  margin: 5px 0 2px;
  font-weight: 600;
}
#make_film_detail ul li small {
  font-style: italic;
}
#make_film_detail ul li .fa {
  font-size: 22px;
  color: #c3c3c3;
}
#make_film_detail .white_bg {
  background: #fff;
  padding: 20px 20px 60px;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
}
.film_cat_head h5 {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding: 0 0 15px;
}

.cat_inner_head {
  background: #e8eeff;
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 12px 0;
}

#make_film_detail ul {
  height: 480px;
  overflow: auto;
}
#make_film_detail .view_all {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  border-top: 1px solid #eee;
  bottom: 16px;
  padding-top: 14px;
}
.film_bottom_btn .blue_btn {
  margin: 15px 5px;
}
.table_info_bx .table thead th {
  background: #2b3c59;
  border-radius: 30px !important;
  color: #fff;
  padding: 8px 20px;
  margin: 0 14px;
  font-weight: 400;
  font-size: 12px;
  border: none;
}
#make_film .project_list .table tr td {
  padding: 14px 20px !important;
}
#make_film .project_list .table tr td .label {
  font-weight: 500;
  display: block;
  max-width: 100px;
  padding: 5px 5px;
}
#make_film .project_list .table tr td a svg {
  vertical-align: middle;
}

/* feature deatail page */
#celebrity_detail {
  padding: 5rem 0;
}
#celebrity_banner {
  background: #003169;
  padding: 5rem 0;
  color: #fff;
  font-size: 15px;
}
.celebrity_info img {
  width: 260px;
  height: 260px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: -5px -6px 0 #0b8ade;
  border: 6px solid #003169;
}
.celebrity_info h3 {
  font-size: 34px;
  font-weight: 600;
  color: #fff;
}
.star_rating {
  color: #ffc72f;
  margin: 15px 0;
}
.celebrity_info h3 {
  font-size: 34px;
  font-weight: 600;
  color: #fff;
  margin: 25px 0 10px;
}
.action_btns {
  margin: 30px 0 0;
}
.action_btns a {
  min-width: 300px;
  text-align: center;
  border-radius: 50px;
  margin: 8px 0;
  padding: 10px 10px;
  text-transform: none;
  font-weight: 600;
}
.action_btns .pink_btn {
  background: #f11a66;
}
.action_btns .stroke_btn {
  border-color: #fff;
  color: #fff;
}
.celebrity_info a {
  color: #0e9bf5;
}
.dark_bg {
  background: #fff;
  padding: 20px;
  border-radius: 0;
  color: #000;
  border:1px solid #e7e7e7;
}
.light_bg {
  background: #e7edfe;
}
.all_reviews .dark_bg {
  border-radius: 20px;
  padding: 30px;
}
.all_reviews ul li {
  color: #cdcdcd;
  border-bottom: 1px solid #2f3441;
  padding: 20px 0;
}
.review_user {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
}
.all_reviews ul li h6 {
  color: #fff;
  font-weight: 600;
  margin: 0 0 5px;
  font-size: 17px;
}
.all_reviews ul li span {
  color: #f84584;
  font-weight: 600;
}
.review_user {
  display: flex;
  align-items: center;
}
.review_user img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  border: 4px solid #f11a66;
}
.all_reviews h5 {
  color: #339ae3;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 10px;
}
.all_reviews .pagination > li > a,
.all_reviews .pagination > li > span {
  position: relative;
  float: left;
  padding: 2px 7px;
  margin: 0 5px;
  line-height: 1.42857143;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #4b5265;
  border-radius: 50%;
  font-size: 12px;
}
.all_reviews .pagination > li.active > a {
  background: #339ae3;
  border-color: #339ae3;
}
.all_reviews .pagination {
  margin: 20px 0 0;
}
.celebrity_video img {
  border-radius: 15px;
  border: 4px solid #fff;
  box-shadow: 0 5px 12px -2px rgba(0, 0, 0, 0.2);
}
.youtube_icon img {
  border: none;
  border-radius: 50%;
  width: 36px;
  position: absolute;
  bottom: 16px;
  left: 34px;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.4);
}
.celebrity_video {
  position: relative;
  margin-bottom: 30px;
}
.celebrity_video span {
  background: #e8eeff;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  right: 35px;
  border-radius: 20px;
  padding: 1px 12px;
  font-weight: 600;
  color: #003169;
  font-size: 13px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}
#how_work {
  color: #fff;
}
#how_work .modal-content h4 {
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
#how_work .modal-content {
  background: #003169;
  padding: 30px 50px;
}
.action_btns p a {
  color: #fff;
}
#how_work .modal-content ul li .left_icon {
  margin-right: 15px;
  width: 40px;
  height: 40px;
  background: #f11a66;
  border-radius: 50%;
  line-height: 38px;
  display: inline-table;
  position: relative;
  z-index: 9;
  font-size: 16px;
}
#how_work .modal-content ul li {
  padding: 9px 14px;
  display: inline-flex;
  align-items: center;
  min-height: 85px;
}
#how_work .modal-content ul li .left_icon:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 102%;
  background: #144782;
  top: 42px;
  left: 20px;
}
#how_work .modal-content ul li:last-child .left_icon:before {
  display: none;
}
#how_work .close {
  position: absolute;
  right: -21px;
  top: -9px;
  color: #fff;
  opacity: 0.6;
  font-size: 25px;
  font-weight: 400;
}

/* feature main page */
#main_feature_detail {
  padding: 5rem 0 5rem;
}
#celebrity_feature_banner {
  background: "url(${process.env.PUBLIC_URL" +"/ feature_main_banner.jpg" "})"
    no-repeat;
  padding: 14rem 0;
  background-position: center center;
  background-size: cover;
}
#celebrity_feature_banner h2 {
  color: #fff;
  font-weight: 700;
  line-height: 40px;
}
#main_feature_detail ul li:after,
#main_feature_detail ul li:before,
#main_feature_detail ul:after,
#main_feature_detail ul:before {
  content: "";
  display: table;
  clear: both;
}
#main_feature_detail ul li:hover {
  transform: translate(0, -5px);
}
#main_feature_detail ul li {
  float: left;
  width: 20%;
  transition: all ease-in 0.2s;
}
#main_feature_detail ul li .stars_profile {
  padding: 15px;
}
#main_feature_detail ul li .stars_profile .bg_bx {
  background: #131722;
  border: 4px solid #191f2d;
  border-radius: 12px;
  color: #fff;
}
#main_feature_detail ul li .stars_profile .bg_bx span {
  width: 45px;
  height: 45px;
  background: #f11a66;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  float: right;
  margin-top: -32px;
  position: relative;
  z-index: 9;
  margin-right: -14px;
}
.feature_heading_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 15px;
  padding: 0 15px;
  width: 100%;
}
.feature_heading_part h3 {
  color: #fff;
  font-weight: 600;
  position: relative;
  padding-left: 60px;
}
.feature_heading_part h3:before {
  content: "";
  width: 40px;
  height: 4px;
  background: #f11a66;
  position: absolute;
  left: 0;
  top: 12px;
}
#main_feature_detail ul li .star_detail {
  padding: 10px 20px 15px;
  color: #cdcdcd;
}
#main_feature_detail ul li .star_detail h6,
#main_feature_detail ul li .star_detail p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#main_feature_detail ul li .star_detail h6 {
  font-weight: 600;
  color: #fff;
  margin: 10px 0 4px;
}
.feature_heading_part .blue_btn {
  text-transform: none;
  border-radius: 20px;
  font-weight: 600;
}
#main_feature_detail .cat_list ul li a {
  color: #fff;
  background: #202532;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  margin: 6px 0;
}
#main_feature_detail .cat_list ul li button {
  color: #fff;
  background: #202532;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  margin: 6px 0;
}
#main_feature_detail .cat_list ul li {
  float: none;
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
#main_feature_detail .cat_list ul li:hover {
  transform: translate(0, -3px);
}
#main_feature_detail .cat_list ul li a span {
  display: inline-block;
  margin-left: 10px;
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 600;
}
#main_feature_detail .cat_list ul li a.active {
  background: #1683f3;
}

/* new css */
.form_part .form-group {
  width: 100%;
  margin: 0 4px;
}
.form_part {
  display: flex;
  align-items: normal;
  width: 100%;
}
.form_part {margin: 0 -4px 20px;}
.form_part .owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 230px;
}
.mid_area p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #d5d5d5;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
}
.full_movie_detail .list_part{padding: 0;}
.full_movie_detail .list_part span {
  background: #161f2c;
    padding: 6px 15px;
    border-radius: 50px;
    margin: 4px 2px;
    display: inline-block;
    font-size: 11px;
    color: #d5d5d5;
    line-height: 14px;
}
.full_movie_detail .list_part span span {
  padding: 0;
  margin: 0;
}
a.link_clr {
  color: #1f8dff;
  margin: 10px 0 0;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
}
.film_cate_part .link_clr {
  margin-left: 10px;  
}
.btn_part a.blue_btn {
  padding: 11px 40px;
  margin-right: 20px;
  font-size: 17px;
}
#cta .right_pattern {
  opacity: 1;
  left:0;
}
.cta_info {
  z-index: 1;
}
#cta h2 {
  color: #fff;
  font-weight: 600;
  margin: 0 0 24px;
  font-size: 30px;
  line-height: 36px;
}
#cta p {
  font-size: 16px;
  color: #d5d5d5;
}
#cta .blue_btn {
  margin: 40px 0 0;
  font-size: 17px;
  font-weight: 500;
  padding: 13px 40px;
}
#cta .home_grey_bx:before {
  background: linear-gradient(to left, #240653 1%, rgba(19, 53, 168, 0.9) 54%, rgba(0, 0, 0, 0) 86%);
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
}
#cta.inner_banner .home_grey_bx:before {
  background: linear-gradient(to right, #240653 1%, rgba(19, 53, 168, 0.9) 54%, rgba(0, 0, 0, 0) 86%);
}
#cta.inner_banner .right_pattern {
  opacity: 1;
  left: auto;
}
#cta.inner_banner .home_grey_bx {
  padding: 6rem 30px;
  margin-bottom: 30px;
}
#cta.inner_banner h2 {
  margin: 0 0 20px;
  font-size: 30px;
}
#cta .blue_btn {
  margin: 30px 0 0;
}
#cta .blue_btn {
  margin: 26px 0 0;
  font-size: 16px;
  padding: 9px 25px;
}
.heading h2 {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
}
.heading {
  position: relative;
  z-index: 1;
  margin: 0 0 30px;
}
#cta.inner_banner p {
  font-size: 16px;
  color: #d5d5d5;
  padding-right: 5rem;
  line-height: 24px;
}
#track_page_tab {
  padding-top: 30px;
}
.tab-content .stroke_btn{margin: 0;}
#login.register_page .stepwizard-row .btn-primary p {
  color: #fff;
}
.people_detail_banner .stroke_btn {
  margin: 0;
  width: 100%;
}
.fund_info {
  display: flex;
  color: #fff;
  padding-bottom: 22px;
  margin-top: -50px;
}
.inside_listing_tab table tr td {
  padding: 14px 10px;
}
.application_btn .stroke_btn {
  margin: 0;
}
#popular_tag .nav-tabs > li > a{transition: all ease-in-out 0.4s;}
#popular_tag .nav-tabs > li > a:hover {
  background: transparent;
  border-left: 3px solid;
  border-radius: 0;
}
.top_left_info h2 {
  color: #fff !important;
}
.head_right_part {
  margin: 0 0 22px;
}
.head_right_part .stroke_btn {
  margin: 0;
}
.wallet_page .filter_points {
  border-bottom: none;
  padding: 0 6px;
  width: 20%;
}
.flex_area div {
  padding: 0 10px;
}
.flex_area div input{ height: 40px;}
.flex_area {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  width: 71%;
}
.flex_area.side_btns {
  width: 33%;
  justify-content: left;
}
.flex_area.side_btns div {
  padding: 0 3px;
}
.side_btns button {
  padding: 7px 20px;
  min-height: 40px;
  border-radius: 4px;
  font-size: 13px;
    font-weight: 600;
}
.hire_list .dropdown-menu {
  padding: 8px !important;
}
.hire_list .dropdown-item button svg {
  margin-right: 9px;
  margin-top: 3px;
}
.hire_list .dropdown-item {
  padding: 3px 0;
  text-align: left;
}
.hire_list .dropdown-item button {
  border: none;
  background: transparent;
  padding: 0 !important;
  margin: 0;
  text-align: left;
  font-size: 12px;
  color: #6e6e6e;
  line-height: 18px;
  display: flex;
}
.hire_list .dropdown-item button:hover {
  color: #258af3;
}
.side_action_btn .stroke_btn {
  margin-left: 20px;
}
#popular_tag {
  padding: 5rem 0;
}
#breakdown_list {
  padding: 0 0 5rem;
}
.left_space{padding-left:0;}
.inline_feild svg {
  margin-right: 5px;
}
#breakdown_list.notification_list {
  padding: 40px 0 ;
}

/* nav new css */
.top_nav .single_drop .dropdown-menu>li>a {
  color: #626262;
  font-size: 13px;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
}
.top_nav .single_drop .dropdown-menu>li>a>span {
  padding-right: 10px;
  padding-left: 2px;
}
.top_nav .single_drop .dropdown-menu>li>a>span svg {
  fill: #1f8dff;
}
.top_nav .single_drop .dropdown-menu>li:hover a {
  transform: translate(10px, 0);
  -webkit-transform: translate(10px, 0);
}

.top_nav .single_drop .dropdown-menu>li:hover {
  border-left: 4px solid #1f8dff;
}
.top_nav .single_drop .dropdown-menu>li:last-child {
  border-bottom: none;
}
.top_nav .single_drop .dropdown-menu>li {
  width: 100%;
  padding: 7px 0;
  border-bottom: 1px solid #f1f1f1;
  border-left: 4px solid transparent;
}
.top_nav .single_drop .dropdown-menu>li:hover a {
  color: #626262 !important;
  font-weight: 500;
}
.top_nav .single_drop ul.dropdown-menu {
  border-radius:3px !important;
  box-shadow:none;
  margin-top:10px;
  width:180px;
  box-shadow: 2px 2px 8px rgba(0,0,0,.175);
  border:none;
}
.top_nav .single_drop ul.dropdown-menu:before {
  content: "";
  border-bottom: 8px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  top: -8px;
  right: 22px;
  z-index: 10;
}
#auditions .bg_bx {
  background: #0b1829;
  padding: 20px;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  margin: 15px 0;
  height: 100%;
}
#auditions .bg_bx .list_part span {
  background: #06101e;
  padding: 1px 12px;
  border-radius: 50px;
  margin: 4px 2px;
  display: inline-block;
  font-size: 11px;
  color: #d5d5d5;
}
#auditions .bg_bx .list_part span:empty {
  display: none;
}
#auditions .info_bx {
  margin: 15px 0;
}
#auditions .head_part h2 {
  color: #fff;
  margin: 0 0 10px;}
.flex-row {
  display: flex;
  flex-wrap: wrap;
}
#auditions .bg_bx p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #d5d5d5;
    font-size: 14px;
    font-weight: 300;
    line-height: 23px;
}
#auditions .bg_bx h6 {
  font-size: 20px;
  FONT-WEIGHT: 600;
}
#auditions .bg_bx .list_part {
  padding: 0;
}
#auditions .stroke_btn {
  margin: 20px 0 0;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
#services_pages{padding: 0 0 30px;}
#services_pages a {
  display: block;
  color: #fff;
}
#services_pages .bx_bg {
  background: #0b1829;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin: 0 0 30px;
  max-height: 250px;
  min-height: 250px;
}
#services_pages a h6 {
  font-size: 16px;
  line-height: 21px;
  margin-top: 15px;
  margin-bottom: 5px;
}
#services_pages .bx_bg img {
  width: 100%;
  object-fit: cover;
  height: 158px;
  margin-left: -9px;
  object-position: top;
}
.filter_flex{display: flex; align-items: flex-end;}
.job_list .filter_points h6 {
  color: #acb3d4 !important;
}

.login_form input[type=checkbox] {
    vertical-align: sub;
}
.create_profile_head .default_btn {
  margin-left: 10px;
  padding: 6px 25px;
}
.add_info h6, .skills_part h6 {
  color: #333 !important;
  font-weight: 600;
  font-size: 17px;
  margin: 0;
}
label.add_head a {
  font-size: 19px;
}
label.add_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.amount_modal .modal-body {
  padding: 20px 30px;
}
.amount_modal .modal-footer {
  padding: 20px 30px;
}
.amount_modal .modal-header {
  padding: 15px 30px;
}
.apply_job button.stroke_btn {
  min-width: 100%;
}
#postjob_detail .shadow_bg h5 {
  word-break: break-all;
}

/***************  responsive ************************/
@media (min-width: 768px){
.alert_box .modal-dialog{
  width: 423px;
}
.alert_box .modal-dialog .skills_part{margin: 10px 0;}
.alert_box .modal-dialog .skills_part h4 {
  text-align: center;
  line-height: 23px;
}
.upi_pay .skills_part {
  text-align: center;
}
.upi_pay .skills_part img {
  max-width: 400px;
  margin: 0 auto;
}
.upi_pay .skills_part p {
  font-size: 17px;
  line-height: 20px;
}
}
@media (min-width: 1299px){
  .container {
      width: 1220px;
  }
  }
@media (max-width: 1399px) {
  .search input {
    min-width: 450px;
  }
  #make_film_section img {
    position: absolute;
    right: -50px;
    top: -9rem;
    max-width: 71rem;
}
#make_film_section {
  background: linear-gradient(19deg, #06101e 60%, #dc1838 86%);
  padding: 8rem 0 6rem;
}
  
}

@media (max-width: 1199px) {
  .side_details.hire_talent_side {
    width: min-content;
    min-width: 33.3%;
}
.left_casting_btn {
    padding: 20px 15px;
    margin-left: 10px;
    width: 30%;
}  
.filter_points {
  line-height: 15px;
}
  .heading.header_btn .blue_btn {
    margin-left: 12px;
    height: 37px;
    padding: 7px 13px;
}
#top_cards .item:nth-child(5) h5 {
  white-space: normal;
  padding-top: 24px;
    line-height: 18px;
}
#top_cards .gallery_info h5{font-size: 15px;}
.wallet_page .table>thead>tr>th {
    white-space: nowrap;
}
  header .flex_bx {
    width: 82%;
}
  .menu_items {
    width: 74%;
}
  .full_movie_detail .list_part span {
    font-size: 10px;
  }
  .side_btns button {
    padding: 7px 15px;
  }
  #login .css-2b097c-container {
    height: 44px !important;
}
#top_cards .gallery_info .stroke_btn {
  font-size: 13px;
  padding: 7px 5px;
  min-width: auto;
  width: 114px;
  line-height: 16px;
}
  #cta .right_pattern {
    max-width: 70%;
}
  .search input {
    min-width: 300px;
  }
  .navbar-nav > li > a {
    padding: 9px 8px;
  }
  header .logo {
    padding: 0 8px;
  }
  .jctkr-label {
    font-size: 11px;
  }
  #slider_part .banner_text h1 {
    line-height: 36px;
    font-size: 30px;
  }
  #auditions .event_cal {
    padding: 0 20px;
  }
  .event_bg {
    padding: 0 20px;
  }
  .event_cal ul li .date {
    font-size: 18px;
  }
   .need_work {
    padding: 0 15px 0 3rem;
  }
  #make_film_section img {
    right: -30px;
    top: -3rem;
    max-width: 60rem;
  }
  .film_cate_part span {
    padding: 5px 24px;
    margin: 7px 5px;
  }
  .make_film_info {
    padding-right: 4rem;
  }
  .film_cate_part {
    margin: 22px 0;
  }
  .make_film_info p {
    font-size: 18px;
  }
  .mid_area h6 {
    font-size: 18px;
    margin: 5px 0 7px;
    line-height: 25px;
  }
  #movie_status .trendy_txt h6 {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 15px;
  }
  .trendy_txt small {
    line-height: 16px;
    display: inline-block;
  }
  #slider_part .carousel-inner .item img {
    object-position: 70%;
}
.tab-content .stroke_btn {
  font-size: 16px;
  padding: 8px 20px;
}
.css-2b097c-container{ height: 45px !important; }
.find_talent_list .cele_price {
  margin: 0 0 20px;
  font-size: 12px;
  padding: 3px 10px;
  line-height: 18px;
}
.celebrity_detail .people_list_info {
  display: block;
  min-height: 60px;
  padding: 0 15px;
}
.talent_list p{padding:0;}
}

@media screen and (max-width: 767px) {

.navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: #fff;
}
  #login {
    position: relative;
    display: block;
  }
  .stepwizard {
    padding: 0;
}

  .top_left_info {
    text-align: left;
}
.top_left_info h2 {
  font-size: 24px;
}
  .table-responsive thead,
  .table-responsive tbody,
  .table-responsive tfoot,
  .table-responsive tr,
  .table-responsive th,
  .table-responsive td {
    display: block;
  }
  .table-responsive thead {
    display: none;
  }
  .table-responsive .align-center,
  .table-responsive .align-right {
    text-align: inherit;
  }
  .table-responsive tr {
    border-top: 1px solid #ddd;
  }
  .table-responsive tr:first-child {
    border: 0;
  }
  .table-responsive > tbody > tr > td {
    padding-left: 50%;
    border-top-color: rgba(221, 221, 221, 0.5);
  }
  .table-responsive > tbody > tr > td:first-child {
    border: 0;
  }
  .table-responsive [data-label] {
    position: relative;
  }
  .table-responsive [data-label]:before {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    content: attr(data-label);
    font-weight: bold;
  }

  .navbar-fixed-top.visible-xs + .row {
    padding-top: 60px;
  }

  .btn .visible-xs {
    display: inline-block !important;
  }
  .sliding-sidebar {
    position: relative;
    z-index: 1;
    background: #0e1a35;
    min-height: 100%;
    border-right: none;
    transform: translate3D(0, 0, 0);
    transition: all 200ms;
    width: 100%;
    margin: 0 0 20px;
}
  .sliding-sidebar.collapse,
  .sliding-sidebar.collapsing {
    display: none !important;
    -webkit-transform: translate3D(-100%, 0, 0);
    transform: translate3D(-100%, 0, 0);
    transition: all 200ms;
  }
  .sliding-sidebar.collapse.in {
    -webkit-transform: translate3D(0, 0, 0);
    transform: translate3D(0, 0, 0);
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.05), 2px 0 0 rgba(0, 0, 0, 0.05),
      3px 0 0 rgba(0, 0, 0, 0.05);
      transition: all 200ms;
  }
}



@media (max-width: 1199px) {
  #track_page_header .top_left_info {
    padding: 40px 0 0 0;
  }
  #track_page_header .banner_img img,
  #track_page_header.my_list .banner_img img {
    max-width: 100%;
  }
  .top_links ul li {
    padding: 30px 20px 25px;
  }
  .footer_links ul li,
  .copyright ul li {
    padding-right: 20px;
  }
  .list_part ul li .list_info a {
    width: 330px;
  }
  .brief_intro {
    font-size: 14px;
    line-height: 23px;
}
.top_head_info p, .casting_list_head p {
  font-size: 12px;
  line-height: 20px;
}
}

@media (max-width: 991px) {
  .side_details.hire_talent_side {
    min-width: 41.6%;
}  
  .breakdown_casting_info .flex_bx {
    display: block;
} 
.casting_list_head .disc_brief span {
  display: inline-block;
}
.casting_list_head {
  width: 100%;
}
.notification_list ul li .casting_list_head {
    display: inline-flex;
    width: 100%;
 }  
 .casting_list_head .disc_brief span, .casting_list_head .disc_brief strong {
  display: inline-block;
}
.left_casting_btn {
  padding: 10px 15px;
  margin-left: 0;
  width: 100%;
  min-height: auto;
}
.left_casting_btn .stroke_btn {
  margin: 7px 5px;
  min-width: 30%;
  text-align: center;
}
.casting_list_btn .action_btn ul li {
  display: inline-block !important;
}
  .screen_ques input[type="text"] {
    width: 70%;
}
#top_cards .gallery_info .stroke_btn {
  font-size: 14px;
  padding: 9px 16px;
  min-width: 170px;
  width: auto;
  line-height: 16px;
}
.tab-content .stroke_btn {
  font-size: 15px;
  padding: 8px 20px;
}
  .inside_listing_tab .nav-tabs li a {
    padding: 20px 16px 15px;
  }
  #login .register_type h4 {
    width: 71%;
}
  header .flex_bx {
    width: 77%;
}
.audition_people_list .inner_tab li a {
  padding: 6px 18px;
  font-size: 13px;
}
.jobdetail_tab .dark_search input {
  width: 90%;
}
.job_list.job_list_info h6 {
  margin-top: 30px;
}
.mid_job_info ul li {
  display: block;
  text-align: center;
  margin: 0 auto;
}
  #cta h2 {
    font-size: 22px;
    line-height: 28px;
}
.mid_job_info ul li .side_icon {
  margin: 0 auto;
}
.menu_items {
  width: 67%;
}
.job_list ul li strong {
  margin-right: 0;
  font-size: 12px;
  line-height: 18px;
  display: block;
  margin-top: 8px;
}
.job_list ul li span {
  margin-right: 0;
  display: block;
}
.job_list ul li .job_info {
  padding: 0 10px;
}
p {
  font-size: 14px;
  line-height: 24px;
}
.stepwizard-step .btn-primary:after {
  border-top: 40px solid transparent;
  border-left: 11px solid #1683f3;
  border-bottom: 40px solid transparent;
  right: -11px;
}
.stepwizard-step .btn-primary:before {
  border-top: 40px solid transparent;
  border-left: 9px solid #b7d2ef;
  border-bottom: 40px solid transparent;
  left: 0;
}
#login.register_page .stepwizard-row .btn {
  min-height: 79px;
}
.stepwizard-step p {
  font-size: 13px;
  white-space: pre-line;
  line-height: 17px;
}

.searchjob_head {
  width: 85%;
}
.searchjob_bx .side_action_btn {
  width: 14%;
}
.job_des {
  width: 100%;
}
.search_btm_info p, .search_btm_info .rating {
  font-size: 12px;
}
.searchjob_bx .application_info p {
  font-size: 13px;
}
.job_des {
  font-size: 14px;
  line-height: 21px;
}
.flex_area {
  margin: 0 -10px;
  width: auto;
}
.flex_area.side_btns {
  margin: 15px 0 0;
}
#cta p {
  font-size: 15px;
  color: #d5d5d5;
}
#slider_part .banner_text p {
    font-size: 14px;
    line-height: 20px;
}
  .double_field label {
    display: block;
    margin: 0 0 4px;
}
label.check_bx {
  margin-top: 5px;
}
.heading h2 {
  font-size: 24px;
}
.filter_points .check_bx {
  font-size: 13px;
}
  .search input {
    min-width: 356px;
  }
  .navbar-nav > li > a {
    padding: 9px 8px;
  }
  .logo img {
    display: inline-block;
  }
 
 .top_nav ul li a img {
    margin-right: 0px;
    width: 18px;
  }
   #track_page_header h2,
  #inner_banner h2 {
    font-size: 28px;
  }
  #track_page_header .top_left_info {
    font-size: 16px;
    padding: 0;
    line-height: 26px;
  }
  #track_page_header h2,
  #inner_banner h2 {
    margin: 0 0 10px;
  }
  #track_page_header .stroke_btn {
    padding: 7px 30px;
    margin: 26px 0 0;
    font-size: 14px;
  }
  .top_links ul li {
    padding: 30px 12px 25px;
  }
  .footer_info_part h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
  .list_part ul li .item_info span {
    padding: 6px 14px;
  }
  .list_filter .stroke_btn {
    padding: 10px 15px;
  }
  #slider_part .carousel,
  #slider_part .carousel-inner,
  #slider_part .carousel-inner .item {
    height: 400px;
  }
  #slider_part .banner_text {
    left: 3rem;
    top: auto;
  }
  #slider_part .banner_text h1 {
    line-height: 30px;
    font-size: 24px;
  }
  #slider_part .banner_text {
    font-size: 17px;
  }
  .work_head h2 {
    margin: 45px 0 20px;
  }
  #top_banner h2,
  #news_gallery h2,
  #top_banner h2,
  #movie_status h2 {
    font-size: 24px;
  }
  #top_banner .nav-tabs > li > a,
  #news_gallery .nav-tabs li a,
  #movie_status .nav-tabs li a {
    margin-right: 0;
    font-size: 13px;
    padding: 10px 4px;
  }
  .mid_area:last-child {
    display: none;
  }
  .big_img img {
    height: 220px;
    width: 100%;
  }
  .big_img {
    margin: 0 0 10px;
  }
  #make_film_section img {
    top: -4rem;
    max-width: 52rem;
    right: -131px;
}
  .make_film_info h2 {
    margin: 0 0 15px;
    font-size: 30px;
  }
  .make_film_info p {
    font-size: 15px;
  }
  #make_film_section {
    background: linear-gradient(34deg, #131722 57%, #dc1838 92%);
    height: auto;
}
  .wrap {
    width: 83%;
  }
  .jctkr-label {
    width: 16%;
  }
  #cta .right_pattern {
    max-width: 90%;
}
.home_grey_bx {
  padding: 6rem 25px;
}
#popular_tag .nav-tabs > li {
  float: none;
  font-size: 18px;
}
.pill_result li span {
  font-size: 13px;
  padding: 5px 16px;
}
.section_info h2 {
  font-size: 36px;
  line-height: 44px;
}
.section_info p {
  font-size: 16px;
  line-height: 26px;
}
.event_cal ul li .date {
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.event_cal ul li .event_detail {
  margin-left: 15px;
  width: 75%;
}
.event_cal ul li .event_detail h4 {
  font-size: 16px;
}
.event_cal ul li .event_detail small {
  font-size: 12px;
}
#top_cards .item {
  width: 33%;
  float: left;
  margin: 7px 0;
}
#top_cards .item:nth-child(5) h5 {
  white-space: normal;
  padding-top: 40px;
}
.head_part h2 {
  font-size: 27px;
}
#top_cards .head_part h2 {margin:0 0 15px;}
#cta.inner_banner h2 {
  margin: 0 0 20px;
  font-size: 25px;
}
#cta.inner_banner p {
  font-size: 14px;
  padding-right: 1rem;
  line-height: 24px;
}
}

@media (max-width: 767px) {
  .side_details.hire_talent_side {
    min-width: 100%;
    margin: 25px 0 0;
}
 .wallet_page .table-responsive>.table>tbody>tr>td .dropdown {
    white-space: normal;
} 
  .terms_part ul li {
    width: 48%;
  }
.flex_row {
    display: flex;
    flex-direction: column;
}
.celebrity_form {
  order: 1;
}
.office_detail {
  order: 2;
}
  .stroke_btn {
    padding: 7px 18px;
} 
  .create_profile_head .stroke_btn {
    font-size: 13px;
    padding: 3px 13px !important;
}
  .inner_celebrity_info {
    padding-top: 3rem;
    width: 100%;
}
.inner_celebrity_info h1 {
  font-size: 28px;
  margin: 0 0 10px;}
.inner_celebrity_info p {
  font-size: 16px;
  line-height: 27px;
  font-weight: 300;
}
.jobdetail_tab .dark_search {
    margin: 20px 0 10px;
}
.detail_sidebar ul li {
  display: inline-block;
  margin: 0 7px;
}
#top_cards .gallery_info .stroke_btn {
  font-size: 13px;
  padding: 9px 10px !important;
  min-width: 144px;
  width: auto;
}
.map_info p {
  min-height: auto !important;
  margin: 20px 0 10px;
}
.jobdetail_tab .dark_search input {
    width: 100%;
}
  .postjob_tab .nav-tabs li a {
    padding: 14px 27px;
  }
  .postjob_tab .nav-tabs li {
    width: auto;
  }
  .create_profile_page:after{display:none;}
  #login {
    height: 100%;
  }
  #postjob_detail .jobdetail_tab .detail_sidebar {
    position: relative;
    height: auto;
    border-left: none;
    border-top: 1px solid #e5e7eb;
    margin: 30px 0 0;
}
.wallet_page .table>tbody>tr>td, .wallet_page .table-responsive thead tr td{
    display: table-cell;
}
.wallet_page .table>tbody>tr, .wallet_page .table-responsive thead tr {
  display: table-row;
}
.wallet_page .table-responsive thead {
  display: table-header-group;
}
.wallet_page .table-responsive thead, .wallet_page .table-responsive tbody, .wallet_page .table-responsive tfoot, .wallet_page .table-responsive tr, .wallet_page .table-responsive th, .wallet_page .table-responsive td {
  display: revert;
}
.wallet_page .table-responsive {
  margin-top: 22px;
}
  #login .bg_box {
    margin: 40px auto;
}
.login_form {
  float: none !important;
}
  .navbar-nav > li > .stroke_btn {
    padding: 7px 19px;
    margin: 24px 15px 0;
}
  header .flex_bx {
    display: block;
    width: 100%;
}
  .menu_items {
    width: 60%;
}
.navbar-toggle {
  margin-top: 22px;
}
  .home_grey_bx .box_align, #auditions .box_align {
    display: block;
    align-items: center;
  }
  .section_info {
    margin: 40px 6px 0;
}
.stepwizard-step p {
  display: inline-block;
  margin-left: 10px;
}
.stepwizard-step {
  width: 33%;
}
#login .register_type h4 {
  font-size: 22px;
  padding: 30px 20px 16px;
}
.create_profile_head {
  padding-right: 20px;
  font-size: 14px;
}
.create_profile_head  .default_btn {
  padding: 4px 13px;
  font-size: 13px;
}
#login.register_page .stepwizard-row .btn {
  min-height: auto;
  text-align: left;
  padding-left: 20px;
}
.stepwizard-step .btn-primary:after, .stepwizard-step .btn-primary:before{display: none;}
.form-control {
  font-size: 12px;
}
#cta.inner_banner h2 {
  font-size: 22px;
  line-height: 26px;
  margin: 0 0 15px;
}
#cta.inner_banner p {
  font-size: 13px;
  line-height: 20px;
}
#cta .blue_btn {
  margin: 18px 0 0;
  font-size: 15px;
  padding: 9px 17px;
}
  #slider_part .banner_text h1 {
    line-height: 25px;
    font-size: 20px;
    margin: 0 0 10px;
}
.switch {
  margin-left: 5px;
}
.manage_space{padding-left:15px !important;}
#slider_part .banner_text p {
  font-size: 13px;
  line-height: 19px;
}
#slider_part .stroke_btn {
  padding: 4px 12px;
  font-size: 15px;
  width: 165px;
}
  #track_page_tab .nav-tabs > li,
  #inner_form .nav-tabs > li {
    display: inline-block;
  }
  #track_page_tab .nav-tabs > li > a,
  #inner_form .nav-tabs > li > a {
    padding: 8px 20px;
    font-size: 14px;
  }
  #track_page_tab .nav-tabs > li.active > a,
  #track_page_tab .nav-tabs > li.active > a:focus,
  #track_page_tab .nav-tabs > li.active > a:hover,
  #create_breakdown .nav-tabs > li.active > a,
  #create_breakdown .nav-tabs > li.active > a:focus,
  #create_breakdown .nav-tabs > li.active > a:hover,
  #inner_form .nav-tabs > li.active > a,
  #inner_form .nav-tabs > li.active > a:focus,
  #inner_form .nav-tabs > li.active > a:hover,
  #news_page .nav-tabs > li.active > a,
  #news_page .nav-tabs > li.active > a:focus,
  #news_page .nav-tabs > li.active > a:hover {
    border-radius: 25px;
  }
  #track_page_tab .tab-content {
    padding: 30px 20px;
  }
  
  #track_page_tab .side_bar_bg,
  #list_table .side_bar_bg {
    display: none;
  }
  #track_page_tab,
  #list_table {
    padding: 0 0 60px;
  }
  #track_page_tab .right_space,
  #list_table .list_filter {
    padding: 20px 20px;
    margin: 0 -5px 5px;
  }
 
.inner_page_head .heading {
    margin: 15px 0 5px;
}
.inner_page_head .heading h2 {
  font-size: 24px;
}
  .inner_head {
    margin: 20px 0 30px;
    text-align: center;
  }
  #track_page_header .banner_img img {
    max-width: 250px;
  }
  #track_page_header .banner_img {
    text-align: center;
  }
  #track_page_header {
    padding-top: 25px;
  }
  .track_point ul li .track_info a {
    font-size: 17px;
  }
  .top_links ul li {
    padding: 5px 12px;
  }
  .top_links {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  footer {
    text-align: center;
  }
  .footer_info_part h3 {
    margin: 18px 0 5px;
  }
  .copyright {
    margin: 15px auto 0;
    padding: 0;
    font-size: 13px;
  }
  
  footer .logo {
    text-align: right;
  }
  .list_part,
  .left_space {
    padding-left: 15px;
  }
  .main_head {
    display: block;
  }
  #top_banner .nav-tabs > li > a,
  #news_gallery .nav-tabs li a,
  #movie_status .nav-tabs li a {
    padding: 10px 8px;
  }
  #news_gallery .tab-content {
    padding: 12px 15px 0;
  }
  #news_gallery .blue_btn {
    margin: 5px 0 10px;
  }
  #news_gallery .bottom_btn:after {
    top: 26px;
  }
  #make_film_section img {
    top: -17rem;
    max-width: 46rem;
    right: -75px;
    display: none;
  }
  #make_film_section {
    background: linear-gradient(34deg, #131722 57%, #dc1838 92%);
    padding: 11rem 0 0;
}
#cta .right_pattern {
  max-width: 100%;
  opacity: 0.2;
}
#popular_tag .nav-tabs > li {
  display: inline-block;
}
#popular_tag .nav-tabs > li > a {
  padding: 10px 12px;
}
#popular_tag .nav-tabs > li.active > a, #popular_tag .nav-tabs > li.active > a:focus, #popular_tag .nav-tabs > li.active > a:hover {
  color: #2196F3 !important;
  border-left: none;
  border-bottom: 3px solid #2196F3;
}
#popular_tag .nav-tabs {
  margin: 0 0 30px;
}
  .make_film_info {
    bottom: 54px;
  }
  .mobile_view {
    display: block !important;
  }
  .web_view {
    display: none !important;
  }
  .search input {
    min-width: 100%;
  }
  .top_nav .search {
    position: absolute;
    width: 90%;
    padding: 9px 10px 0;
    z-index: 1;
    top: 50px;
    left: 0;
  }
  .search .btn {
    right: 10px;
    top: 6px;
  }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 6px;
  }
  .navbar-toggle .icon-bar {
    background: #fff;
  }
  .top_nav .navbar-collapse ul {
    width: 100%;
  }
  .top_nav .navbar-collapse ul li {
    display: block;
    padding: 0;
    float: none;
    width: 100%;
    text-align: center;
  }
  .top_nav ul .mega-dropdown {
    width: 100%;
  }
  .navbar-nav .menu_drop .open .mega-dropdown-menu {
    position: absolute;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.9);
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    left: 0;
    width: 100%;
    right: 0;
    height: 400px;
    overflow: auto;
  }
  .mega_links {
    width: 50%;
  }
  #slider_part .banner_text {
    left: 10px;}
.head_part h2 {
      margin: 0 0 15px;
      font-size: 26px;
  }
  #auditions .head_part h2 {
    margin: 0;
}
#auditions .bg_bx{height: auto; margin:0;}
.no_space {
  padding: 0;
}
#breakdown_list{overflow: hidden;}
.list_bg {
  border-radius: 0;
  box-shadow: none;
}
.filter-toggler svg {
  vertical-align: middle;
}
.heading h2 {
  font-size: 24px;
  width: 58%;
}
.cta_info {
    width: 66%;
}
.foot_logo {
  text-align: center;
  margin: 0 0 20px;
}
.navbar-nav .single_drop.open .dropdown-menu {
  position: relative;
  width: 100%;
  min-width: auto;
  background: transparent;
}
header .navbar-header {
  width: 100%;
  padding: 0;
}
.top_nav .single_drop .dropdown-menu>li span {
  display: none;
}
.navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu>li>a {
  padding: 5px 15px 5px;
  color: #fff !important;
}
.navbar-nav .single_drop.open .dropdown-menu br, .top_nav .single_drop ul.dropdown-menu:before {
  display: none;
}
.top_nav .single_drop .dropdown-menu>li {
  border-bottom: 1px solid #1a2d49;
}
.top_nav .navbar-collapse ul .single_drop a:after, .top_nav .navbar-collapse ul .mega-dropdown a:after {
  content: 'Profile';
  color: #fff;
  font-family: 'Poppins';
  vertical-align: text-top;
}
.top_nav .navbar-collapse ul .mega-dropdown a:after {
  content: 'All Services';
  font-size: 14px;
  vertical-align: middle;
}
.top_nav .navbar-collapse ul .mega-dropdown ul a:after, .top_nav .navbar-collapse ul .single_drop ul  a:after {
  display: none;
}
.top_nav .single_drop ul.dropdown-menu {
  margin-top: 0;}
.top_nav .navbar-collapse ul .mega-dropdown svg {
    font-size: 24px;
}
header .mega-dropdown-menu > li > ul > li > a {
  display: block;
  color: #fff;
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 300;
}
header .mega_links h5 {
  font-weight: 500;
  padding: 10px 0 10px 5px;
  color: #fff;
  border-bottom: 1px solid #1a2d49;
  border-top: 1px solid #1a2d49;
  text-transform: uppercase;
}
ul.dropdown-menu.mega-dropdown-menu {
  position: relative !important;
  background: transparent !important;
}
.mega-dropdown-menu {
  margin: 0 !important;
  padding: 0;
}
li.dropdown.mega-dropdown {
  margin-top: 10px;
}
.top_nav {
  margin: 6px 0 10px;
}
#auditions .info_bx {width: 100%;}
#postjob_detail .main_head {
  margin-bottom: 7px;
}
.postjob_tab {
  margin-top: 15px;
}
#change_term .modal-dialog {
  width: auto;
}
#people_other_info .nav-tabs > li {
  width: 32.6%;
}
#people_other_info .postjob_tab .nav-tabs li a {
padding: 8px 15px;
}
.portfolio-sec {
  display: block !important;
}
.tag_text {
  margin: 0 0 14px !important;
  padding: 0 !important;
  border: none !important;
}
}

@media (max-width: 575px) {
  .about_profile p {
    font-size: 13px;
    line-height: 15px;
}
.about_profile ul li span {
  font-size: 16px;
  font-weight: 600;
}
.job_list .discrption {
    display: block;
    display: -webkit-box;
    max-height: 82px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}  
.casting_list_head .disc_brief span{ display: block;
  display: -webkit-box;
  max-height: 40px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;}
  #change_term .terms_bx ul li {
    display: block;
} 
.pagination>li {
  font-size: 12px;
}
.pagination .page-link {
  font-size: 12px;
}
.casting_list_btn .action_btn, .casting_list_btn .side_date_bx{float: none;}
.casting_list_btn .action_btn ul li{padding: 0 11px;}
#change_term .terms_bx ul li label {
  margin-bottom: 7px;
  width: 100%;
}
#change_term .input_part input[type="text"] {
  width: 65%;
}
.modal-header h4 {
  font-size: 19px;
}
.action-btns .input-radio {
    display: block;
}
.aspect-ratios {
  margin: 0 0 12px;
}
  #people_other_info .nav-tabs > li {
    width: 100%;
}  
  .cta_info {
    width: 100% ;
}
  .terms_part ul li {
    width: 100%;
  } 
  .screen_ques input[type="text"] {
    width: 66%;
}
.screen_ques span {
  width: 32px;
  margin-left: 5px !important;
  font-size: 15px;
}
.stroke_btn {
    font-size: 13px !important;
    padding:4px 14px !important;
} 
.submit_application h5 {
  margin: -44px -15px 25px;
    padding: 15px;
    font-size: 18px;
}
.cropper{
width: 91%;
height: 340px;
}
p {
    font-size: 13px;
    line-height: 21px;
}  
  .searchjob_head {
    width: 100%;
}
.searchjob_bx .side_action_btn {
  position: absolute;
  right: 0;
}
.searchjob_head span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.searchjob_head span.discrption {
  -webkit-line-clamp: 2;
}
.search_job_list .inside_listing_tab .nav-tabs li a {
  width: 100%;
  font-size: 13px;
}
.search_job_list .inside_listing_tab .nav-tabs li {
  width: 33.3%;
}
#breakdown_list .searchjob_bx ul li{position: relative;}
  .milestone-status.red{width:100%;}
.hire_list .inside_listing_tab .blue_btn {
  top: -52px !important;
}
.create_profile_head .stroke_btn {
    margin: 0 0 5px;
    font-weight: 500;
}
  #login .register_type h4 {
    width: 100%;
}
  .header_btn {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
}
.heading.header_btn .blue_btn {
  margin-left: 0;
  height: auto;
  margin-bottom: 11px;
  order: 1;
  width: auto;
  margin-right: 10px;
}
#postjob_detail .main_head {
    font-size: 21px;
}  
.jobdetail_tab h5 {
  font-size: 18px;
  line-height: 24px;
}
.postjob_tab, .bid_info {
  border-radius: 21px;
}
.postjob_tab .nav-tabs li a {
  padding: 11px 20px;
  font-size: 12px;
}
.mid_job_info ul li {
  width: 100%;
  margin-bottom: 18px;
}
.audition_people_list .postjob_tab li {
  float: none;
}
.audition_people_list .inner_tab li a {
  border: 1px solid transparent;
}
.default_bg .jobdetail_tab .detail_sidebar .stroke_btn {
  margin: 5px 0;
  width: 100%;
}
.detail_sidebar ul li {
  display: block;
  margin: 0;
}
#postjob_detail .job_cat img {
  object-fit: cover;
  height: 150px !important;
}
  #movie_status .flex-row{display: block;}
.filter_flex{display: block;}
  #login .login_form .form-space {
    padding: 20px;
}
.filter_flex .btn-info {
  padding: 0 20px;
  margin: 12px 16px 0 !important;
}
.fund-text span {
  display: block;
}
.fund-text {
  margin-bottom: 0 !important;
}
#login .login_form .btn_action_btn .blue_btn {
  width: 100%;
}
#login .login_form .btn_action_btn {  display: block;}
#login .login_form  h4 {
  font-size: 23px;
  font-weight: 700;}
#login .login_form .logo, #login .login_form .form_head_part{text-align: center;}
 .search_filed{min-width: 100% !important;} 
 .dropdown-menu.notifications {
  min-width: 293px;
}
 .menu_items {
  width: 82%;
  margin: 0 auto;
  float: none;
  justify-content: center;
  padding-top: 10px;
}
  .job_des {
    font-size: 13px;
  }
  .searchjob_head p {
    font-size: 12px;
}
.stepwizard-step {
  width: 100%;
}
.searchjob_head h3 {
  font-size: 17px;
  word-break: break-word;
  padding-right: 43px;
}
#login .stroke_btn {
  padding: 4px 20px;
  font-size: 13px;
}
label {
  font-size: 12px;
  line-height: 15px;
}
.avatar-wrapper .profile-pic {
  height: 70px;
  width: 70px;
  margin-right: 8px;
}
.avatar-wrapper label .upload-button {
  padding: 3px 12px !important;
  font-size: 12px !important;
  margin: 0;
}
.create_profile_head {
  padding-right: 0;
  display: block;
  margin-bottom: 10px;
  margin-left: 20px;
}
#login .register_type h4 {
  font-size: 18px;
  line-height: 24px;
  padding: 30px 0 7px;
}
#login.register_page .stepwizard-row .btn {
  padding: 7px 20px 5px;
}
#login .setup-content, #login .form-space {
  padding: 20px 5px 0;
}
#login.register_page .btn-circle{margin: 0;}
  .side_action_btn .icon {
    font-size: 12px;
    margin-left: 0;
    width: 30px;
    height: 30px;
    line-height: 32px;
}  
.searchjob_bx .searchjob_point {
  display: block;
}
  .heading h2 {
    font-size: 19px;
    line-height: 25px;
    width: 100%;
}
.top_left_info h2 {
  font-size: 20px;
}
.flex_area {
  display: block;
}
.flex_area.side_btns {
  width: 100%;
}
.flex_area.side_btns div {
  padding: 0;
  margin: 10px 0 0;
}
.flex_area div {
  margin: 10px 0;
}
#services_pages .head_part h2 {
  font-size: 20px;
}
#services_pages .bx_bg {
  min-height: auto;
}
#services_pages .bx_bg {
  margin: 0 0 20px;
}
  .inside_listing_tab .nav-tabs li a {
    padding: 9px 15px 9px;
  }
.search_job_list .form-group.search_field {
    border-radius: 0;
    margin: 0 -15px;
    padding: 15px 15px; 
}
.audition_list .tab-content ul li {
  display: block;
}
.audition_date h5 {
  font-size: 16px;
  display: inline-block;
  margin: 0 10px;
}
.brief_intro {
  font-size: 13px;
  line-height: 20px;
}
.casting_list_head h3 {
  margin: 4px 0 7px;
  font-size: 16px;
  line-height: 20px;
}
.application_info {
  text-align: left;
  margin: 0 0 0;
}
.searchjob_bx .application_info a, .searchjob_bx .application_info p, .searchjob_bx .application_info span {
  font-size: 13px !important;
}
.sliding-sidebar{width: 100%;}
.audition_date span {
    font-size: 15px;}
.audition_date small {
  line-height: 20px;
  padding: 0 13px;
  vertical-align: text-bottom;
}
.audition_date {
  max-width: 100%;
  height: auto;
  margin-right: 0;
  padding: 5px 0;
  width: 100%;
  margin: 0 0 10px;
}
.search_job_list .inside_listing_tab .nav-tabs {
  margin: 0 -15px;
  padding: 0 0;
}
#create_breakdown form, #submission .submission_bg, .shadow_bg {
  padding: 30px 15px;
}
  #cta.inner_banner .home_grey_bx {
    padding: 3rem 30px;
    margin-bottom: 20px;
}
  .tab_head h4 {
    font-size: 18px;
}
#slider_part .stroke_btn {
  padding: 4px 12px;
  font-size: 14px;
  width: 165px;
  margin: 15px 0 29px;
}
#movie_status {
  padding: 25px 0 40px;
}
.mid_area p, #auditions .bg_bx p {
  font-size: 13px;
  line-height: 21px;
}
a.link_clr {
  font-size: 14px;
}
#auditions .bg_bx h6 {
  font-size: 18px;
}
.tab-content .stroke_btn {
    margin: 0;
    padding: 4px 16px;
    font-size: 13px;
}
  #cta.inner_banner h2 {
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 15px;
}
#cta .blue_btn {
  margin: 14px 0 0;
  font-size: 14px;
  padding: 6px 17px;
}
.inner_page_head .heading h2 {
  font-size: 18px;
  line-height: 25px;
}
#track_page_tab .nav-tabs > li > a, #inner_form .nav-tabs > li > a {
  padding: 8px 12px;
}
#track_page_tab .nav-tabs {
  background: transparent;
  padding: 0;
  border-radius: 8px;
}
.blue_btn, .default_btn {
  font-size: 13px;
  padding: 5px 11px;
  margin-right: 4px !important;
}
  footer .logo img {
    width: 110px;
  }
  #top_cards .item {
    width: 50%;
    margin: 7px 0;
}
.gallery_info img {
  height: 215px;
}
  .event_cal ul li .date {
    font-size: 13px;
    padding: 12px;
}
#cta.inner_banner .right_pattern {
  opacity: 0.4;
  left: auto;
  height: 100%;
  width: 100%;
  max-width: none;
  top: 0;
  object-fit: cover;
}
#cta.inner_banner p {
  font-size: 12px;
  line-height: 19px;
}
  .social_icons ul li {
    padding: 18px 9px 0;
  }
  .footer_links {
    margin: 10px 0 0;
  }
  .top_links ul li {
    padding: 2px 8px;
  }
  #track_page_tab .tab-content h6,
  #inner_form .nav-tabs {
    margin: 0 0 15px;
    font-size: 21px;
  }
  #track_page_header h2,
  #inner_banner h2 {
    font-size: 22px;
  }
  .list_part ul li {
    display: block;
  }
  .list_part ul li .list_info a {
    width: 100%;
  }
  .work_icon {
    width: 100%;
    height: 75px;
    max-width: 75px;
    min-width: 75px;
    margin: 0 auto;
  }
  .work_icon img {
    width: 45px;
    margin: 15px auto 0;
  }
  .work_bx {
    display: block;
    text-align: center;
  }
  .work_info {
    padding: 0;
    width: 100%;
  }
  .need_work {
    padding: 0 20px;
  }
  .work_info span {
    left: 0;
    right: 0;
    margin: 6px auto 0;
  }
  .work_bx h4 {
    margin: 40px 0 8px;
  }
  .work_head h2 {
    margin: 45px 0 30px;
    font-size: 24px;
    text-align: center;
  }
  #movie_status h2:after,
  #top_banner h2:after {
    display: none;
  }
  #make_film_section img {
    top: -17rem;
    max-width: 37rem;
    right: -26px;
  }
  #make_film_section {
    height: 560px;
  }
  .film_cate_part {
    margin: 12px 0 22px;
  }
  .make_film_info h2 {
    font-size: 24px;
    line-height: 31px;
  }
  .form_part {
    display: block;
  }
  .form_part .form-group {
    width: 100%;
    margin: 12px 4px;
}
.film_cate_part h6 {
  font-size: 16px;
  margin: 23px 0 15px;
}
.film_cate_part span {
  padding: 2px 14px;
  margin: 7px 5px;
  font-size: 12px;
}
.film_cate_part .link_clr {
  margin-left: 10px;
  font-size: 13px;
}

#movie_status .nav-tabs li{float:none;}
#movie_status .nav-tabs li a { font-size: 15px;}
#movie_status h2 {
  margin: 0 0 17px;
}
.btn_part a.blue_btn {
  padding: 7px 18px;
  margin-right: 9px;
  font-size: 14px;
}
#cta h2 {
  line-height: 24px;
  margin: 0 0 15px;
  font-size: 18px;
}
#slider_part .banner_text p {
  font-size: 12px;
  line-height: 18px;
}
#cta p {
  font-size: 13px;
    line-height: 19px;
}
.btn_part .stroke_btn {
  margin: 15px 0 0;
}
#popular_tag {
  padding: 2rem 0 4rem;
}
#cta .right_pattern {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
#cta .home_grey_bx {
  padding: 3rem 25px;
}
#movie_status .btn_part a {
  width: 100%;
  font-size: 15px !important;
  padding: 10px !important;
}
  .full {
    width: 100%;
  }
  #movie_status li:last-child .trendy_txt {
    border-bottom: 1px solid #37373a;
  }
  .mid_area {
    display: block;
  }
  #movie_status .side_movie_img img {
    width: 100%;
    height: 185px;
  }
  #movie_status .full_movie_detail {
    margin-left: 0;
    margin-top: 10px;
  }
  #top_banner .nav-tabs > li > a,
  #news_gallery .nav-tabs li a,
  #movie_status .nav-tabs li a {
    padding: 4px 7px;
  }
  #top_banner h2,
  #news_gallery h2,
  #top_banner h2,
  #movie_status h2 {
    font-size: 24px;
    margin: 0 0 10px;
  }
  .mid_area {
    margin: 10px 0 40px;
  }
  #news_gallery small {
    font-size: 12px;
    display: block;
    margin: 4px 0 10px;
    line-height: 16px;
  }
  #news_gallery h5 {
    font-size: 13px;
  }
  #news_gallery .small_img {
    margin-bottom: 20px;
    min-height: 245px;
  }
  .big_img {
    margin: 0 0 25px;
  }
  .audition_head h2 {
    font-size: 28px;
  }
  .audition_head {
    padding: 43px 40px 28px;
  }
  .event_cal ul li .date {
    font-size: 13px;
  }
  #popular_tag .nav-tabs > li > a {
    padding: 8px 7px;
}
#popular_tag img {
  border-radius: 8px;
  height: 142px;
  margin: 0 0 20px;
  object-position: 71%;
}
.pill_result li span {
  font-size: 12px;
  padding: 2px 12px;
}
  #popular_tag .nav-tabs > li {
    font-size: 14px;
}
  .event_cal ul li .date:after {
    right: -30px;
  }
  .event_cal ul li .event_detail {
    width: 80%;
  }
  .event_cal ul li .event_detail h4 {
    font-size: 16px;
  }
  .event_cal ul li .event_detail small {
    font-size: 11px;
  }
  #top_banner {
    padding-top:22px;
  }
  .head_img {
    width: 90px;
  }
  #auditions {
    padding:0 0 40px;
  }
  .section_info h2 {
    font-size: 24px;
    line-height: 31px;
    margin: 0 0 12px;
}
.section_info p {
  font-size: 14px;
  line-height: 23px;
}
.section_info .stroke_btn {
  margin: 27px 0 0;
  min-width: 190px;
  padding: 9px 10px;
  font-size: 16px;
}
.head_part h2 {
  margin: 0 0 16px;
  font-size: 25px;
}
  #slider_part .banner_text {
    left: 0;
  }
  #slider_part .banner_text h1 {
    line-height: 24px;
    font-size: 18px;
}
#slider_part .owl-carousel .owl-item {
  height: 330px;
}
#slider_part .carousel-inner .item img {
  object-position: 75%;
  opacity: 0.8;
}
#slider_part .overlay {
  background: linear-gradient(to right, #240653 6%, rgba(19, 53, 168, 0.9) 58%, rgba(0, 0, 0, 0) 123%);
}
  #slider_part .banner_text {
    font-size: 16px;
    line-height: 22px;
  }
  .top_nav .search {
    min-width: auto;
    width: 85%;
  }
  .search input {
    min-width: auto;
    width: 100%;
  }
  .mega_links {
    width: 100%;
    min-height: auto;
  }
  .jctkr-label,
  .wrap {
    width: 100%;
    text-align: center;
  }
  .jctkr-label:after {
    content: "";
    position: absolute;
    right: -99%;
    top: 0;
    width: 100%;
    height: 100%;
    background: #eae100;
    z-index: 0;
  }
  #login .create_profile_head .stroke_btn { margin:3px 0 17px !important;}
}


@media (max-width: 399px){
#top_cards .item {
    width: 100%;
}
}


/* chat send button css */
.cgZnmU {
  display: flex;
  border: 0.2rem solid rgb(69, 255, 255);
  border-radius: 0.8rem;
  background-color: rgb(62, 62, 62);
}

.hfqhoP {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255);
}

.bQBzzj {
  font-family: cerebri, sans-serif;
  font-weight: 400;
  letter-spacing: -0.01rem;
  line-height: 1.8rem;
  background-color: rgb(62, 62, 62) !important;
  outline: none;
  flex: 1 1 0%;
  font-size: 1.4rem !important;
  border-bottom: none !important;
  box-shadow: none !important;
  padding: 1.6rem 5rem 1.6rem 2.4rem !important;
  border-top-left-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important;
}

.jTfAtm:not(:disabled) {
  cursor: pointer;
}

.jTfAtm {
  display: inline-block;
  appearance: none;
  border: 0.1rem solid rgb(69, 255, 255);
  transition: border-color 216ms ease-in-out 0s,
    background-color 216ms ease-in-out 0s;
  width: inherit;
  position: relative;
  outline: none;
  text-decoration: none;
  background-color: rgb(69, 255, 255);
  color: rgb(16, 16, 16);
  font-family: cerebri, sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: -0.01rem;
  line-height: 2.2rem;
  padding: 1rem 1.8rem;
  border-radius: 0.6rem;
}

.ljMbZC {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  opacity: 1;
}
.p-3{
  padding: 1rem;
}
.pt-3{
  padding-top: 1rem;
}
.search_button{
  position: absolute;
  right: 0;
  top: 7px;
  background: #fff;
}
.pt-4{
  padding-top: 1rem;
}

.footer-menu .mega_links ul {
  border-top: 2px solid #fffc;
  padding-top: 6px;
}
.footer-menu .mega_links{
  border: none !important;
  min-height: 140px;
}
.m_title{
  color: #fffc;
  padding-bottom: 4px;
  /* margin-bottom: 10px; */

}
.footer-menu .mega_links ul:hover{
  border-top: 2px solid #ff3838 !important;
  -webkit-transition : border 500ms ease-out;
  -moz-transition : border 500ms ease-out;
  -o-transition : border 500ms ease-out;
  transition : border 500ms ease-out;
}
.footer-menu .mega_links a:hover{
  color:#1683f3 !important;
}
.social_login a {
     width: 100% !important;
    float: none !important;
    padding: 6px 10px !important;
    /* text-align: center; */
    margin: 0px 10px !important;
}