.stroke_btn {
    margin: 40px 0 30px;
    font-weight: 500;
    font-size: 15px;
    padding: 8px 30px;
}

/* celebrity banner section  */

#celebrity_banner {background: url(../images/banner_bg.webp) no-repeat;  background-size: cover;  background-position: center bottom;  padding: 14rem 0 0; margin-bottom: -80px; background-attachment:fixed; min-height:750px;}

.celebrity_info { margin: 120px 0 0;}

.animate_btn:hover, .celebrity_info .animate_btn:focus{text-decoration:none; background:#118afa; color:#fff;}

.celebrity_info h1 {font-size: 50px; color: #fff;  font-weight: 400;  margin: 0 0 30px; text-transform:uppercase;}

.celebrity_info p {font-size: 28px; font-weight: 300;  color: #f1f1f1;  line-height: 38px;}

.celebrity_img img { z-index: 9; object-fit: cover;}

.animate_btn {position: relative; overflow: hidden;   padding: 14px 30px;  border-radius: 3px;  color: #fff; margin: 20px 0 0; display: inline-block; font-size: 17px; text-transform: uppercase; font-weight: 600; letter-spacing: 1px;  background: #007aeb;   text-align: center;     border: none;}

.animate_btn:after {content: ""; position: absolute; top: -550%; left: -210%; width: 200%; height: 350%; opacity: 0; transform: rotate(30deg); background: rgba(255, 255, 255, 0.13); background: linear-gradient( to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);}

.animate_btn:hover:after { opacity: 1;  top: -30%;  left: -30%;  transition-property: left, top, opacity;  transition-duration: 0.7s, 0.7s, 0.15s;transition-timing-function: ease;}

.celebrity_img { position: relative;}

.banner_img1 {position: absolute;  top: 15px; max-width: 440px;	left: 0;}

.banner_img2 {position: absolute;  top: 289px;  left: 55px;  max-width: 260px;}

.banner_img3 {position: absolute; top: 192px; right: 40px; max-width: 385px;}

.banner_img4 {position: absolute; right: -35px; top: 0; max-width: 270px;}



/* services inner pages */

#inner_celeb_banner, #celebrity_contact{background: url(../images/banner_bg.webp) no-repeat; background-size: cover;  background-position: center bottom;  padding: 2rem 0 4rem;  background-attachment: fixed;}
.inner_celebrity_img img {
    margin: 35px 0 0;
    object-fit: cover;
}

#inner_services ul li .service_bx img {max-width: 95px;}

.inner_celebrity_info {padding-top: 5rem;}

.inner_celebrity_info h1 {
    font-size: 38px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 30px;
    line-height: 44px;
}

.inner_celebrity_info p {
    font-size: 19px;
    color: #f1f1f1;
    line-height: 30px;
    font-weight: 300;
}

#inner_about{background:#e5e6f5; padding:8rem 0 7rem;}

#inner_about img, #our_role img {max-width: 100%;}

.inner_about_info p, .role_info p, #last_info p {
    font-size: 14px;
    line-height: 26px;
    color: #2f2f2f;
    padding-right: 30px;
}
.inner_head h2 {
    font-weight: 600;
    color: #333;
}
.inner_head {
    margin: 0 0 25px;
}
#our_role{padding:7rem 0 6.5rem;}

#inner_services p {
    font-size: 14px;
    line-height: 24px;
    color: #f1f1f1;
    font-weight: 400;
}
#inner_services {
    background: url(../images/artist_bg.webp) no-repeat;    background-attachment: fixed;
    background-size: cover;
    background-position: center bottom;
    padding: 7rem 0 6rem;
    color: #fff;
}
#inner_services .inner_head h2 {color:#fff; margin: 0 0 15px;}

#inner_services .inner_head p {font-size: 19px;  font-weight: 300;}

#inner_services ul{padding:0; margin:0 -8px;}

#inner_services ul li {
    display: inline-grid;
    width: 20%;
    padding: 11px 8px 30px;
}

#inner_services ul li .service_bx {background: #1c79cf; padding: 22px 15px 10px; min-height: 235px;}

#inner_services ul:after, #inner_services ul:before{content:''; display:table; clear:both;}

#inner_services ul li .service_bx h6 {font-size: 16px;  text-transform: uppercase; color: #fff; font-weight: 600; line-height: 22px; margin: 20px 0 0;}

#last_info {
    padding: 5rem 0 4rem;
}

#last_info blockquote {padding:0 0 0 20px; border-left: 5px solid #1c79cf;}

.role_info ul li:before, .inner_about_info ul li:before {
    content: '';
    position: absolute;
    background: #f81c65;
    width: 6px;
    height: 6px;
    left: 0;
    top: 10px;
    transform: rotate(45deg);
}
.role_info ul li, .inner_about_info ul li {
    position: relative;
    padding-left: 20px;
    font-weight: 500;
}
#inner_services h3{color:#fff;}

#inner_services.dark_list ul li {
    display: block;
    padding: 7px 0 0;
    vertical-align: text-top;
    line-height: 19px;
    width: 100%;
    list-style: disc;
}
#inner_services ol li {
    list-style: circle;
    font-size: 14px;
}

/* reach us page */
#celebrity_contact {
    padding: 3rem 0 8rem;
}
.cele_contact_info {
    padding: 3rem 15px 0;
    text-align: center;
}
.cele_contact_info h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 42px;
    margin-bottom: 20px;
}
.cele_contact_info h3 {
    color: #fff;
    text-transform: none;
    position: relative;
    margin-bottom: 60px;
}
.cele_contact_info h3:after {
    content: '';
    width: 59px;
    height: 4px;
    background: #007aeb;
    position: absolute;
    left: 0;
    bottom: -31px;
    right: 0;
    margin: 0 auto;
}
#our_offices .office_info h4 {
    color: #007aeb;
    margin: 0 0 10px;
}
#our_offices .office_info ul {
    padding: 0;
    list-style: none;
}
.cele_form_bg {
    background: #fff;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 14px 24px rgba(0,0,0,0.4);
    border-top: 5px solid #007aeb;
}
#our_offices .office_info img {
    border-radius: 4px;
    height: 175px;
    object-fit: cover;
    width: auto;
    max-width: 200px;
    margin-right: 20px;
}
.office_info {
    display: inline-flex;
    align-items: center;
    margin: 20px 0;
}
.office_detail {
    margin: 30px 0 0;
}
.celebrity_form {
    margin-top: -50px;
}
#our_offices {
    margin: 0 0 50px;
}

@media (max-width: 991px){
#our_offices .office_info img {
    height: 175px;
    max-width: 120px;
    margin-right: 20px;
}
.office_info {
    font-size: 13px;
    line-height: 20px;
}
}

@media (max-width: 767px){
.inner_celebrity_info h1 {
    font-size: 28px;
    margin: 0 0 10px;}
.inner_celebrity_info p {
    font-size: 16px;
    line-height: 26px;}
.inner_celebrity_info {
    padding-top: 1rem;
}
.inner_celebrity_img{display: none;}
#inner_about img, #our_role img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin: 10px 0 20px;
}
#our_role {
    padding: 5rem 0 3.5rem;
}
#inner_about {
    padding: 5rem 0 4rem;
}
#inner_about .inner_head, #our_role .inner_head,  #inner_services .inner_head {text-align: left;}
#our_offices .office_info img {
    height: 120px;
    max-width: 120px;
}
}

@media (max-width: 575px){
.inner_celebrity_info h1 {
    font-size: 22px;
    margin: 0 0 10px;
    line-height: 28px;
}
.inner_celebrity_info p {
    font-size: 14px;
    line-height: 24px;
}
#inner_about .inner_head h2, #our_role .inner_head h2, #inner_services .inner_head h2{
    font-size: 22px;    
    line-height: 28px;
}
#inner_about .inner_head, #our_role .inner_head {
    margin: 0 0 10px;
}

.inner_about_info p, .role_info p, #last_info p {
    padding-right: 0;
}
.cele_contact_info h1 {
    font-size: 28px;
}
.cele_contact_info h3 {
    font-size: 20px;
    margin-bottom: 30px;
}
.cele_form_bg {
    padding: 20px;
}
.office_info {
    display: block;
}
#our_offices .office_info img {
    height: 120px;
    max-width: 100%;
    width: 100%;
    margin: 0 0 17px;
}
.cele_contact_info {
    padding: 1rem 15px 0;
}
}